import { defineStore } from 'pinia'

export const useApproveEmailStore = defineStore('approveEmail', {
  actions: {
    getTemplate() {
      return axios.get('/api/approval-email-template')
    },
    saveTemplate(data) {
      console.log(data)
      return axios.post('/api/approval-email-template', data)
    }
  },
})
