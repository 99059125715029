<script setup>
import { ref, computed } from 'vue';
import ApplyModal from './ApplyModal.vue';

const props = defineProps({
  pagination: { type: Object, required: true },
  items: { type: Array, default: () => [] },
})

const emit = defineEmits(['refresh'])
const refApplyModal = ref(null);

const havePending = computed(() => {
  return props.items.some((item) => {
    return item.rooms.some((room) => {
      return room.status === 'pending'
    })
  })
})
</script>
<template>
  <div v-if="props.items.length" class="flex flex-col gap-4">
    <div class="flex items-center gap-4 justify-center font-semibold">
      <div>
        <button class="text-center px-4 py-1 text-white bg-primary rounded hover:opacity-80"
          @click.prevent="refApplyModal.open()"
        >
          再申請する
        </button>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col justify-center gap-2">
    <div class="flex justify-center">
      <button 
        class="px-4 py-2 bg-yellow-400 text-white rounded font-semibold hover:opacity-80 min-w-36"
        @click.prevent="refApplyModal.open()"
      >
        申請する
      </button>
    </div>
    <div class="flex flex-col text-error justify-center text-xs">
      <div>※当月25日迄に施設利用日の申請をしてください。</div>
      <!-- <div>※25日を過ぎると申請できません。</div> -->
    </div>
  </div>
  <ApplyModal ref="refApplyModal" 
    :pagination="props.pagination" 
    :schedules="props.items" 
    @refresh="emit('refresh')" 
  />
</template>