<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
const url = ref(null)
const isOpen = ref(false)
const open = async (qr_url) => {
  if (qr_url) {
    url.value = qr_url;
  }
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template" @close="close">
    <Dialog as="div" class="relative z-20">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto" @click="close">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="max-w-xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4 pb-1">
              <div class="flex flex-col gap-2">
                <div>
                  <img :src="url" class="min-w-60 max-w-full" loading="lazy" @click="close" />
                </div>
                <div class="flex justify-center">
                  <button
                    class="px-4 py-1 w-fit bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out font-semibold"
                    @click="close">
                    閉じる
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
