<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpen = ref(false)
const open = () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

defineExpose({ open, close })
</script>
<template>
  <!-- 利用申請しました！ -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-fit transform bg-white text-left align-middle shadow-xl transition-all rounded-lg"
            >
              <div class="w-full flex flex-col gap-4 items-center justify-center p-4">
                <div class="flex items-center justify-center w-full border rounded border-blue-500 text-blue-500 font-semibold gap-2 py-1">
                  <QuestionMarkCircleIcon class="w-5 h-5 fill-blue-500" />
                  {{ $t('qr_code_view.desc2_help') }}
                </div>
                <div>
                  {{ $t('qr_code_view.desc2_help_top') }}
                </div>
                <ul class="list-disc pl-6 font-semibold text-sblue w-full justify-start">
                  <li>
                    {{ $t('qr_code_view.desc2_help_iphone_ipad') }}
                    <ol class="list-decimal pl-2 text-charcoal font-normal">
                      <li>{{ $t('qr_code_view.desc2_help_iphone_ipad_1') }}</li>
                      <li>{{ $t('qr_code_view.desc2_help_iphone_ipad_2') }}</li>
                    </ol>
                  </li>
                  <li>
                    {{ $t('qr_code_view.desc2_help_android') }}
                    <ol class="list-decimal pl-2 text-charcoal font-normal">
                      <li>{{ $t('qr_code_view.desc2_help_android_1') }}</li>
                      <li>{{ $t('qr_code_view.desc2_help_android_2') }}</li>
                    </ol>
                  </li>
                </ul>
                <div class="text-xs text-error">
                  {{ $t('qr_code_view.desc2_help_note') }}
                </div>
                <div class="flex items-center justify-between gap-4 font-semibold text-grey-500">
                  <button class="border rounded px-4 min-w-32 py-1 hover:opacity-80 border-grey-500" @click="close">
                    {{ $t('close') }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
