<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { UserIcon, UsersIcon, PlusIcon } from '@heroicons/vue/24/solid'

const $root = getCurrentInstance()?.appContext.config.globalProperties
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import PopoverLanguage from './widgets/PopoverLanguage.vue';

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

import ConfirmModel from './ConfirmModel.vue';
const refConfirmModel = ref(null)
const club_id = route.params.id
onMounted(async () => {
  if (!fronEndClubStore.club.id) {
    router.push({ path: `/front-end/club-membership/${club_id}` });
    return;
  }
  fetchData()
})

const fetchData = async () => {
  $root.overlay.loading = true
  await fronEndClubStore.search()
  $root.overlay.loading = false
}

const newRegister = () => {
  router.push({ path: `/front-end/club-membership/${club_id}/2` });
}

const addMember = (member, markMain = false) => {
  if (fronEndClubStore.filter.related == false && markMain == true) {
    fronEndClubStore.member.type = 'guest'
  }
  refConfirmModel.value.open(member)
}

const downloadQR = () => {
  router.push({ path: `/front-end/club-membership/${club_id}/4` });
}
</script>
<template>
  <div v-if="fronEndClubStore.info.member"
    class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div
      class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-md md:shadow-lg">
      <div class="flex flex-row w-full bg-secondary pt-4 px-3 pb-0 justify-between items-center  relative">
        <div class="flex w-12 h-12 items-center"></div>
        <div class="text-xl text-white text-center font-bold">{{ $t('registration_list') }}</div>
        <div class="group transition duration-300 delay-75 ease-in-out">
          <PopoverLanguage/>
        </div>
      </div>
      <hr />

      <div class="flex flex-col text-base p-4">
        <div class="flex flex-col gap-4">

          <div class="flex flex-col items-start border border-gray-200 divide-y">
            <div class="flex w-full p-2 bg-gray-100">
              {{ $t('club_name') }}
            </div>
            <div class="flex w-full p-2 font-bold text-sblue">
              {{ fronEndClubStore.club.name }}
            </div>
          </div>

          <div class="flex flex-col items-start gap-2">
            <div class="w-full text-sm bg-[#D7E5FE] text-center">{{ $t('register_additional_members.desc1') }}</div>
            <div class="flex w-full text-error text-xs font-bold">{{ $t('register_additional_members.desc2') }}</div>
            <div class="flex w-full text-error text-xs">{{ $t('register_additional_members.desc3') }}</div>
          </div>

          <div class="flex flex-row items-center gap-4 justify-center p-2 border border-grey-400 rounded-lg">
            <div class="flex-none justify-start group transition duration-300 delay-75 ease-in-out w-7 h-7">
              <UserIcon
                class="w-7 h-7 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
            </div>
            <div class="grow gap-2 flex flex-col">
              <div class="flex flex-row gap-2">
                <div class="grow">
                  <div class="py-1 items-start">{{ fronEndClubStore.info.member.name }}</div>
                  <div class="min-h-4">
                    <button type="submit" v-if="!fronEndClubStore.info.joined"
                      @click="addMember(fronEndClubStore.info.member, true)"
                      class="bg-sky-500 shadow text-white font-bold px-4 py-1 rounded-md hover:opacity-80 ">
                      {{ $t('add_member') }}
                    </button>
                  </div>
                </div>
                <div class="flex flex-none w-fit align-bottom text-sm items-end justify-end"
                  :class="fronEndClubStore.info.joined ? 'text-sky-500' : 'text-error'">
                  {{ fronEndClubStore.info.joined ? $t('registered') : $t('unregistered') }}
                </div>
              </div>
            </div>
          </div>

          <template v-for="rela in fronEndClubStore.info.related">
            <div class="flex flex-row items-center gap-4 justify-center p-2 border border-grey-400 rounded-lg">
              <div class="flex-none justify-start group transition duration-300 delay-75 ease-in-out w-7 h-7">
                <UsersIcon
                  class="w-7 h-7 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
              </div>
              <div class="grow gap-2 flex flex-col">
                <div class="flex flex-row gap-2">
                  <div class="grow">
                    <div class="py-1 items-start">{{ rela.name }}</div>
                    <div class="min-h-4">
                      <template v-if="!rela.join">
                        <button type="submit" @click="addMember(rela)"
                          class="bg-sky-500 shadow text-white font-bold px-4 py-1 rounded-md hover:opacity-80 ">
                          {{ $t('add_member') }}
                        </button>
                      </template>
                    </div>
                  </div>
                  <div class="flex flex-none w-fit align-bottom text-sm items-end justify-end"
                    :class="rela.join ? 'text-sky-500' : 'text-error'">
                    {{ rela.join ? $t('registered') : $t('unregistered') }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-col gap-2 text-center">
            <button type="submit" @click="newRegister"
              class="font-bold hover:opacity-80 group flex items-center gap-2 transition duration-300 delay-75 ease-in-out w-full bg-primary justify-center  text-white rounded-lg p-3 h-14">
              <div class="border bg-white rounded-sm">
                <PlusIcon
                  class="w-5 h-5 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out text-primary">
                </PlusIcon>
              </div>
              {{ $t('additional_registration') }}
            </button>
            <div class="text-error text-sm px-3">
              {{ $t('additional_registration_note') }}
            </div>
          </div>

          <div class="flex flex-col">
            <button type="submit" @click="downloadQR"
              class="w-fit font-bold hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-sky-500 text-white rounded-lg px-4 h-11">
              {{ $t('complete_registered') }}
            </button>
          </div>

        </div>
      </div>
    </div>
    <ConfirmModel ref="refConfirmModel" />

  </div>
</template>
