<script setup>
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import QuestionIcon from './QuestionIcon.vue';
</script>

<template>

  <Popover v-slot="{ open }" class="flex items-center group transition duration-300 delay-75 ease-in-out">
    <PopoverButton class="group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out">
      <QuestionIcon fill="#79878A"/>
    </PopoverButton>

    <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel class="absolute top-9 z-10 w-screen md:max-w-80 max-w-screen -right-4 md:-right-0">
        <div class="overflow-hidden shadow-2xl ring-1 ring-black/5 p-4 bg-yellow-200 text-gray-600" v-html="$t('phone_hint')"></div>
      </PopoverPanel>
    </transition>
  </Popover>

</template>