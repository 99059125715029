<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useApproveEmailStore } from '../../../js/store/approve-email'
import EditorContent from '../../components/EditorContent.vue'
import EmailApprovalTemplateHelp from './EmailApprovalTemplateHelp.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const approveEmailStore = useApproveEmailStore()

const refEmailApprovalTemplateHelp = ref(null)
const item = ref({
  subject: '',
  body: ''
})

onMounted(async () => {
  $root.overlay.loading = true
  approveEmailStore.getTemplate().then((res) => {
    item.value = res.data.data || { subject: '', body: '' }
  }).finally(() => {
    $root.overlay.loading = false
  })
})

const handleSave = () => {
  if (item.value.subject?.trim() === '' || item.value.body?.trim() === '') {
    $root.push.error('設定名と内容を入力してください')
    return
  }

  $root.overlay.loading = true
  approveEmailStore.saveTemplate(item.value).then(() => {
    $root.overlay.loading = false
    $root.push.success('設定を保存しました')
  }).catch(() => {
    $root.overlay.loading = false
    $root.push.error('設定の保存に失敗しました')
  })
}
</script>
<template>
  <div class="w-full flex justify-center p-4">
    <div class="flex flex-col border border-t-2 border-t-secondary w-full 2xl:w-2/3">
      <h3 class="px-2 py-1 bg-gray-100 border-b flex justify-between items-center font-semibold">
          テンプレート
          <button 
            type="button"
            class="px-4 py-1 bg-primary text-white rounded-md"
            @click.prevent="handleSave"
          >
            設定を保存
          </button>
      </h3>
      <!-- name -->
      <div class="flex items-center gap-4 px-2 py-1">
        <div class="flex-none w-fit">設定名</div>
        <div class="grow">
          <input 
            type="text" 
            class="px-2 py-0.5 rounded border bg-yellow w-full" 
            v-model="item.subject"
          />
        </div>
      </div>
      <hr />
      <div class="flex flex-col h-full">
          <div class="transition-all delay-75 duration-300 ease-in-out h-full">
            <EditorContent 
              v-model:content="item.body"
              class="flex flex-col h-full bg-yellow" 
            />
          </div>
      </div>
      <div class="px-2 text-xxs text-error pb-2">
        <p>
          ※メールの内容を入力してください。メールの内容には、<strong>[#CONTENT]</strong> のショートコードを使用できます。
          <!-- more -->
          <button 
            type="button"
            class="text-blue-500 underline"
            @click.prevent="refEmailApprovalTemplateHelp.open()"
          >
            詳細
          </button>
        </p>
      </div>
    </div>
  </div>
  <EmailApprovalTemplateHelp ref="refEmailApprovalTemplateHelp" />
</template>