<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, Switch } from '@headlessui/vue'
import { CalendarIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useMember } from '../../../../js/store/member';
import { useClubStore } from '../../../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const $root = getCurrentInstance()?.appContext.config.globalProperties

const memberStore = useMember()
const clubStore = useClubStore()
const type = ref('guest')

const isOpen = ref(false)
const open = async (id, t) => {
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    close()
    return
  }

  type.value = t

  $root.overlay.loading = true
  await memberStore.getItem(id, clubStore.item.id, t)
  $root.overlay.loading = false

  isOpen.value = true
}
const close = () => {
  type.value = 'guest'
  isOpen.value = false
}

const save = async () => {
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    return
  }

  memberStore.saved = true

  $root.overlay.loading = true
  try {
    await memberStore.save(clubStore.item.id, type.value)
    $root.push.success('メンバーを保存しました')

    // update member list
    await clubStore.getMembers()

    $root.overlay.loading = false

    close()
  } catch (error) {
    $root.push.error('メンバーの保存に失敗しました')
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary">
              <DialogTitle as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 py-2 px-4 bg-graysp justify-between">
                メンバー
                <div>
                  <button title="閉じる" class="p-1 transition duration-300 delay-75 ease-in-out" @click="close">
                    <XMarkIcon class="w-6 h-6 text-gray-400" @click="close" />
                  </button>
                </div>
              </DialogTitle>

              <div class="flex flex-col gap-4">
                <div class="flex flex-col md:flex-row md:items-start gap-2 p-4">
                  <!-- expried date -->
                  <div class="w-full md:w-2/3 flex items-center">
                    <div class="flex flex-col gap-1">
                      <div>有効期限</div>
                      <div class="max-w-40">
                        <VueDatePicker v-model="memberStore.member.expired_at" locale="ja" format="yyyy-MM-dd"
                          :enable-time-picker="false" auto-apply teleport>
                          <template #input-icon>
                            <CalendarIcon class="ml-2 w-4 h-4" />
                          </template>
                          <template #dp-input="{ value }">
                            <input type="text" :value="value" class="border rounded-md pl-8 pr-2 py-1 max-w-40" />
                          </template>
                        </VueDatePicker>
                      </div>
                      <div class="text-xxs text-gray-400 leading-tight">
                        ※QRコードは有効期限が過ぎると使用できなくなります。
                        <div>
                          ※有効期限を設定しない場合は、QRコードは無期限で使用できます。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/3 flex items-center">
                    <div class="flex flex-col  gap-1">
                      <div>QR有効・無効</div>
                      <div class="pl-1">
                        <Switch v-model="memberStore.member.status"
                          class="relative inline-flex h-6 w-11 items-center rounded-full" :class="{
                            'bg-primary': memberStore.member.status,
                            'bg-gray-200': !memberStore.member.status
                          }">
                          <span class="inline-block h-4 w-4 transform rounded-full bg-white transition" :class="{
                            'translate-x-6': memberStore.member.status,
                            'translate-x-1': !memberStore.member.status
                          }" />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- cancel and save buttons -->
                <div class="flex items-center justify-between mt-2 w-full bg-gray-300/10 p-4 border-t">
                  <div></div>
                  <div class="flex items-center gap-2">
                    <!-- cancel -->
                    <button
                      class="px-4 py-1 bg-white border rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="close">
                      キャンセル
                    </button>
                    <!-- add guest -->
                    <button
                      class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="save">
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
