<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import {ClockIcon } from '@heroicons/vue/24/outline'
import DateTimeHelper from '../../../js/helper/DatetimeHelper'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useCalendarFactility } from '../../../js/store/calendar-factility';
const facilityStore = useCalendarFactility();

import { useResource } from '../../../js/store/resource';
const resourcTimeStore = useResource();
const isCloseAll = ref(false);
const isOpenAll = ref(false);

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true;
  isCloseAll.value = false;
  isOpenAll.value = false;
}
const close = () => {
  isOpen.value = false
}

const save = async() => {
  $root.overlay.loading = true
  try {
    const response = facilityStore.isUpdate ? await facilityStore.update() : await facilityStore.save();
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      facilityStore.getItems();
      facilityStore.settingDay = {};
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}

const closeAll = () => {
  if(isCloseAll.value) {
    isOpenAll.value = false;
  }
  facilityStore.settingDay.map((item) => {
    item.closed = isCloseAll.value;
    changeClosed(item);
  })
}

const openAll = () => {
  if(isOpenAll.value) {
    isCloseAll.value = false;
  }
  facilityStore.settingDay.map((item) => {
    item.default_hours = isOpenAll.value;
    changeDefaultHour(item);
  })
}

const changeClosed = (hour) => {
  if(hour.closed && hour.default_hours) {
    hour.default_hours = false;
  }
  if(hour.closed) {
    hour.opening_time = null;
    hour.closing_time = null;
  } else {
    let openTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].opening_time : resourcTimeStore.itemsTime[0].opening_time;
    let closeTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].closing_time : resourcTimeStore.itemsTime[0].closing_time;
    hour.opening_time = { "hours": openTime.split(':')[0], "minutes": openTime.split(':')[1]};
    hour.closing_time = { "hours": closeTime.split(':')[0], "minutes": closeTime.split(':')[1]};
  }
}

const changeDefaultHour = (hour) => {
  if(hour.closed && hour.default_hours) {
    hour.closed = false;
  }
  if(hour.default_hours) {
    let openTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].opening_time : resourcTimeStore.itemsTime[0].opening_time;
    let closeTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].closing_time : resourcTimeStore.itemsTime[0].closing_time;
    hour.opening_time = { "hours": openTime.split(':')[0], "minutes": openTime.split(':')[1]};
    hour.closing_time = { "hours": closeTime.split(':')[0], "minutes": closeTime.split(':')[1]};
  }
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadown-lg transition-all border-t-2 border-t-secondary"
            >
              <div class="text-primary font-semibold bg-gray-100 px-4 py-2">
                施設利用詳細
              </div>
              <div class="flex flex-col p-4 gap-4">
                <div class="flex flex-col gap-2">
                  <h4 class="font-semibold">{{facilityStore.settingDay[0] ? DateTimeHelper.convertToDateWithDayOfWeek(facilityStore.settingDay[0].date) : ''}}</h4>
                  <table class="table border-b">
                    <thead class="bg-gray-100 border-b border-gray-200">
                      <tr>
                        <th class="p-1 font-normal">施設</th>
                        <th class="p-1 font-normal">
                          <div class="flex items-center gap-2">
                            <input v-model="isCloseAll" type="checkbox" class="w-4 h-4" @change="closeAll()"/>使用不可
                          </div>
                          </th>
                        <th class="p-1 font-normal">
                          <div class="flex items-center gap-2">
                            <input v-model="isOpenAll" type="checkbox" class="w-4 h-4" @change="openAll()"/>固定時間
                          </div>
                          </th>
                        <th class="p-1 font-normal">利用時間設定</th>
                        <th class="p-1 font-normal">備考</th>
                      </tr>
                    </thead>
                    <tbody class="divide-y">
                      <tr v-for="hour in facilityStore.settingDay">
                        <td class="p-1">{{ hour.name }}</td>
                        <td class="p-1">
                          <label class="flex items-center gap-2">
                            <input type="checkbox" class="w-4 h-4" v-model="hour.closed" @change="changeClosed(hour)"/>
                            使用不可
                          </label>
                        </td>
                        <td class="p-1">
                          <label class="flex items-center gap-2">
                            <input type="checkbox" class="w-4 h-4" v-model="hour.default_hours" @change="changeDefaultHour(hour)"/>
                            基本時間
                          </label>
                        </td>
                        <td class="p-1 w-28">
                          <div class="flex items-center gap-1">
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none z-10" />
                              <VueDatePicker 
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                locale="ja"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                :minutes-increment="15"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                                v-model="hour.opening_time"
                                :disabled="hour.closed || hour.default_hours"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="rounded w-full pr-8 pl-2 py-1 cursor-pointer" :disabled="hour.closed || hour.default_hours"/>
                                </template>
                              </VueDatePicker>
                            </div>
                            <span>～</span>
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none z-10" />
                              <VueDatePicker 
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                locale="ja"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                :minutes-increment="15"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                                v-model="hour.closing_time"
                                :disabled="hour.closed || hour.default_hours"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="rounded w-full pr-8 pl-2 py-1 cursor-pointer" :disabled="hour.closed || hour.default_hours"/>
                                </template>
                              </VueDatePicker>
                            </div>
                          </div>
                        </td>
                        <td class="p-1 w-28">
                          <input v-model="hour.notes" type="text" class="w-full border border-gray-300 rounded px-2 py-1 max-w-32" placeholder="備考" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mb-4 flex items-center justify-center gap-4 font-semibold">
                <button
                  class="px-4 py-1 text-white rounded bg-primary border border-primary hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="save"
                >
                  更新
                </button>
                <button
                  class="px-4 py-1 rounded bg-gray-100 border border-slate-600 text-slate-600 hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="close"
                >
                  キャンセル
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
