<template>
  <div class="h-screen min-h-full bg-slate-200 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <main class="sm:flex">
      <p class="text-4xl font-bold tracking-tight text-rose-600 sm:text-5xl">404</p>
      <div class="sm:ml-6">
        <div class="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 class="text-4xl font-bold tracking-tight text-gray-600 sm:text-5xl">ページが見つかりません</h1>
          <p class="mt-1 text-base text-gray-500">アドレスバーのURLを確認して、もう一度お試しください。</p>
        </div>
      </div>
    </main>
  </div>
</template>