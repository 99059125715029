<script setup>
import { ref, computed } from 'vue'
import CalendarHelper from '../../../js/helper/CalendarHelper'
import { PencilIcon } from '@heroicons/vue/24/solid';
import DateTimeHelper from '../../../js/helper/DatetimeHelper'

import { useCalendarEvent } from '../../../js/store/calendar-event';
const eventStore = useCalendarEvent();

const emit = defineEmits(['edit']);

// default is current year
const year = defineModel('year', Number);
// default range is 3 months
const range = defineModel('range', Number);
// default is current month
const monthStart = defineModel('monthStart', Number);

const addItem = (day) => {
  eventStore.newItem = {color: '#000000'}
  eventStore.startDate =  `${year.value}-${monthStart.value}-${day}`;
  eventStore.endDate =  `${year.value}-${monthStart.value}-${day}`;
  emit('edit');
};
const editItem = (item) => {

  item.holiday = item.holiday ? true : false;
  item.important = item.important ? true : false;
  eventStore.newItem = JSON.parse(JSON.stringify(item));
  eventStore.startDate =  DateTimeHelper.format(item.start_time);
  eventStore.starTime = DateTimeHelper.formatTimeObject(item.start_time);
  eventStore.endDate =  DateTimeHelper.format(item.end_time);
  eventStore.endTime = DateTimeHelper.formatTimeObject(item.end_time);
  emit('edit');
};

const showItem = (item, day) => {
  const currentDate = DateTimeHelper.format(`${year.value}-${monthStart.value}-${day}`);
  const startSchedule = DateTimeHelper.format(item.start_time);
  const endSchedule = DateTimeHelper.format(item.end_time);
  return (startSchedule <= currentDate && currentDate <= endSchedule) ? true : false;
}

const calendars = computed(() => {
  const cals = CalendarHelper.generateCalendars(year.value, monthStart.value, range.value); // array

  let objs = [], years = {}
  cals.forEach(c => {
    for (let i = 1; i <= parseInt(c.days); i++) {
      let item = {
        year: c.year,
        month: c.month,
        day: i,
        textYear: years[c.year] ? '' : c.year,
        textMonth: years[c.year] && years[c.year][c.month] ? '' : `${c.month}月`,
      }
      if (!years[c.year]) years[c.year] = {}
      if (!years[c.year][c.month]) years[c.year][c.month] = {} 

      years[c.year][c.month][i] = item
      objs.push(item)
    }
  })

  return objs
});

const dragOver = (e) => {
  e.preventDefault();
  // console.log('dragOver', e);
};

const drop = (e) => {
  e.preventDefault();
  console.log('drop', e);
};
</script>
<template>
<table class="table w-full border-separate border-spacing-0 transition-all duration-500 delay-75 ease-in-out relative">
  <thead class="bg-gray-100 sticky top-0 left-0 right-0 h-8 z-10">
    <tr class="divide-x divide-slate-400">
      <th colspan="3" class="p-1 text-center border-b border-slate-400">
        日付
      </th>
      <th class="p-1 text-left border-b border-slate-400">
        行事
      </th>
    </tr>
  </thead>
  <tbody class="divide-x divide-slate-400">
    <tr v-for="(c, idx) in calendars" class="transition delay-75 duration-300 ease-in-out divide-x divide-slate-400">
      <td 
        class="p-1 font-semibold text-lg w-24 bg-white"
        :class="{
          'sticky left-0 top-8': c.textYear,
        }"
      >
        {{ c.textYear }}
      </td>
      <td 
        class="p-1 font-semibold text-lg w-24 border-slate-400 bg-white"
        :class="{
          'border-t': idx > 0 && c.textMonth,
          'sticky left-0 top-8': c.textMonth,
        }"
      >
        {{ c.textMonth }}
      </td>
      <td class="p-1 w-16 border-slate-400"
        :class="{
          'border-t': idx > 0,
          'bg-blue-100 text-blue-500': CalendarHelper.isSat(c.year, c.month, c.day),
          'bg-error/10': CalendarHelper.isSun(c.year, c.month, c.day)
        }"
      >
        <div class="flex items-center gap-2 justify-between font-semibold">
          <div class="text-lg">{{ c.day }}</div>
          <div>{{ CalendarHelper.getWeekday(c.year, c.month, c.day) }}</div>
        </div>
      </td>
      <td 
        class="p-1 border-slate-400 relative group"
        :class="{
          'border-t': idx > 0,
          'bg-error/10': CalendarHelper.isSun(c.year, c.month, c.day),
          'bg-blue-100': CalendarHelper.isSat(c.year, c.month, c.day),
        }"
        @dragover="dragOver"
        @drop="drop"
      >
        <div class="flex flex-col gap-0.5 items-start min-h-32">
            <div class="flex w-full flex-col p-1 min-h-14 transition delay-75 duration-300 ease-in-out relative min-w-40"
            :class="{
                'border-dashed bg-white/60': isDragging,
            }"
            draggable="true"
            @dragstart="isDragging = true"
            @dragend="dragendHandler"
            @click.prevent="editItem(item)"
            >
                <div class="flex items-center gap-2"> 体育館 <span>15:45～21:00</span></div>
                <div class="flex items-center gap-2">校　庭<span>15:45～21:00</span></div>
                <div class="flex items-center gap-2">テニス<span>15:45～21:00</span></div>
            </div>
        </div>
        <button 
            class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-1 rounded-full bg-green absolute right-2 top-1/2 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out"
            @click.prevent="emit('edit')"
          >
            <PencilIcon class="w-5 h-5 text-white" />
          </button>
      </td>
    </tr>
  </tbody>
</table>
</template>
