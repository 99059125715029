<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { ArrowLeftIcon, CameraIcon, MinusCircleIcon } from '@heroicons/vue/24/solid'
import { CalendarIcon } from '@heroicons/vue/24/outline';
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter();
const $root = getCurrentInstance()?.appContext.config.globalProperties
import ValidateHelper from 'helper/ValidateHelper'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import PopoverLanguage from './widgets/PopoverLanguage.vue';
import PopoverPhone from './widgets/PopoverPhone.vue';
import QuestionIcon from './widgets/QuestionIcon.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

import ConfirmModel from './ConfirmModel.vue';
const refConfirmModel = ref(null)

import KycModel from './KycModel.vue';
const refKycModel = ref(null)

const viewKey = ref(0)
const currentYear = new Date().getFullYear()
const club_id = route.params.id
onMounted(async () => {
  if (!fronEndClubStore.club.id) {
    router.push({ path: `/front-end/club-membership/${club_id}` });
  }
  fronEndClubStore.member = Object.assign({}, fronEndClubStore.intMember)
  fronEndClubStore.member.relationship = fronEndClubStore.filter.related == true ? 'blank' : 'other'
  if (!fronEndClubStore.info.member) {
    fronEndClubStore.member.type = 'guest'
    fronEndClubStore.member.phone = fronEndClubStore.filter.phone
    fronEndClubStore.member.related_to_school = fronEndClubStore.filter.related
  }
  else {
    fronEndClubStore.member.type = fronEndClubStore.info.member.type
    fronEndClubStore.member.related_to_school = fronEndClubStore.info.member.related_to_school
    fronEndClubStore.member.related_id = fronEndClubStore.info.member.id
  }
})

const changeKycHandler = (e) => {
  viewKey.value++
  const file = e.target.files[0]
  if (!file) {
    return
  }
  const reader = new FileReader()
  reader.onload = (e) => fronEndClubStore.member.kyc = e.target.result
  reader.readAsDataURL(file)
}

const confirm = async () => {
  await validate()
  if (Object.entries(error.value).length > 0) {
    $root.push.error('必須項目を入力してください')
    return
  }
  if (fronEndClubStore.member.phone_check == false) {
    $root.overlay.loading = true
    fronEndClubStore.validatePhone(fronEndClubStore.member.phone).then((result) => {
      if (result) {
        error.value.phone = true
        error.value.phone_exist = true
      }
      else {
        refConfirmModel.value.open(fronEndClubStore.member)
      }
      $root.overlay.loading = false
    })
  }
  else {
    refConfirmModel.value.open(fronEndClubStore.member)
  }
}

const error = ref({})
const validate = async () => {
  error.value = {}
  if (fronEndClubStore.filter.related && fronEndClubStore.member.relationship == 'blank') {
    error.value.relationship = true
  }
  // Name checks
  const requiredNameFields = ['surname', 'name', 'romaji_surname', 'romaji_name'];
  requiredNameFields.forEach(field => {
    if (!fronEndClubStore.member[field]) {
      error.value[field] = true;
    }
  });
  if (!fronEndClubStore.member.dob) {
    error.value.dob = true
  }
  if (['other', 'relatives'].includes(fronEndClubStore.member.relationship) && !fronEndClubStore.member.kyc) {
    error.value.kyc = true
  }
  if (['staff', 'father', 'mother', 'relatives', 'other'].includes(fronEndClubStore.member.relationship)) {
    if (!fronEndClubStore.member.email || !ValidateHelper.isEmail(fronEndClubStore.member.email)) {
      error.value.email = true
    }
  }
  if (!fronEndClubStore.member.phone_check && !fronEndClubStore.member.phone) {
    error.value.phone = true
  }
}
const changeRelationship = () => {
  if (['student', 'children', 'relatives'].includes(fronEndClubStore.member.relationship)) {
    phoneCheck(true)
  }
  else {
    phoneCheck(false)
  }
  validate()
  removeImage()
}

const phoneCheck = (value) => {
  if (!fronEndClubStore.info.member) {
    fronEndClubStore.member.phone_check = value
    fronEndClubStore.member.phone = fronEndClubStore.filter.phone
  }
  else if (value == true) {
    fronEndClubStore.member.phone_check = true
    if (!fronEndClubStore.info.member) {
      fronEndClubStore.member.phone = fronEndClubStore.filter.phone
    }
    else {
      fronEndClubStore.member.phone = fronEndClubStore.info.member.phone
    }
  }
  else {
    fronEndClubStore.member.phone = ''
    fronEndClubStore.member.phone_check = false
  }
}

const existPhone = ($value) => {
  if ($value) {
    error.value.phone = true
    error.value.phone_exist = true
  }
}

const removeImage = () => {
  if (fronEndClubStore.member.kyc != '') {
    fronEndClubStore.member.kyc = ''
  }
}

const phoneInput = () => {
  fronEndClubStore.member.phone = fronEndClubStore.member.phone.replace(/[^0-9]/g, '');
}
</script>
<template>
  <div :key="viewKey"
    class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div
      class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-md md:shadow-lg">
      <div class="flex flex-row w-full bg-secondary pt-4 px-3 pb-0 justify-between items-center relative">
        <div class="flex w-12 h-12 items-center">
          <button type="button" class="p-1 rounded group transition duration-300 delay-75 ease-in-out"
            @click="$router.go(-1)">
            <ArrowLeftIcon
              class="font-bold w-7 h-7 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
          </button>
        </div>
        <h5 class="text-xl text-white text-center font-bold">{{ $t('member_registration') }}</h5>
        <div class="flex items-center">
          <div class="group transition duration-300 delay-75 ease-in-out">
            <PopoverLanguage />
          </div>
        </div>
      </div>
      <hr />

      <div class="flex flex-col text-base p-4">
        <div class="flex flex-col gap-2">

          <div class="px-4 text-error font-bold" v-if="!fronEndClubStore.filter.related">
            {{ $t('type_other_note', { year1: currentYear - 4 }) }}
          </div>

          <template v-if="fronEndClubStore.filter.related">
            <div class="flex flex-row items-center">
              <div class="flex-none px-2 py-0 justify-start">
                {{ $t('type') }}
              </div>
              <div class="grow p-1 justify-start">
                <select v-model="fronEndClubStore.member.relationship" @change="changeRelationship"
                  :class="error.relationship ? 'border-error' : 'border-grey'"
                  class="px-2 py-1 border rounded shadow-sm w-full bg-white disabled:text-black transition-all delay-75 duration-300 ease-in-out">
                  <template
                    v-for="relation in ['blank', 'staff', 'student', 'father', 'mother', 'relatives', 'children']">
                    <option :value="relation"
                      :disabled="['father', 'mother'].includes(relation) ? fronEndClubStore.availRelationship.includes(relation) : false">
                      {{ $t(`register_type.${relation}`) }}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="px-4 text-error font-bold w-full text-center"
              v-if="fronEndClubStore.member.relationship == 'relatives'">
              {{ $t('type_relatives_note') }}
            </div>
            <div class="px-4 text-error font-bold w-full text-center"
              v-else-if="fronEndClubStore.member.relationship == 'children'">
              {{ $t('type_children_note', { year1: currentYear - 4, year2: currentYear - 6 }) }}
            </div>
          </template>

          <div class="flex flex-row gap-2">
            <div class="flex flex-col w-1/2">
              <div class="flex w-full px-1 py-0 justify-start">
                {{ $t('surname') }}
              </div>
              <div class="flex w-full py-1 justify-start">
                <input type="text" v-model.trim="fronEndClubStore.member.surname" :class="error.surname ? 'border-error' : 'border-grey'"
                  class="px-2 py-1 border rounded w-full" />
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="flex w-full px-1 py-0 justify-start">
                {{ $t('name') }}
              </div>
              <div class="flex w-full py-1 justify-start">
                <input type="text" v-model.trim="fronEndClubStore.member.name" :class="error.name ? 'border-error' : 'border-grey'"
                  class="px-2 py-1 border rounded w-full" />
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-2">
            <div class="flex flex-col w-1/2">
              <div class="flex w-full px-1 py-0 justify-start">
                {{ $t('romaji_surname') }}
              </div>
              <div class="flex w-full py-1 justify-start">
                <input type="text" class="px-2 py-1 border rounded w-full"
                  :class="error.romaji_surname ? 'border-error' : 'border-grey'"
                  v-model.trim="fronEndClubStore.member.romaji_surname" />
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="flex w-full px-1 py-0 justify-start">
                {{ $t('romaji_name') }}
              </div>
              <div class="flex w-full py-1 justify-start">
                <input type="text" class="px-2 py-1 border rounded w-full"
                  :class="error.romaji_name ? 'border-error' : 'border-grey'" v-model.trim="fronEndClubStore.member.romaji_name" />
              </div>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="flex w-full px-1 py-0 justify-start">
              {{ $t('phone_number') }}
            </div>
            <div class="flex flex-row w-full py-1 items-center">
              <div class="grow">
                <input type="text" class="px-2 py-1 border rounded w-full"
                  :class="error.phone && !fronEndClubStore.member.phone_check ? 'border-error' : 'border-grey'"
                  @input="phoneInput" placeholder="0900000000"
                  v-model.trim="fronEndClubStore.member.phone" :disabled="fronEndClubStore.member.phone_check" />
              </div>

              <div class="flex-none w-fit items-center gap-2 justify-center bg-yellow-300 px-2 relative"
                v-if="!fronEndClubStore.filter.related || ['student', 'children', 'relatives'].includes(fronEndClubStore.member.relationship)">
                <label class="flex items-center gap-2 text-sm py-1 cursor-pointer">
                  <input type="checkbox" class="w-6 h-6 appearance-none border-2 border-grey rounded-sm"
                    @click="phoneCheck($event.target.checked)" :class="{
                      'appearance-auto': fronEndClubStore.member.phone_check
                    }" v-model="fronEndClubStore.member.phone_check" />
                  {{ $t('check_use_parent_phone') }}
                  <PopoverPhone />
                </label>
              </div>
            </div>
            <div v-if="['mother', 'father'].includes(fronEndClubStore.member.relationship)">
              <label class="flex items-center gap-2 text-sm py-1 cursor-pointer">
                <input type="checkbox" class="w-8 h-8 border-2 border-grey rounded-sm"
                  v-model="fronEndClubStore.member.phone_exist" />
                {{ $t('allow_phone_exist') }}
              </label>
            </div>
            <div class=" text-sm text-error" v-if="error.phone_exist && !fronEndClubStore.member.phone_check">{{
              $t('phone_exist')
              }}</div>
          </div>

          <div class="flex flex-col"
            v-if="['staff', 'father', 'mother', 'relatives', 'other'].includes(fronEndClubStore.member.relationship)">
            <div class="flex w-full px-1 py-0 justify-start">
              {{ $t('mail_address') }}
            </div>
            <div class="flex w-full py-1 justify-start">
              <input type="text" class="px-2 py-1 border rounded w-full"
                :class="error.email ? 'border-error' : 'border-grey'" v-model.trim="fronEndClubStore.member.email" 
                placeholder="name@example.com"
              />
            </div>
          </div>

          <div class="flex flex-row items-center gap-2">
            <div class="flex-none w-fit">
              <div class="px-1">
                {{ $t('gender') }}
              </div>
              <div>
                <select class="px-2 py-1 border border-grey rounded bg-white" v-model="fronEndClubStore.member.gender">
                  <option value="male">{{ $t('male') }}</option>
                  <option value="female">{{ $t('female') }}</option>
                  <option value="other">{{ $t('other') }}</option>
                </select>
              </div>
            </div>
            <div class="grow flex flex-col">
              <div class="px-1">
                {{ $t('birthday') }}
              </div>
              <div>
                <VueDatePicker :locale="$i18n.locale" format="yyyy-MM-dd" v-model="fronEndClubStore.member.dob" model-type="yyyy-MM-dd"
                  :enable-time-picker="false" auto-apply teleport>
                  <template #input-icon>
                    <CalendarIcon class="ml-2 w-4 h-4" />
                  </template>
                  <template #dp-input="{ value }">
                    <input type="text" :value="value" :class="error.dob ? 'border-error' : 'border-grey'"
                      class="border rounded  w-full px-2 py-1 pl-8" />
                  </template>
                </VueDatePicker>
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center gap-2"
            v-if="['other', 'relatives'].includes(fronEndClubStore.member.relationship)">
            <div class="grow">
              <label
                class="rounded-md p-2 w-full justify-center h-12 cursor-pointer flex items-center gap-2 font-bold hover:opacity-80"
                :class="{
                  'border-error border-2 text-primary ': error.kyc && !fronEndClubStore.member.kyc,
                  'border-primary border-2 text-primary ': !error.kyc && !fronEndClubStore.member.kyc,
                  'bg-tertiary': fronEndClubStore.member.kyc
                }">
                <input type="file" class="hidden" accept="image/png, image/jpeg, image/jpg"
                  @change="changeKycHandler" />
                <CameraIcon v-if="!fronEndClubStore.member.kyc"
                  class="w-7 h-7 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                {{ fronEndClubStore.member.kyc ? $t('kyc_image_reupload') : $t('kyc_image') }}
              </label>
            </div>
            <div class="w-fit flex-none">
              <button type="button" @click="refKycModel.open()"
                class="w-full flex flex-col h-12 py-1 px-2 items-center justify-center text-center group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out border border-grey rounded-md">
                <QuestionIcon :fill="`#FF9500`" />
                <div v-if="!fronEndClubStore.member.kyc">KYC?</div>
              </button>
            </div>
          </div>

          <div class="flex flex-row justify-center items-center gap-4" v-if="fronEndClubStore.member.kyc">
            <div>
              <img :src="fronEndClubStore.member.kyc" class="max-h-60 w-auto">
            </div>

            <div class="group transition duration-300 delay-75 ease-in-out">
              <MinusCircleIcon
                class="w-7 h-7 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out text-error"
                @click.prevent="removeImage" />
            </div>
          </div>

          <div class="flex flex-col bg-graysp" v-if="fronEndClubStore.member.relationship == 'staff'">
            <label class="flex flex-row cursor-pointer items-center gap-2 text-sm py-2 pl-4 pr-2">
              <input type="checkbox" v-model="fronEndClubStore.member.child_enrolled"
                class="w-6 h-6 appearance-none border-2 border-grey rounded-sm" :class="{
                  'appearance-auto': fronEndClubStore.member.child_enrolled
                }" />
              {{ $t('type_staff_note') }}
            </label>
          </div>

          <div class="flex flex-row items-center justify-center gap-4 pt-4">
            <button type="button" @click="$router.go(-1)"
              class="w-1/2 border shadow text-gray-800 font-bold  py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('back') }}
            </button>
            <button type="submit" @click="confirm()"
              class="w-1/2 shadow bg-primary  text-white font-bold py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('confirm_form') }}
            </button>
          </div>

        </div>
      </div>
    </div>
    <ConfirmModel ref="refConfirmModel" @errorPhone="existPhone" />
    <KycModel ref="refKycModel" />
  </div>
</template>
