<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { ArrowLeftIcon, UserIcon, UsersIcon, PlusIcon } from '@heroicons/vue/24/solid'

import { useRoute, useRouter } from 'vue-router'
const route = useRoute();
const router = useRouter();
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import PopoverLanguage from './widgets/PopoverLanguage.vue';

const club_id = route.params.id
onMounted(async () => {
  if (!fronEndClubStore.club.id) {
    router.push({ path: `/front-end/club-membership/${club_id}` });
  }
})

const back = () => {
  router.push({ path: `/front-end/club-membership/${club_id}` });
}

const newMember = ref([])
const addRelate = ($value) => {
  if ($value.checked) {
    newMember.value.push({ 'id': $value.value, 'type': $value.type })
  } else {
    const idx = newMember.value.findIndex(item => item.id === $value.value && item.related === $value.related)
    if (idx !== -1) {
      newMember.value.splice(idx, 1)
    }
  }
}

const error = ref(false)
const register = async () => {
  error.value = false
  if (newMember.value.length == 0) {
    error.value = true
    return
  }
  fronEndClubStore.joinClubExist(newMember.value).then((result) => {
    if (result) {
      router.push({ path: `/front-end/club-membership/${club_id}/3` });
    }
    else {
      $root.push.error(t('error'))
    }

  }).catch((err) => {
    $root.push.error(t('error'))
  });

}

const newRegister = () => {
  router.push({ path: `/front-end/club-membership/${club_id}/2` });
}
</script>
<template>
  <div
    class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div
      class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-md md:shadow-lg">
      <div class="flex flex-row w-full bg-secondary pt-4 px-3 pb-0 justify-between items-center relative">
        <div class="flex w-12 h-12 items-center">
          <button type="button" class="p-1 rounded group transition duration-300 delay-75 ease-in-out"
            @click="$router.go(-1)">
            <ArrowLeftIcon
              class="font-bold w-7 h-7 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
          </button>
        </div>
        <h5 class="text-xl text-white text-center font-bold">{{ $t('member_registration') }}</h5>
        <div class="flex items-center">
          <div class="group transition duration-300 delay-75 ease-in-out">
            <PopoverLanguage />
          </div>
        </div>
      </div>
      <hr />

      <div class="flex flex-col text-base p-4">
        <div class="flex flex-col gap-4">

          <div class="flex flex-col">
            <div class="flex w-full px-2 py-0 justify-start">
              {{ $t('phone_number') }}
            </div>
            <div class="flex w-full p-1 justify-start">
              <input type="text" class="px-2 py-1 border text-grey border-grey bg-gray-100 rounded w-full"
                :value="fronEndClubStore.filter.phone" disabled placeholder="0900000000" />
            </div>
          </div>

          <div class="flex flex-col">
            <div class="w-full p-1 text-center"
              v-html="$t('jsh_members_note.desc1', { club: `<span class='text-primary-dark font-bold'>［${fronEndClubStore.club.name}］</span>` })">
            </div>
            <div class="w-full p-1 text-center" v-html="$t('jsh_members_note.desc2')">
            </div>
          </div>
          <template v-if="fronEndClubStore.info.member">
            <div
              class="flex flex-row items-center gap-2 justify-center pl-2 pr-4 py-4 border border-grey-400 rounded-lg">
              <div class="flex-none justify-start group transition duration-300 delay-75 ease-in-out">
                <UserIcon
                  class="w-7 h-7 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
              </div>
              <div class="grow justify-start">
                {{ fronEndClubStore.info.member.name }}
              </div>
              <div>
                <input type="checkbox" class="w-6 h-6 border-2 border-grey rounded appearance-none" :class="{
                  'appearance-auto': newMember.filter(i => i.type == fronEndClubStore.info.member.type).length
                }"
                  @click="addRelate({ checked: $event.target.checked, value: fronEndClubStore.info.member.id, type: fronEndClubStore.info.member.type })" />
              </div>
            </div>
          </template>

          <template v-for="rel in fronEndClubStore.info.related">
            <div
              class="flex flex-row items-center gap-2 justify-center pl-2 pr-4 py-4 border border-grey-400 rounded-lg">
              <div class="flex-none justify-start group transition duration-300 delay-75 ease-in-out">
                <UsersIcon
                  class="w-7 h-7 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
              </div>
              <div class="grow justify-start">
                {{ rel.name }}
              </div>
              <div>
                <input type="checkbox" class="w-6 h-6 border-2 border-grey rounded appearance-none" :class="{
                  'appearance-auto': newMember.filter(i => i.type == rel.type && i.id == rel.id).length
                }" @click="addRelate({ checked: $event.target.checked, value: rel.id, type: rel.type })" />
              </div>
            </div>
          </template>

          <div class="flex flex-col gap-2 justify-center  text-center">
            <button type="submit" @click="newRegister"
              class="font-bold hover:opacity-80 group flex w-full items-center gap-2 transition duration-300 delay-75 ease-in-out bg-primary justify-center  text-white rounded-lg p-3 h-12">
              <div class="border bg-white rounded-sm">
                <PlusIcon
                  class="w-5 h-5 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out text-primary">
                </PlusIcon>
              </div>
              {{ $t('register_member_other') }}
            </button>
            <div class="text-error text-sm px-3">
              {{ $t('register_member_other_note') }}
            </div>
          </div>


          <div class="text-error text-xs px-3" v-if="error">
            {{ $t('form1_error_joinclub') }}
          </div>

          <div class="flex flex-row items-center justify-center gap-4 pt-4">
            <button type="button" @click="back()"
              class="w-1/2 border shadow font-bold   text-gray-800 py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('back') }}
            </button>
            <button type="submit" @click="register()"
              class="w-1/2 shadow text-white font-bold bg-sky-500 py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('register') }}
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
