<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { PhoneIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../../js/store/club';
import APPJSH from '../../../js/helper/ConstantHelper';
import { useAuthStore } from '../../../js/store/auth';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const authStore = useAuthStore()

const isOpen = ref(false)
const open = async (item) => {
  clubStore.isSaved = false;
  // get item
  clubStore.getManagers()
  isOpen.value = true
}
const close = () => {
  clubStore.searchMemberText = ''
  isOpen.value = false
}

const save = async () => {
  clubStore.isSaved = true 
  clubStore.item.agree_terms = true

  if (!clubStore.item.name) {
    $root.push.error('クラブ名を入力してください')
    return
  }

  if (!clubStore.item.teacher) {
    $root.push.error('責任者氏名を入力してください')
    return
  }

  $root.overlay.loading = true
  const msg = await clubStore.saveItem()
  $root.overlay.loading = false

  $root.push.success(msg)

  clubStore.isSaved = false

  close()
}

const isClubManager = computed(() => {
  return authStore.user && 
    authStore.user.role.includes(APPJSH.ROLES.CLUB) && 
    !authStore.user.role.includes(APPJSH.ROLES.SCHOOL)
})

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <DialogTitle class="px-4 py-2 bg-graysp font-semibold text-lg">
                <!-- title: update club info -->
                クラブ設定
              </DialogTitle>
              <div class="flex flex-col">
                <div class="p-4">
                  <div class="flex w-full mb-4 flex-col">
                    クラブ名
                    <input 
                      v-model="clubStore.item.name" 
                      type="text" 
                      class="w-full px-2 py-1 border border-gray-200 rounded-md transition duration-300 delay-75 ease-in-out" 
                      :class="{
                        'bg-red/10 border-red': clubStore.isSaved && clubStore.item.name.trim() === ''
                      }"
                    />
                  </div>

                  <div class="flex flex-col md:flex-row md:items-center gap-2 w-full">
                    <div class="w-full md:w-1/2 flex flex-col">
                      責任者氏名
                      <input 
                        v-model="clubStore.item.teacher" 
                        type="text" 
                        class="w-full px-2 py-1 border border-gray-200 rounded-md" 
                        :class="{
                          'bg-red/10 border-red': clubStore.isSaved && !clubStore.item.teacher
                        }"
                      />
                    </div>
                    <div class="w-full md:w-1/2 flex flex-col">
                      電話番号
                      <div class="flex items-center relative">
                        <PhoneIcon class="w-6 h-6 text-gray-300 absolute pt-0.5 pl-2" />
                        <input 
                          v-model="clubStore.item.teacher_phone" 
                          type="text" 
                          class="w-full pl-8 pr-2 py-1 border border-gray-200 rounded-md" 
                          placeholder="0900000000"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- manager -->
                  <div class="flex flex-col md:flex-row md:items-start gap-2 mt-2">
                    <div 
                      v-if="!isClubManager"
                      class="w-full md:w-1/2 flex flex-col"
                    >
                      クラブアカウント設定
                      <div class="flex w-full">
                        <select 
                          v-model="clubStore.item.manager" 
                          class="w-full px-2 py-1 border border-gray-200 rounded-md"
                        >
                          <option value="0">未選択</option>
                          <option 
                            v-for="manager in clubStore.managers" 
                            :value="manager.id"
                          >{{ manager.name }}</option>
                        </select>
                      </div>
                      <span class="text-xxs text-error leading-tight">
                        ※必ず当該クラブ代表者のアカウントを設定してください。
                      </span>
                    </div>
                    <!-- email -->
                    <div class="w-full md:w-1/2 flex flex-col">
                      メールアドレス
                      <div class="flex w-full items-center relative">
                        <EnvelopeIcon class="w-6 h-6 text-gray-300 absolute pt-0.5 pl-2" />
                        <input 
                          v-model="clubStore.item.email" 
                          type="text" 
                          class="w-full pl-8 pr-2 py-1 border border-gray-200 rounded-md" 
                          placeholder="name@example.com"
                        />
                      </div>
                      <span class="text-xxs text-gray-400">
                        ※承認・却下時のメール送信に使用します
                      </span>
                    </div>
                  </div>

                  <!-- note -->
                  <div class="flex flex-col mt-2">
                    備考
                    <div>
                      <textarea 
                        v-model="clubStore.item.note" 
                        class="w-full px-2 py-1 border border-gray-200 rounded-md min-h-12 max-h-28" 
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- actions -->
                <div class="flex gap-4 justify-end p-4">
                  <button
                    class="px-4 py-1 bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out font-semibold"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>
                  <button
                    class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out min-w-28 justify-center font-semibold"
                    @click.prevent="save"
                  >
                    保存
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
