<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon, CalendarIcon, PhoneIcon, EnvelopeIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const isSaved = ref(false)
const isOpen = ref(false)
const open = () => {
  isSaved.value = false
  isOpen.value = true
}
const close = () => {
  isSaved.value = false
  isOpen.value = false
}

const save = async () => {
  isSaved.value = true

  // validate
  if (!clubStore.member.name) {
    $root.push.error('名前を入力してください')
    return
  }

  if (!clubStore.member.phone) {
    $root.push.error('電話番号を入力してください')
    return
  }

  $root.overlay.loading = true
  try {
    close()
    const response = await clubStore.addGuest()

    if (response.status === 'error') {
      $root.push.error(response.message)
    } else {
      $root.push.success('メンバーを追加しました')
    }
    
  } catch (error) {
    $root.push.error('メンバーの追加に失敗しました')
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <!-- add member to club #A -->
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 py-2 px-4 bg-graysp justify-between"
              >
                メンバー追加
                <div>
                  <button title="閉じる" class="p-1 transition duration-300 delay-75 ease-in-out" @click="close">
                    <XMarkIcon class="w-6 h-6 text-gray-400" @click="close" />
                  </button>
                </div>
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <div class="flex flex-col gap-2 px-4 py-2">
                  <!-- name -->
                  <div class="flex flex-col">
                    <label class="flex items-center gap-1">
                      名前
                      <span class="text-error">*</span>
                    </label>
                    <input 
                      type="text" 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      :class="{
                        'bg-red/10 border-red': isSaved && !clubStore.member.name
                      }"
                      v-model="clubStore.member.name"
                    />
                  </div>
                  
                  <div class="flex items-center gap-2">
                    <!-- phone -->
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        電話番号
                        <span class="text-error">*</span>
                      </label>
                      <div class="relative min-w-48">
                        <PhoneIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                        <input 
                          type="text" 
                          class="px-2 py-1 border border-gray-200 rounded w-full pl-8" 
                          :class="{
                            'bg-red/10 border-red': isSaved && !clubStore.member.phone
                          }"
                          v-model="clubStore.member.phone"
                          placeholder="0900000000"
                        />
                      </div>
                    </div>

                    <!-- email -->
                    <div class="flex flex-col w-1/2">
                      <label>メールアドレス</label>
                      <div class="relative min-w-64">
                        <EnvelopeIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                        <input 
                          type="email" 
                          class="px-2 py-1 border border-gray-200 rounded w-full pl-8" 
                          v-model="clubStore.member.email"
                          placeholder="name@example.com"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex items-end gap-2">
                    <!-- gender -->
                    <div class="flex gap-2 items-center w-1/2">
                      <div class="grow">
                        <div class="flex flex-col">
                          <label>性別</label>
                          <div>
                            <select 
                              class="px-2 py-1 border border-gray-200 rounded w-full" 
                              v-model="clubStore.member.gender"
                            >
                              <option value="male">男性</option>
                              <option value="female">女性</option>
                              <option value="other">その他</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- dob -->
                      <div class="flex-none w-fit">
                        <div class="flex flex-col w-fit">
                          <label>生年月日</label>
                          <div class="max-w-40">
                            <VueDatePicker 
                              v-model="clubStore.member.dob" 
                              auto-apply 
                              format="yyyy-MM-dd"
                              :enable-time-picker="false"
                              locale="ja"
                              input-class-name="px-2 py-1 border border-gray-200 rounded"
                            >
                              <template #input-icon>
                                <CalendarIcon class="ml-2 w-4 h-4" />
                              </template>
                              <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
                                <input type="text" :value="value" class="pl-8 pr-2 py-1 border border-gray-200 rounded max-w-40" placeholder="yyyy-mm-dd" />
                              </template>
                            </VueDatePicker>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex w-1/2">
                      <!-- 
                        クラブ一覧　のメンバー数の所、全メンバー数の隣に、学校関係者、子供の人数を表示 
                      -->
                      <label class="flex items-center gap-1 mb-1.5">
                        <input 
                          type="checkbox" 
                          class="w-4 h-4" 
                          v-model="clubStore.member.related_to_school"
                        />
                        学校関連
                      </label>
                    </div>
                  </div>

                  <!-- note -->
                  <div class="flex flex-col">
                    <label>備考</label>
                    <textarea 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      rows="3"
                      v-model="clubStore.member.note"
                    ></textarea>
                  </div>

                  <div class="flex items-start gap-2">
                    <div class="w-full flex items-end gap-2">
                      <div class="flex flex-col w-fit">
                        <label for="expired_at">QRコード有効期限</label>
                        <div class="max-w-40">
                          <VueDatePicker 
                            v-model="clubStore.member.expired_at" 
                            locale="ja"
                            format="yyyy-MM-dd"
                            :enable-time-picker="false"
                            auto-apply
                            teleport
                          >
                            <template #input-icon>
                              <CalendarIcon class="ml-2 w-4 h-4" />
                            </template>
                            <template #dp-input="{ value }">
                              <input 
                                type="text" 
                                :value="value" 
                                class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-40"
                                placeholder="yyyy-mm-dd"
                              />
                            </template>
                          </VueDatePicker>
                        </div>
                      </div>
                      <div class="text-xxs text-gray-400 leading-tight mb-1">
                        ※QRコードは有効期限が過ぎると使用できなくなります。
                        <div>
                          ※有効期限を設定しない場合は、QRコードは無期限で使用できます。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center justify-between mt-2 w-full bg-gray-300/10 p-4 border-t">
                  <label class="flex items-center gap-1">
                    <input 
                      type="checkbox" 
                      class="form-checkbox rounded text-primary w-4 h-4" 
                      v-model="clubStore.autoGenerateQrcode"
                    />
                    メンバー追加後、QRコードを自動生成する
                  </label>
                  <div class="flex items-center gap-2">
                    <!-- cancel -->
                    <button 
                      class="px-4 py-1 bg-white border rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="close"
                    >
                      キャンセル
                    </button>
                    <!-- add guest -->
                    <button 
                      class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="save"
                    >
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
