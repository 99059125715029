<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { useClubScheduleStore } from '../../../js/store/club-schedule';
import DateTimeHelper from '../../../js/helper/DatetimeHelper.js';
import InputTime from '../../components/InputTime.vue';

const emit = defineEmits(['refresh'])
const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubScheduleStore = useClubScheduleStore()

const item = ref({})
const infors = ref([])
const isOpen = ref(false)

const open = async (i) => {
  // 利用可否情報
  // get rooms and status: available or not
  const response = await clubScheduleStore.getRoomsInfor(i.date)
  infors.value = response.data.data

  item.value = { ...i }
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const save = () => {
  if (DateTimeHelper.isSameOrAfter(item.value.start, item.value.end)) {
    $root.push.error('開始時間が終了時間以降になっています')
    return
  }

  $root.overlay.loading = true
  clubScheduleStore.update({
    ...item.value,
    start: DateTimeHelper.format(`${item.value.date} ${item.value.start}:00`, 'YYYY-MM-DD HH:mm:ss'),
    end: DateTimeHelper.format(`${item.value.date} ${item.value.end}:59`, 'YYYY-MM-DD HH:mm:ss'),
  }).then(() => {
    $root.overlay.loading = false
    close()
    emit('refresh')
    $root.push.success('保存しました')
  })
}

defineExpose({ open, close })
</script>
<template>
  <!-- Modal / 申請画面_01_利用申請 -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-fit transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <DialogTitle class="text-lg text-primary font-semibold bg-gray-100 px-4 py-1">
                  承認設定
                </DialogTitle>
              <div class="flex flex-col gap-2 p-4">
                <div class="flex items-center justify-between gap-8">
                  <div class="text-lg font-semibold">
                    {{ DateTimeHelper.format(item.date, 'YYYY年M月D日（ddd）') }}
                  </div>
                  <div class="flex items-center gap-2">
                    利用可否情報
                    <div class="border p-1 flex flex-col">
                      <table>
                        <tbody>
                          <tr v-for="infor in infors">
                            <td class="px-1">{{ infor.name }}</td>
                            <td class="px-1">
                              <div class="flex items-center gap-2">
                                {{ infor.closed ? '✕' : (infor.default ? '◯' : infor.time) }}
                                <span class="max-w-32 truncate" :title="infor.note">{{ infor.note }}</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="flex items-start gap-6">
                  <div class="flex flex-col">
                    <div class="text-xs text-gray-400">申請施設</div>
                    <div class="p-1">{{ item.roomName }}</div>
                  </div>
                  <div class="flex flex-col">
                    <div class="text-xs text-gray-400">申請利用時間</div>
                    <div class="flex items-center gap-1 p-1">
                      <div class="w-fit">
                        <InputTime 
                          v-model="item.start" 
                          :stepminute="15" 
                          :class="{
                             'bg-error/30': DateTimeHelper.isSameOrAfter(item.start, item.end)
                          }"
                        />
                      </div>
                      <div>〜</div>
                      <div class="w-fit">
                        <InputTime 
                          v-model="item.end" 
                          :stepminute="15" 
                          :class="{
                             'bg-error/30': DateTimeHelper.isSameOrAfter(item.start, item.end)
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="text-xs text-gray-400">承認状況</div>
                  <div class="p-1 flex items-center gap-6">
                    <label class="rounded p-0.5 flex gap-1 items-center border-2 transition-all delay-75 duration-300 ease-in-out"
                      :class="{
                        'border-primary': item.status === 'pending',
                        'border-white': item.status !== 'pending',
                      }"
                    >
                      <input type="radio" name="status" value="pending" class="w-5 h-5" v-model="item.status" />
                      <div class="px-4 py-1 rounded bg-yellow-200 text-yellow-500">承認待ち</div>
                    </label>
                    <label class="rounded p-0.5 flex gap-1 items-center border-2 transition-all delay-75 duration-300 ease-in-out"
                      :class="{
                        'border-primary': item.status === 'approved',
                        'border-white': item.status !== 'approved',
                      }"
                    >
                      <input type="radio" name="status" value="approved" class="w-5 h-5" v-model="item.status" />
                      <div class="px-4 py-1 rounded bg-oblue-100 text-oblue">承認済み</div>
                    </label>
                    <label class="rounded p-0.5 flex gap-1 items-center border-2 transition-all delay-75 duration-300 ease-in-out"
                      :class="{
                        'border-primary': item.status === 'rejected',
                        'border-white': item.status !== 'rejected',
                      }"
                    >
                      <input type="radio" name="status" value="rejected" class="w-5 h-5" v-model="item.status" />
                      <div class="px-4 py-1 rounded bg-red-50 text-error">申請却下</div>
                    </label>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex items-end gap-2">
                    <div class="text-xs text-gray-400">管理メモ</div>
                    <div class="text-xxs text-error">
                      ※学校側のみ閲覧可能なメモです。
                    </div>
                  </div>
                  <div>
                    <textarea class="w-full border border-gray-200 rounded px-2 py-1" v-model="item.admin_memo"></textarea>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex items-end gap-2">
                    <div class="text-xs text-gray-400">共有メモ</div>
                    <div class="text-xxs text-error">
                      ※クラブ側も閲覧可能なメモです。
                    </div>
                  </div>
                  <div>
                    <textarea class="w-full border border-gray-200 rounded px-2 py-1" v-model="item.shared_memo"></textarea>
                  </div>
                </div>
                <div class="flex justify-center gap-6 mt-4 font-semibold">
                  <div>
                    <button class="px-4 py-2 min-w-32 border border-gray-400 text-gray-500 rounded hover:opacity-80" @click.prevent="close">
                      閉じる
                    </button>
                  </div>
                  <div>
                    <button class="px-4 py-2 min-w-32 bg-primary text-white rounded hover:opacity-80" @click.prevent="save">
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
