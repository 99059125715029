<script setup>
import { ref, computed } from 'vue'
import { useAuthStore } from '../../../../js/store/auth';
import DateTimeHelper from '../../../../js/helper/DatetimeHelper.js';
import TableItemCancelModal from './TableItemCancelModal.vue';

const emit = defineEmits(['refresh'])
const authStore = useAuthStore()
const props = defineProps({
  items: { type: Array, default: [] }
})

const refTableItemCancelModal = ref(null)

const statusText = (status) => {
  if (status === 'pending') {
    return '未承認'
  }

  if (status === 'rejected') {
    return '申請却下'
  }

  return '承認済'
}

const itemStatus = (item) => {
  if (item.rooms.every(room => room.status === 'cancelled')) {
    return 'cancelled'
  }

  if (item.rooms.every(room => room.status === 'rejected')) {
    return 'rejected'
  }

  if (item.rooms.every(room => room.status === 'pending')) {
    return 'pending'
  }

  return 'approved'
}

const cancel = (item) => {
  refTableItemCancelModal.value.open(item)
}

const isApprovedAll = computed(() => {
  if (props.items.length === 0) {
    return false
  }
  
  return props.items.every(item => item.rooms.every(room => room.status === 'approved' || room.status === 'cancelled'))
})
</script>
<template>
  <div class="flex flex-col gap-2 sm:gap-4 md:gap-8 w-full md:w-fit">
    <div class="relative scroll-smooth flex overflow-x-auto md:overflow-x-hidden">
      <table class="w-full divide-y divide-gray-200">
        <thead class="border-t-2 border-t-primary bg-graysp">
          <tr>
            <th class="font-normal sticky top-0 left-0 bg-graysp z-10">日付</th>
            <th class="font-normal w-fit">承認状況</th>
            <th class="font-normal w-20">調整＆メモ</th>
            <th class="font-normal">利用施設</th>
            <th class="font-normal">利用時間</th>
            <th class="font-normal min-w-max">
              <span class="flex min-w-max">利用日取り消し</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in items">
            <tr v-for="(room, idx) in item.rooms"
              class="relative"
              :class="{
                'border-b border-gray-200': item.rooms.length == 1 || (item.rooms.length > 1 && idx == item.rooms.length - 1),
                'bg-gray-100': itemStatus(item) === 'cancelled',
                'bg-yellow-300': itemStatus(item) === 'pending',
              }"
            >
              <td class="p-1 border-r sticky left-0 z-10" 
                v-if="item.rooms.indexOf(room) === 0"
                :rowspan="item.rooms.length"
                :class="{
                  'text-pblue bg-pblue-200': DateTimeHelper.getDayOfWeekShort(item.start_at) === '土',
                  'text-red bg-red-200': DateTimeHelper.getDayOfWeekShort(item.start_at) === '日',
                  'text-gray-400 bg-grey-100': itemStatus(item) === 'cancelled',
                  'bg-yellow-300': itemStatus(item) === 'pending',
                  'bg-white': itemStatus(item) !== 'cancelled' && itemStatus(item) != 'pending' && DateTimeHelper.getDayOfWeekShort(item.start_at) != '土' && DateTimeHelper.getDayOfWeekShort(item.start_at) != '日',
                }"
              >
                <div class="flex justify-center">
                  <div class="flex items-center justify-between w-8 md:w-10">
                    <div>{{ DateTimeHelper.format(item.start_at, 'D')  }}</div>
                    <div>{{ DateTimeHelper.getDayOfWeekShort(item.start_at) }}</div>
                  </div>
                </div>
              </td>
              <td class="p-1 border-b w-fit"
                :class="{
                  'bg-grey-100': room.status === 'cancelled',
                  'border-gray-200': item.rooms.length - 1 === idx,
                }"
              >
                <div class="flex justify-center">
                  <div 
                    class="px-2 md:px-4 py-0.5 text-center w-fit font-semibold min-w-max"
                    :class="{
                      'bg-yellow-200 text-yellow-500': room.status === 'pending',
                      'bg-oblue-100 text-oblue': room.status !== 'pending',
                      'bg-red-200 text-red': room.status === 'rejected',
                    }"
                  >
                    {{ statusText(room.status) }}
                  </div>
                </div>
              </td>
              <td class="p-1 border-b"
                :class="{
                  'bg-grey-100': room.status === 'cancelled',
                  'border-gray-200': item.rooms.length - 1 === idx,
                }"
              >
                <div class="flex items-center justify-end gap-2 w-full min-w-20">
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{
                      'opacity-0': !room.tonal,
                    }">
                      <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" fill="white"/>
                      <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#4DC0B2"/>
                      <path d="M12.688 7.84H16.688V9.248H12.688V7.84ZM12.608 10.32H16.8V11.728H12.608V10.32ZM13.92 6.8H15.408V11.136H13.92V6.8ZM11.344 4.992H17.968V6.624H11.344V4.992ZM13.472 12.544H16.672V16.8H13.472V15.424H15.296V13.92H13.472V12.544ZM12.72 12.544H14.064V17.36H12.72V12.544ZM17.28 4.992H19.056V17.296C19.056 18.224 18.896 18.768 18.352 19.072C17.824 19.392 17.024 19.424 15.888 19.424C15.824 18.928 15.6 18.048 15.344 17.552C16.016 17.6 16.752 17.584 16.992 17.584C17.216 17.584 17.28 17.52 17.28 17.28V4.992ZM10.416 4.992H12.144V10.992C12.144 13.536 11.968 17.216 10.704 19.52C10.4 19.216 9.616 18.672 9.216 18.48C10.336 16.384 10.416 13.312 10.416 10.992V4.992ZM5.136 9.328H9.392V10.768H5.136V9.328ZM5.232 4.912H9.344V6.368H5.232V4.912ZM5.136 11.504H9.392V12.96H5.136V11.504ZM4.464 7.072H9.792V8.592H4.464V7.072ZM6.016 13.728H9.36V18.56H6.016V17.056H7.824V15.232H6.016V13.728ZM5.088 13.728H6.592V19.232H5.088V13.728Z" fill="#37AB9D"/>
                    </svg>
                  </div>
                  <div class="group relative">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{
                      'opacity-0': !room.shared_memo
                    }">
                      <g clip-path="url(#clip0_1427_27747)">
                        <path d="M12 0C5.3831 0 0 4.86217 0 10.8387C0 11.708 0.116797 12.5617 0.336548 13.3869C0.661767 14.6169 1.22294 15.7793 2.00042 16.8322C2.47874 17.4818 3.03425 18.0891 3.66691 18.6397V21.3795V23.4009C3.69231 23.4009 3.69231 24.337 4.61538 23.869L6.51949 22.9569L9.27853 21.397C10.1671 21.5832 11.0794 21.6772 12 21.6772C18.6169 21.6772 24 16.815 24 10.8387C24 4.86217 18.6169 0 12 0Z" fill="#37AB9D"/>
                        <path d="M8.4181 8.54991L4.68364 14.4628C4.68364 14.4628 4.45268 14.8535 4.83951 15.2409C4.83951 15.2409 5.15057 15.3965 5.46165 15.2409C5.77274 15.0853 9.35171 12.2844 9.35171 12.2844C9.35171 12.2844 9.66265 12.1288 9.81852 12.1288C9.97439 12.1288 10.2853 12.2844 10.2853 12.2844L12.9306 14.3072C12.9306 14.3072 13.4162 14.7417 14.1754 14.7741C14.9346 14.8064 15.5146 14.2545 15.5758 14.1516C15.5758 14.1516 19.1017 8.65415 19.3103 8.23873C19.5189 7.82332 19.2291 7.53336 19.1547 7.46069C19.0802 7.38803 18.7869 7.33437 18.5325 7.4607C18.2782 7.58703 14.7978 10.2615 14.7978 10.2615L14.1754 10.5727L13.553 10.2615L11.0633 8.39431C10.4409 7.92757 10.1297 7.92755 9.50741 7.9275H9.50732C8.885 7.92746 8.4181 8.54991 8.4181 8.54991Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1427_27747">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <div v-if="room.shared_memo" class="px-2 py-1 shadow-lg bg-yellow-600 hidden group-hover:block absolute group-hover:z-10 left-6 min-w-40 min-h-20 bottom-4">
                      {{ room.shared_memo }}
                    </div>
                    <div v-if="authStore.user.role.includes('school') && room.admin_memo" class="px-2 py-1 shadow-lg bg-pink-100 hidden group-hover:block absolute group-hover:z-10 left-6 min-w-40 min-h-20 top-4">
                      {{ room.admin_memo }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="border-b"
                :class="{
                  'text-gray-400 bg-grey-100': room.status === 'cancelled',
                  'border-gray-200': item.rooms.length - 1 === idx,
                }"
              >
                <div class="flex justify-center truncate min-w-28 max-w-32 md:max-w-none">
                  {{ room.room_name }}
                </div>
              </td>
              <td class="p-1 border-b"
                :class="{
                  'text-gray-400 bg-grey-100': room.status === 'cancelled',
                  'border-gray-200': item.rooms.length - 1 === idx,
                }"
              >
                <div class="flex justify-center min-w-max">
                  {{ DateTimeHelper.format(room.start, 'HH:mm') }} ～ {{ DateTimeHelper.format(room.end, 'HH:mm') }}
                </div>
              </td>
              <td class="p-1 border-b min-w-max"
                :class="{
                  'bg-grey-100': room.status === 'cancelled',
                  'border-gray-200': item.rooms.length - 1 === idx,
                }"
              >
                <div class="flex justify-center font-semibold text-white">
                  <button v-if="room.status === 'approved'" class="px-2 md:px-4 py-0.5 rounded bg-error md:hover:opacity-80 min-w-max" @click.prevent="cancel(room)">
                    利用取消する
                  </button>
                  <span v-else-if="room.status === 'cancelled'" class="px-2 md:px-4 py-0.5 rounded bg-gray-400 md:hover:cursor-not-allowed min-w-max">
                    利用日取消済
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="flex flex-col justify-between text-xs gap-1 p-2 md:p-4 rounded border border-gray-200 divide-y divide-gray-200">
      <div class="flex flex-col gap-1">
        <div class="flex items-start gap-2 md:gap-0 md:items-center">
          <div class="w-20 flex justify-end">
            <div class="px-2 md:px-4 py-0.5 font-semibold text-yellow-500 bg-yellow-200 w-full text-center min-w-20">未承認</div>
          </div>
          ：学校が承認前の表示。利用施設や時間を変更して、再申請が可能です。
        </div>
        <div class="flex items-start gap-2 md:gap-0 md:items-center">
          <div class="w-20 flex justify-end">
            <div class="px-2 md:px-4 py-0.5 font-semibold text-oblue bg-oblue-100 w-full text-center min-w-20">承認済</div>
          </div>
          <div class="flex flex-col md:flex-row md:items-center">
            ：申請が承認され、施設利用できます。
            <span class="text-error">
              ※時間変更は学校の担当者に連絡してください。
            </span>
          </div>
        </div>
        <div class="flex items-start gap-2 md:gap-0 md:items-center">
          <div class="w-20 flex justify-end">
            <div class="px-2 md:px-4 py-0.5 font-semibold text-error bg-red-50 w-full text-center min-w-20">申請却下</div>
          </div>
          ：申請が承認されませんでした。利用施設や時間を変更して、再申請可能です。
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:items-start gap-2 md:justify-center pt-4">
        <div class="flex flex-col gap-1">
          <div class="flex items-center">
            <div class="flex justify-end">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" fill="white"/>
                <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#4DC0B2"/>
                <path d="M12.688 7.84H16.688V9.248H12.688V7.84ZM12.608 10.32H16.8V11.728H12.608V10.32ZM13.92 6.8H15.408V11.136H13.92V6.8ZM11.344 4.992H17.968V6.624H11.344V4.992ZM13.472 12.544H16.672V16.8H13.472V15.424H15.296V13.92H13.472V12.544ZM12.72 12.544H14.064V17.36H12.72V12.544ZM17.28 4.992H19.056V17.296C19.056 18.224 18.896 18.768 18.352 19.072C17.824 19.392 17.024 19.424 15.888 19.424C15.824 18.928 15.6 18.048 15.344 17.552C16.016 17.6 16.752 17.584 16.992 17.584C17.216 17.584 17.28 17.52 17.28 17.28V4.992ZM10.416 4.992H12.144V10.992C12.144 13.536 11.968 17.216 10.704 19.52C10.4 19.216 9.616 18.672 9.216 18.48C10.336 16.384 10.416 13.312 10.416 10.992V4.992ZM5.136 9.328H9.392V10.768H5.136V9.328ZM5.232 4.912H9.344V6.368H5.232V4.912ZM5.136 11.504H9.392V12.96H5.136V11.504ZM4.464 7.072H9.792V8.592H4.464V7.072ZM6.016 13.728H9.36V18.56H6.016V17.056H7.824V15.232H6.016V13.728ZM5.088 13.728H6.592V19.232H5.088V13.728Z" fill="#37AB9D"/>
              </svg>
            </div>
            ：学校が日時を調整した予定に表示。
          </div>
          <div class="flex items-center">
            <div class="flex justify-end">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_27747)">
                  <path d="M12 0C5.3831 0 0 4.86217 0 10.8387C0 11.708 0.116797 12.5617 0.336548 13.3869C0.661767 14.6169 1.22294 15.7793 2.00042 16.8322C2.47874 17.4818 3.03425 18.0891 3.66691 18.6397V21.3795V23.4009C3.69231 23.4009 3.69231 24.337 4.61538 23.869L6.51949 22.9569L9.27853 21.397C10.1671 21.5832 11.0794 21.6772 12 21.6772C18.6169 21.6772 24 16.815 24 10.8387C24 4.86217 18.6169 0 12 0Z" fill="#37AB9D"/>
                  <path d="M8.4181 8.54991L4.68364 14.4628C4.68364 14.4628 4.45268 14.8535 4.83951 15.2409C4.83951 15.2409 5.15057 15.3965 5.46165 15.2409C5.77274 15.0853 9.35171 12.2844 9.35171 12.2844C9.35171 12.2844 9.66265 12.1288 9.81852 12.1288C9.97439 12.1288 10.2853 12.2844 10.2853 12.2844L12.9306 14.3072C12.9306 14.3072 13.4162 14.7417 14.1754 14.7741C14.9346 14.8064 15.5146 14.2545 15.5758 14.1516C15.5758 14.1516 19.1017 8.65415 19.3103 8.23873C19.5189 7.82332 19.2291 7.53336 19.1547 7.46069C19.0802 7.38803 18.7869 7.33437 18.5325 7.4607C18.2782 7.58703 14.7978 10.2615 14.7978 10.2615L14.1754 10.5727L13.553 10.2615L11.0633 8.39431C10.4409 7.92757 10.1297 7.92755 9.50741 7.9275H9.50732C8.885 7.92746 8.4181 8.54991 8.4181 8.54991Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_27747">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            ：学校からのメッセージがある予定に表示。
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="bg-error text-white font-semibold rounded px-2 md:px-4 py-1">
              利用取消する
            </div>
            ：利用日のキャンセルができます。
          </div>
          <div class="flex items-center gap-1">
            <div class="bg-gray-400 text-white font-semibold rounded px-2 md:px-4 py-1">
              利用日取消済
            </div>
            ：利用日をキャンセルした表示です。
          </div>
        </div>
      </div>
    </div>
  </div>
  <TableItemCancelModal ref="refTableItemCancelModal" @refresh="emit('refresh')" />
</template>