import { defineStore } from 'pinia'

export const useCalendarFactility = defineStore('calendar-factility', {
  state: () => ({
    items: [],
    year: new Date().getFullYear(),
    date: null,
    isHoliday: false,
    isWeekend: false,
    settingDay: [],
    isUpdate: false
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/classroom-hours?year=` + this.year);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    },
    async update() {
      return await axios.put('/api/classroom-hours/', this.settingDay);
    },
    async save() {
      return await axios.post('/api/classroom-hours', this.settingDay);
    },
    setDay() {

    }
  },
})
