<script setup>
import { ref,getCurrentInstance, onMounted} from 'vue';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ModalHoliday from './ModalHoliday.vue';
import { useHoliday } from '../../../js/store/holiday';
import DateTimeHelper from '../../../js/helper/DatetimeHelper'

const modalHoliday = ref(null);
const $root = getCurrentInstance()?.appContext.config.globalProperties
const holidayStore = useHoliday()
const newItem = ref({id: null, name: '', date: new Date(), status: 1 })
const openModal = (item) => {
  holidayStore.item = JSON.parse(JSON.stringify(item))
  modalHoliday.value.open();
};
onMounted(() => {
  let date = new Date();
  holidayStore.year = date.getMonth() < 3 ? date.getFullYear() - 1 : date.getFullYear();
  holidayStore.getItems();
})
const removeItem = async(id) => {
  const confirm = window.confirm('削除しますか？');
  if (!confirm) return;
  $root.overlay.loading = true
  try {
      const response = await axios.delete('/api/holidays/'+ id, holidayStore.item);
      if(response.status == 200) {
        $root.push.success(response.data.message)
        holidayStore.getItems();
        close();
      } else {
        $root.push.error(response.data.message)
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
    $root.overlay.loading = false
    return
};
const handleIpmort = async(event) => {
    let file = event.target.files[0];
    console.log(event);
    $root.overlay.loading = true
    let formData = new FormData();
    formData.append('file', file);

    try {
      let response = await axios.post('/api/holidays/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.status == 200) {
          $root.push.success(response.data.message)
          await holidayStore.getItems();
        } else {
          $root.push.error(response.data.message)
        }
        close();
        event.target.value = null;
        $root.overlay.loading = false
    } catch (error) {
        $root.push.error('エラーが発生しましたし。ばらくしてからもう一度お試しください。')
        console.log(error.message);
        $root.overlay.loading = false
        event.target.value = null;
    }
}
</script>
<template>
  <div class="flex flex-col p-6 gap-2">
    <div class="flex items-center justify-between">
      <div>
        <button class="px-4 py-1 rounded bg-primary font-semibold text-white hover:opacity-80" @click.prevent="openModal(newItem)">新規登録</button>
      </div>
      <div>
        <select v-model="holidayStore.year" class="px-4 py-1 rounded bg-white border border-gray-300 mr-2" @change="holidayStore.getItems()">
          <option v-for="i in 7" :value="`${(new Date().getFullYear() - i) + 6}`">{{(new Date().getFullYear() - i) + 6}}年度</option>
        </select>
        <span class="text-error"> ※年度毎の設定になります。4月から翌年3月までの祝日の設定できます。</span>
      </div>
      <div class="flex items-center gap-2 justify-end text-white font-semibold">
        <label class="flex items-center gap-1 px-4 py-1 rounded bg-blue-500 cursor-pointer hover:opacity-80">
          <svg class="w-4 h-4 text-white" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V1ZM2 12H5.416C5.80509 12.8921 6.44606 13.6514 7.26031 14.1846C8.07456 14.7178 9.0267 15.0018 10 15.0018C10.9733 15.0018 11.9254 14.7178 12.7397 14.1846C13.5539 13.6514 14.1949 12.8921 14.584 12H18V16H2V12ZM2 2H18V10H13C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10H2V2ZM14 6H11V3H9V6H6L10 10.5L14 6Z" fill="white"/>
          </svg>
          インポート
          <input type="file" accept=".xls,.xlsx"  class="hidden" @change="handleIpmort"/>
        </label>
        <button class="px-4 py-1 rounded bg-error hover:opacity-80"><a href="storage/template/sample_import_holiday.xlsx" download="sample_年間行事_施設利用_祝日設定.xlsx">サンプルファイル</a></button>
      </div>
    </div>
    <div>
      <table class="table w-full divide-y border-b">
        <thead class="border-t-2 border-t-secondary bg-gray-100">
          <tr>
            <th class="font-normal text-left p-1">日付</th>
            <th class="font-normal text-left p-1">曜日</th>
            <th class="font-normal text-left p-1">休日名</th>
            <th class="font-normal text-center p-1">表示(有効・無効)</th>
            <th class="font-normal text-center p-1">操作</th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <tr v-for="holiday in holidayStore.items">
            <td class="p-1">{{ holiday.date }}</td>
            <td class="p-1">{{ DateTimeHelper.getJapaneseWeekday(holiday.date) }}</td>
            <td class="p-1">{{ holiday.name }}</td>
            <td class="p-1">
              <div class="flex justify-center" v-if="holiday.status">
                <svg class="w-5 h-5 fill-primary" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4944 3.13333C19.716 3.00004 19.9806 2.95776 20.2327 3.01537C20.4848 3.07299 20.7047 3.22601 20.8464 3.44233L21.8364 4.95233C21.9655 5.14959 22.0207 5.3861 21.9923 5.62011C21.964 5.85413 21.8539 6.0706 21.6814 6.23133L21.6784 6.23533L21.6644 6.24833L21.6074 6.30133L21.3824 6.51633C20.1374 7.72392 18.9302 8.96986 17.7624 10.2523C15.5654 12.6683 12.9564 15.8303 11.2004 18.8983C10.7104 19.7543 9.51342 19.9383 8.80342 19.1993L2.31842 12.4613C2.22549 12.3647 2.15292 12.2504 2.10504 12.1252C2.05716 12 2.03493 11.8665 2.03969 11.7325C2.04445 11.5986 2.07609 11.4669 2.13274 11.3454C2.18938 11.2239 2.26987 11.1151 2.36942 11.0253L4.32942 9.25733C4.50167 9.10204 4.72253 9.01144 4.9542 9.00104C5.18588 8.99064 5.41396 9.06109 5.59942 9.20033L8.90843 11.6813C14.0774 6.58433 17.0084 4.62833 19.4944 3.13333Z" />
                </svg>
              </div>
              <div class="flex justify-center" v-else>
                <svg class="w-4 h-4 fill-gray-500" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.12708 3.14769L14.8532 13.8738C16.0712 12.4141 16.6992 10.5515 16.6136 8.65224C16.5281 6.75298 15.7352 4.95442 14.3908 3.61008C13.0465 2.26574 11.2479 1.47286 9.34868 1.3873C7.44943 1.30174 5.58685 1.92968 4.12708 3.14769ZM13.8748 14.8523L3.14862 4.12615C1.9306 5.58593 1.30266 7.4485 1.38822 9.34776C1.47378 11.247 2.26666 13.0456 3.611 14.3899C4.95534 15.7343 6.75391 16.5271 8.65316 16.6127C10.5524 16.6983 12.415 16.0703 13.8748 14.8523ZM2.63631 2.63631C6.15046 -0.878769 11.8486 -0.878769 15.3637 2.63631C18.8788 6.15138 18.8788 11.8486 15.3637 15.3637C11.8486 18.8788 6.15138 18.8788 2.63631 15.3637C-0.878769 11.8486 -0.878769 6.15138 2.63631 2.63631Z" />
                </svg>
              </div>
            </td>
            <td class="p-1">
              <div class="flex items-center justify-center gap-2">
                <button class="p-1.5 rounded-full bg-green" @click.prevent="openModal(holiday)">
                  <PencilIcon class="w-4 h-4 text-white" />
                </button>
                <button class="p-1.5 rounded-full bg-error text-white hover:opacity-80 transition delay-75 duration-300 ease-in-out" @click.prevent="removeItem(holiday.id)">
                  <TrashIcon class="w-4 h-4" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ModalHoliday ref="modalHoliday" />
</template>
