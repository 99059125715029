<script setup>
import { ref, watch } from 'vue';
import CalendarHelper from '../../../js/helper/CalendarHelper';
import { PencilIcon } from '@heroicons/vue/24/solid';

import { useCalendarFactility } from '../../../js/store/calendar-factility';
const facilityStore = useCalendarFactility();

import { useHoliday } from '../../../js/store/holiday';
const holidayStore = useHoliday();

import { useResource } from '../../../js/store/resource';
const resourcTimeStore = useResource();

import { useRoom } from '../../../js/store/room';
const roomStore = useRoom();

const emit = defineEmits(['edit']);
const props = defineProps({
  year: Number,
  month: Number,
  day: Number,
  data: Object
});
const openTimePicker = ref(null)
const closeTimePicker = ref(null)

const isHoliday = () => {
  let dateString = `${props.year}-${("0" + props.month).slice(-2)}-${("0" + props.day).slice(-2)}`;
  return holidayStore.items.findIndex((i) => i.date == dateString  && i.status == 1) >= 0
}

const editItem = () => {
  facilityStore.date = `${props.year}-${("0" + props.month).slice(-2)}-${("0" + props.day).slice(-2)}`;
  facilityStore.isWeekend = CalendarHelper.isWeekend(props.year, props.month, props.day);
  facilityStore.isHoliday = isHoliday();

  if(props.data.daySetting.length) {
    facilityStore.isUpdate = true;
    facilityStore.settingDay = JSON.parse(JSON.stringify(props.data.daySetting))
    facilityStore.settingDay.map((stg) => {
      stg.default_hours = !!stg.default_hours;
      stg.closed = !!stg.closed;
      stg.name = stg.classroom.name;
      stg.opening_time = stg.opening_time ? { "hours": stg.opening_time.split(':')[0], "minutes": stg.opening_time.split(':')[1]} : null;
      stg.closing_time =stg.closing_time ? { "hours": stg.closing_time.split(':')[0], "minutes": stg.closing_time.split(':')[1]} : null;
    })
  } else {
    facilityStore.isUpdate = false
    let openTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].opening_time : resourcTimeStore.itemsTime[0].opening_time;
    openTimePicker.value = { "hours": openTime.split(':')[0], "minutes": openTime.split(':')[1]};
    let closeTime = facilityStore.isWeekend || facilityStore.isHoliday ? resourcTimeStore.itemsTime[1].closing_time : resourcTimeStore.itemsTime[0].closing_time;
    closeTimePicker.value = { "hours": closeTime.split(':')[0], "minutes": closeTime.split(':')[1]};

    facilityStore.settingDay = roomStore.items.map((room) => {
    let time = {
        classroom_id: room.id,
        name: room.name,
        date: facilityStore.date,
        default_hours: true,
        closed: false,
        opening_time: JSON.parse(JSON.stringify(openTimePicker.value)),
        closing_time: JSON.parse(JSON.stringify(closeTimePicker.value)),
        notes: null
      }
      return time;
    })
  }
  emit('edit');
};

// const isDragging = ref(false)
// const isDragOver = ref(false)

// const dragendHandler = () => {
//   isDragging.value = false
//   isDragOver.value = false
// }

// const dropHandler = (e) => {
//   e.preventDefault()
//   isDragging.value = false
//   isDragOver.value = false
// }
</script>

<template>
  <div 
    class="flex items-center w-full group hover:bg-green/10 h-fit print:p-px divide-x divide-slate-400 overflow-hidden"
    :class="{
      'bg-blue-100 text-blue-500': CalendarHelper.isSat(props.year, props.month, props.day),
      'bg-error/10 text-error': CalendarHelper.isSun(props.year, props.month, props.day) || isHoliday(),
    }"
  >
    <div class="flex-none w-14 h-full">
      <div class="p-1 flex w-full items-center justify-between h-full">
        {{ props.day }}
        <span>{{ CalendarHelper.getWeekday(props.year, props.month, props.day) }}</span>
      </div>
    </div>
    <div 
      class="grow bg-white text-xs h-full cursor-pointer relative group text-charcoal transition delay-75 duration-300 ease-in-out print:p-px"
    >
      <div 
        class="flex w-full flex-col p-1 min-h-14 transition delay-75 duration-300 ease-in-out relative group-hover:bg-green/10"
        :class="{
          'bg-error/10 ': CalendarHelper.isWeekend(props.year, props.month, props.day) || isHoliday(),
        }"
      >

      <div v-if="props.data.closedAllDay" class="flex-1 flex items-center gap-2">
        <span title="使用不可">
          <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3607 19.7797L12.0007 13.4097L5.6407 19.7797L4.2207 18.3597L10.5907 11.9997L4.2207 5.63973L5.6407 4.21973L12.0007 10.5897L18.3607 4.22973L19.7707 5.63973L13.4107 11.9997L19.7707 18.3597L18.3607 19.7797Z" fill="#394142"/>
          </svg>
        </span>
        <template v-for="item in props.data.daySetting">
            <span>{{ item.notes }}</span>
        </template>
      </div>
      <template v-else>
        <template v-if="props.data.daySetting.length">
          <div v-if="props.data.defaultAllDay" class="flex-1 flex items-center gap-2">
            <span title="使用可">
              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9992 1.2002C6.02682 1.2002 1.19922 6.0278 1.19922 12.0002C1.19922 17.9726 6.02682 22.8002 11.9992 22.8002C17.9716 22.8002 22.7992 17.9726 22.7992 12.0002C22.7992 6.0278 17.9716 1.2002 11.9992 1.2002ZM11.9992 20.6402C7.22562 20.6402 3.35922 16.7738 3.35922 12.0002C3.35922 7.2266 7.22562 3.3602 11.9992 3.3602C16.7728 3.3602 20.6392 7.2266 20.6392 12.0002C20.6392 16.7738 16.7728 20.6402 11.9992 20.6402Z" fill="#394142"/>
              </svg>
            </span>
            <span v-for="item in props.data.daySetting" class="truncate">{{ item.notes }}</span>
          </div>
          <template v-else>
            <div v-for="item in props.data.daySetting" class="flex items-center gap-2">
              <span 
                class="font-semibold flex items-center gap-2 truncate" 
                :title="`${item.classroom.name} ${item.closed ? '✕' : '◯'}`"
              >
                {{ item.classroom.name }}
                <span v-if="item.closed" title="使用不可">✕</span>
                <span v-if="item.default_hours" title="使用可">◯</span>
              </span> 
              <span v-if="!item.closed && !item.default_hours" 
                class="truncate" 
                :title="`${item.opening_time.slice(0,5)}～${ item.closing_time.slice(0,5)}`"
              >
                {{item.opening_time.slice(0,5)}}～{{ item.closing_time.slice(0,5)}}
              </span>
              <span class="truncate" :title="item.notes">{{ item.notes }}</span>
            </div>
          </template>
        </template>
        <div v-else class="flex-1 flex items-center gap-2">
          <span title="使用可">
            <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9992 1.2002C6.02682 1.2002 1.19922 6.0278 1.19922 12.0002C1.19922 17.9726 6.02682 22.8002 11.9992 22.8002C17.9716 22.8002 22.7992 17.9726 22.7992 12.0002C22.7992 6.0278 17.9716 1.2002 11.9992 1.2002ZM11.9992 20.6402C7.22562 20.6402 3.35922 16.7738 3.35922 12.0002C3.35922 7.2266 7.22562 3.3602 11.9992 3.3602C16.7728 3.3602 20.6392 7.2266 20.6392 12.0002C20.6392 16.7738 16.7728 20.6402 11.9992 20.6402Z" fill="#394142"/>
            </svg>
          </span>
        </div>
      </template>     
      <button 
        class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-1 rounded-full bg-green absolute right-2 top-1/2 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out print:hidden"
        @click.prevent="editItem"
      >
        <PencilIcon class="w-5 h-5 text-white" />
      </button>
      </div>
    </div>
  </div>
</template>