<script setup>
import { ref, computed } from 'vue'
import CalendarHelper from '../../../js/helper/CalendarHelper';
import CalendarRangeItem from './CalendarRangeItem.vue';
import CalendarRangeFooter from './CalendarRangeFooter.vue';
import DateTimeHelper from '../../../js/helper/DatetimeHelper'

const emit = defineEmits(['edit', 'note']);

// default is current year
const year = defineModel('year', Number);
// default range is 3 months
const range = defineModel('range', Number);
// default is current month
const monthStart = defineModel('monthStart', Number);

import { useCalendarEvent } from '../../../js/store/calendar-event';
const eventStore = useCalendarEvent();



const calendars = computed(() => {
  return CalendarHelper.generateCalendars(year.value, monthStart.value, range.value);
});

const editItem = () => {
  emit('edit');
};

const getItemsOffDay = (year, month, day) => {
  const currentDate = DateTimeHelper.format(`${year}-${month}-${day}`);
  return eventStore.items.filter((item) => DateTimeHelper.format(item.start_time) <= currentDate && currentDate <= DateTimeHelper.format(item.end_time))
};

const dragOver = (e) => {
  e.preventDefault();
  // console.log('dragOver', e);
};

const drop = (e) => {
  e.preventDefault();
  console.log('drop', e);
};
</script>
<template>
<div class="w-full border-separate border-spacing-0 transition-all duration-500 delay-75 ease-in-out">
  <div class="divide-x divide-slate-400">
    <div class="grid transition delay-75 duration-300 ease-in-out divide-x divide-slate-400 border-r border-slate-400"
      :class="{
        'grid-cols-1': calendars.length === 1,
        'grid-cols-2': calendars.length === 2,
        'grid-cols-3': calendars.length === 3,
        'grid-cols-4': calendars.length === 4,
        'grid-cols-5': calendars.length === 5,
        'grid-cols-6': calendars.length === 6,
        'grid-cols-7': calendars.length === 7,
        'grid-cols-8': calendars.length === 8,
        'grid-cols-9': calendars.length === 9,
        'grid-cols-10': calendars.length === 10,
        'grid-cols-11': calendars.length === 11,
        'grid-cols-12': calendars.length === 12,
      }"
    >
      <div 
        v-for="(cal, idx) in calendars" 
        class="border-slate-400 relative print:p-px"
        :class="{ 
          'border-l': idx === 0,
        }"
        @dragover="dragOver"
        @drop="drop"
      >
        <div class="flex flex-col">
          <div class="p-2 font-semibold text-center bg-gray-100 sticky top-0 left-0 right-0 z-10 border-b border-slate-400 h-9">
            {{ cal.month }}月
          </div>
          <div class="flex flex-col">
            <CalendarRangeItem v-for="i in cal.days"
              :year="cal.year" 
              :month="cal.month" 
              :day="i"
              :items="getItemsOffDay(cal.year,cal.month,i)"
              @edit="editItem(cal.year, cal.month, i)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CalendarRangeFooter 
    v-model:year="year"
    v-model:range="range"
    v-model:monthStart="monthStart" 
  />
</div>
</template>
