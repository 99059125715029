<script setup>

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const changeLanguage = (lang) => {
  localStorage.setItem('lang', lang)
}
</script>

<template>
  <select id="locale-select" v-model="$i18n.locale" class="px-2 py-0 border rounded shadow-sm w-fit border-gray-300 h-9"
    @change="changeLanguage($i18n.locale)">
    <option value="ja">{{ $t('japanese') }}</option>
    <option value="vi">{{ $t('vietnamese') }}</option>
    <option value="en">{{ $t('english') }}</option>
  </select>
</template>