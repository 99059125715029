<script setup>
import { ref, getCurrentInstance, onMounted, computed, watch } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { PlusIcon, CheckCircleIcon, ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/vue/24/solid';
import { DocumentDuplicateIcon, ClipboardIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../../../js/store/club';
import { useClubScheduleStore } from '../../../../js/store/club-schedule';
import { useClubRequestStore } from '../../../../js/store/club-request';
import { useResource } from '../../../../js/store/resource';
import ApplyDiscardModal from './ApplyDiscardModal.vue';
import ApplyAddItemModal from './ApplyAddItemModal.vue';
import ApplySuccessModal from './ApplySuccessModal.vue';
import DateTimeHelper from '../../../../js/helper/DatetimeHelper.js';
import InputTime from '../../../components/InputTime.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const clubRequestStore = useClubRequestStore()
const resourceStore = useResource()
const clubScheduleStore = useClubScheduleStore()

const emit = defineEmits(['refresh'])
const props = defineProps({
  // for get days
  pagination: { type: Object, required: true },
  // 利用予定情報	
  defaultSchedules: { type: Array, default: [] },
  // 申請中(再申請する)
  schedules: { type: Array, default: [] },
})

// 絞り込み
const filter = ref({
  // 曜日選択
  day: '',
  // 利用可否
  available: '',
})

// for validation and show errors
const errors = ref({})
const refApplySuccessModal = ref(null)
const refApplyAddItemModal = ref(null)
const refApplyDiscardModal = ref(null)
const items = ref({})
const itemsOriginal = ref({})
const days = ref(1)
const isOpen = ref(false)
const infors = ref({})

const open = async () => {
  $root.overlay.loading = true

  // get days in month
  days.value = DateTimeHelper.getDaysOfMonth(
    `${props.pagination.year}-${props.pagination.month.toString().padStart(2, '0')}-10`
  )
  
  items.value = Array.from({ length: days.value }, (_, i) => ({
    day: i + 1,
    dayOfWeek: DateTimeHelper.getDayOfWeekShort(
      `${props.pagination.year}-${props.pagination.month.toString().padStart(2, '0')}-${i + 1}`
    ),
    schedules: [{
      selected: false,
      room_id: 0,
      start: '',
      end: ''
    }]
  })).reduce((acc, item) => {
    acc[item.day] = item;
    return acc;
  }, {});

  // apply exists schedules
  if (props.schedules.length) {
    props.schedules.forEach(s => {
      const d = DateTimeHelper.format(s.start_at, 'D')
      items.value[d].id = s.id
      if (s.rooms.length) {
        s.rooms.forEach(r => {
          items.value[d]?.schedules.push({
            id: r.id,
            selected: true,
            room_id: r.room_id,
            start: DateTimeHelper.format(r.start, 'HH:mm'),
            end: DateTimeHelper.format(r.end, 'HH:mm'),
            status: r.status
          })
        })

        // remove first empty schedule
        items.value[d]?.schedules.shift()
      }
    })

    itemsOriginal.value = Object.assign({}, JSON.parse(JSON.stringify(items.value)))
  }

  isOpen.value = true

  clubScheduleStore.getInforsByMonth(props.pagination.year, props.pagination.month).then((response) => {
    if (response.status === 200) {
      infors.value = response.data.data.reduce((acc, item) => {
        acc[item.day] = item
        return acc
      }, {})
    }
  }).finally(() => {
    $root.overlay.loading = false
  })
}

const haveChanged = computed(() => {
  // compare items and itemsOriginal
  return JSON.stringify(items.value) !== JSON.stringify(itemsOriginal.value)
})
const close = () => {
  // ■設定中に申請ボタンを押さずに「閉じる」を押した時
  // 下記のモーダルを重ねて表示
  if (haveChanged.value) {
    refApplyDiscardModal.value.open()
    return
  }

  discard()
}

// 破棄して閉じる
const discard = () => {
  itemsOriginal.value = {}
  isOpen.value = false
}

const success = () => {
  itemsOriginal.value = {}
  isOpen.value = false

  emit('refresh')
}

// ■利用申請するボタン
// ・ボタンを押すと、設定した時間で学校側の承認画面のカレンダーに反映
const applyNow = () => {
  errors.value = {}

  // check have changed
  if (!haveChanged.value) {
    $root.push.info('変更がありません')
    return
  }

  // validation time start and end for selected
  // have not empty and null, start < end
  errors.value = Object.keys(items.value).reduce((acc, key) => {
    const item = items.value[key]
    item.schedules.forEach((s) => {
      if (!s.selected) {
        return
      }

      if (!s.start || !s.end) {
        acc[key] = true
        return
      }

      if (DateTimeHelper.isSameOrAfter(s.start, s.end)) {
        acc[key] = true
      }
    })
    return acc
  }, {})
  if (Object.keys(errors.value).length) {
    $root.push.error('利用時間を正しく入力してください')
    return
  }

  // TODO: check isTelescopicallyOverlapped time in each day

  $root.overlay.loading = true
  const response = clubRequestStore.applySchedules({
    club_id: clubStore.item.id,
    year: props.pagination.year,
    month: props.pagination.month,
    // just post schedules have data and selected
    items: Object.keys(items.value).reduce((acc, key) => {
      const item = items.value[key]
      const schedules = item.schedules.filter(s => s.selected && s.room_id)
      if (schedules.length) {
        acc[key] = schedules
      }
      return acc
    }, {})
  })
  $root.overlay.loading = false

  if (!response) {
    // failed, please try again
    $root.push.error('利用申請に失敗しました')
    return
  }

  errors.value = {}

  refApplySuccessModal.value.open()
}

onMounted(() => {
  resourceStore.getTimeItems()
})

// computed
const defaultRoomId = computed(() => {
  return clubRequestStore.rooms[0]?.id || 0
})
const basicStart = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '平日')?.opening_time
  return time ? time.slice(0, 5) : ''
})
const basicEnd = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '平日')?.closing_time
  return time ? time.slice(0, 5) : ''
})
const basicWeekendStart = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '土日祝休')?.opening_time
  return time ? time.slice(0, 5) : ''
})
const basicWeekendEnd = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '土日祝休')?.closing_time
  return time ? time.slice(0, 5) : ''
})

const editItem = (d) => {
  if (infors.value[d]?.status === '✕') {
    $root.push.error('使用不可です')
    return
  }

  // check disabled
  if (items.value[d].schedules.every(s => !s.selected)) {
    $root.push.error('利用日を選択してください')
    return
  }

  refApplyAddItemModal.value.open({
    year: props.pagination.year,
    month: props.pagination.month,
    day: d,
    item: items.value[d]
  }, infors.value[d])
}

const updateItem = (obj) => {
  items.value[obj.day].schedules = obj.schedules
}

// filter items by 曜日選択 and 利用可否
const filteredItems = computed(() => {
  return Object.keys(items.value).reduce((acc, key) => {
    const item = items.value[key]
    // 曜日選択
    if (filter.value.day && item.dayOfWeek !== filter.value.day) {
      return acc
    }
    // 利用可否: 使用不可=✕, 使用可=◯
    // is 使用不可 if have status = ✕
    // is使用可 if have status = ◯ or status = -
    if (filter.value.available) {
      if (filter.value.available === '✕' && infors.value[key]?.status !== '✕') {
        return acc
      }
      if (filter.value.available === '◯' && infors.value[key]?.status !== '◯' && infors.value[key]?.status !== '-') {
        return acc
      }
    }

    acc[key] = item
    return acc
  }, {})
})

// watch items value, if schedule is not selected, then set room id to 0, start and end to ''
watch(items, (newVal) => {
  Object.keys(newVal).forEach(key => {
    newVal[key].schedules.forEach(s => {
      if (!s.selected) {
        s.room_id = 0
        s.start = ''
        s.end = ''
      } else {
        s.room_id = s.room_id || defaultRoomId.value
        s.start = s.start || ''
        s.end = s.end || ''
      }
    })
  })
}, { deep: true })

const applyButtonText = computed(() => {
  return props.schedules.length == 0 ? '利用申請する' : '申請中<br/>(再申請する)'
})

const successModalTitle = computed(() => {
  return props.schedules.length == 0 ? '利用申請しました！' : '再申請しました！'
})

const scheduleCopy = (sche) => {
  if (!sche.selected || !sche.room_id || !sche.start || !sche.end) {
    $root.push.error('コピーする施設と時間を選択してください')
    return
  }
  
  // copy room_id, start, end to clipboard
  clubScheduleStore.copiedItem = {
    room_id: sche.room_id,
    start: sche.start,
    end: sche.end
  }

  $root.push.info('コピーしました')
}

const schedulePaste = (day, sche) => {
  if (!clubScheduleStore.copiedItem || 
    clubScheduleStore.copiedItem.room_id == 0 || 
    !clubScheduleStore.copiedItem.start || 
    !clubScheduleStore.copiedItem.end
  ) {
    $root.push.error('コピーした施設と時間がありません')
    return
  }

  if (!sche.selected) {
    sche.selected = true
  }

  if (sche.status === 'approved') {
    $root.push.error('承認済みの利用日は変更できません')
    return
  }

  // paste room_id, start, end from clipboard
  sche.room_id = clubScheduleStore.copiedItem.room_id
  sche.start = clubScheduleStore.copiedItem.start
  sche.end = clubScheduleStore.copiedItem.end

  // check with range; just allow paste time in range only
  // if not in range, then update to range
  const info = infors.value[day]?.rooms.find(r => r.id == sche.room_id)
  if (info) {
    if (info.opening_time && DateTimeHelper.isSameOrBefore(sche.start, info.opening_time)) {
      sche.start = info.opening_time
    }
    if (info.closing_time && DateTimeHelper.isSameOrAfter(sche.end, info.closing_time)) {
      sche.end = info.closing_time
    }
    if (info.closing_time && DateTimeHelper.isSameOrAfter(sche.start, info.closing_time)) {
      sche.start = info.opening_time
      sche.end = info.closing_time
    }
    if (info.opening_time && DateTimeHelper.isSameOrBefore(sche.end, info.opening_time)) {
      sche.start = info.opening_time
      sche.end = info.closing_time
    }
  }

  $root.push.info('反映しました')
}

const isAvailableRoom = (d, roomId) => {
  return infors.value[d]?.rooms.some(r => r.id == roomId && !r.closed)
}

const getRangeByRoomId = (day, roomId) => {
  const info = infors.value[day]?.rooms.find(r => r.id == roomId)
  if (!info) {
    return { 
      opening_time: '0:00', 
      closing_time: '23:59'
    }
  }

  return {
    opening_time: info.opening_time,
    closing_time: info.closing_time
  }
}

defineExpose({ open, close })
</script>
<template>
  <!-- Modal / 申請画面_01_利用申請 -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-1.5 md:p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full md:max-w-4xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <div class="flex flex-col gap-4 p-2 md:p-4">
                <div class="flex flex-col md:flex-row w-full border border-gray-200 divide-x-0 divide-y md:divide-y-0 md:divide-x divide-gray-200 md:items-center">
                  <div class="flex flex-col md:flex-row md:items-center divide-y divide-x-0 md:divide-x md:divide-y-0 divide-gray-200 ">
                    <div class="px-2 py-1 bg-gray-100 md:bg-transparent">利用情報</div>
                    <div class="px-2 py-1 flex items-center gap-4">
                      ◯：使用可
                      <div>✕：使用不可</div>
                    </div>
                  </div>
                  <div class="flex flex-col divide-y divide-gray-200 md:divide-y-0 md:flex-row md:items-center md:gap-4">
                    <div class="flex items-center py-1 px-2">平日：{{ basicStart }} ～ {{ basicEnd }}</div>
                    <div class="flex items-center py-1 px-2">
                      土日祝休：{{ basicWeekendStart }} ～ {{ basicWeekendEnd }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <div class="flex flex-col md:flex-row md:items-end md:justify-between gap-4">
                    <div class="flex flex-row md:flex-col w-full md:w-fit">
                      <div class="flex flex-col-reverse md:flex-row md:items-end gap-2 w-full md:w-fit">
                        <div class="flex items-end justify-between w-full md:w-fit">
                          <div class="flex items-end gap-2">
                            <div class="flex flex-col leading-tight">
                              絞り込み
                              <div>
                                <select class="border border-gray-200 rounded px-2 py-0.5" v-model="filter.day">
                                  <option value="">曜日選択</option>
                                  <option value="火">火</option>
                                  <option value="水">水</option>
                                  <option value="木">木</option>
                                  <option value="金">金</option>
                                  <option value="土">土</option>
                                  <option value="日">日</option>
                                </select>
                              </div>
                            </div>
                            <div>
                              <select class="border border-gray-200 rounded px-2 py-0.5" v-model="filter.available">
                                <option value="">利用可否</option>
                                <option value="✕">使用不可</option>
                                <option value="◯">使用可</option>
                              </select>
                            </div>
                          </div>
                          <div class="flex md:hidden">
                            <button class="px-4 py-1.5 text-white rounded hover:opacity-80 font-semibold"
                              :class="{
                                'bg-yellow-400': props.schedules.length == 0,
                                'bg-primary': props.schedules.length > 0
                              }"
                              @click.prevent="applyNow"
                              v-html="applyButtonText"
                            >
                            </button>
                          </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:items-end gap-2">
                          <div class="flex flex-col text-xs md:pl-4 leading-none">
                            <div>コピー&ペースト</div>
                            <div class="flex items-center text-error gap-0.5">
                              <span class="rounded-full p-1 bg-yellow-100 mt-0.5">
                                <DocumentDuplicateIcon class="w-3 h-3 text-white" />
                              </span>
                              を押すと施設と時間がコピーされ、
                            </div>
                            <div class="flex items-center text-error gap-0.5">
                              <span class="rounded-full p-1 bg-pblue mt-0.5">
                                <ClipboardIcon class="w-3 h-3 text-white" />
                              </span>
                              を押すとコピーした施設と時間が反映されます。
                            </div>
                          </div>
                          <div class="flex flex-row gap-4 flex-wrap md:flex-nowrap md:gap-0 md:flex-col text-xs leading-none">
                            <div class="flex items-center">
                              <CheckCircleIcon class="w-5 h-5 fill-blue-500" />
                              ：承認済
                            </div>
                            <div class="flex items-center">
                              <NoSymbolIcon class="w-5 h-5 fill-gray-400" />
                              ：利用日取消済
                            </div>
                            <div class="flex items-center">
                              <ExclamationTriangleIcon class="w-5 h-5 fill-error" />
                              ：申請却下
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hidden md:flex">
                      <button class="px-4 py-1.5 text-white rounded hover:opacity-80 font-semibold"
                        :class="{
                          'bg-yellow-400': props.schedules.length == 0,
                          'bg-primary': props.schedules.length > 0
                        }"
                        @click.prevent="applyNow"
                        v-html="applyButtonText"
                      >
                      </button>
                    </div>
                  </div>
                  <div class="relative w-full scroll-smooth border border-gray-200 overflow-x-auto md:overflow-x-hidden"
                    :class="{
                      'md:max-h-[600px] md:overflow-y-auto': Object.keys(filteredItems).length > 10
                    }"
                  >
                    <table class="min-w-full md:w-full border-collapse">
                      <thead class="bg-graysp sticky top-0 z-10 shadow">
                        <tr>
                          <th class="md:text-lg p-1 text-center w-fit md:w-16 border-b border-gray-200 sticky top-0 left-0 bg-graysp">
                            {{ props.pagination.month }}月
                          </th>
                          <th class="font-normal p-1 text-left border-b border-gray-200 text-xs min-w-48">
                            利用予定情報
                          </th>
                          <th class="font-normal p-1 border-b border-gray-200 text-xs">
                            <div class="hidden md:flex flex-col justify-center leading-none items-center">
                              利用日
                              <div>選択</div>
                            </div>
                          </th>
                          <th class="font-normal p-1 text-left border-b border-gray-200 text-xs">
                            施設選択
                          </th>
                          <th class="font-normal p-1 text-left border-b border-gray-200 text-xs">
                            利用時間
                          </th>
                          <th class="font-normal p-1 text-center border-b border-gray-200 text-xs">
                            コピー／反映
                          </th>
                          <th class="font-normal p-1 border-b border-gray-200 text-xs">
                            <div class="flex flex-col justify-center leading-none items-center">
                              施設
                              <div>追加</div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200">
                        <tr v-for="item in filteredItems" class="relative">
                          <td class="px-2 py-1 border-r border-r-gray-200 w-fit sticky left-0 z-10 md:relative md:z-auto"
                            :class="{
                              'text-pblue bg-pblue-200': item.dayOfWeek === '土',
                              'text-red bg-red-200': item.dayOfWeek === '日',
                              'bg-white': item.dayOfWeek !== '土' && item.dayOfWeek !== '日'
                            }"
                          >
                            <div class="flex flex-col md:flex-row items-center justify-between w-full leading-tight md:leading-normal">
                              {{ item.day }}
                              <div>{{ item.dayOfWeek }}</div>
                            </div>
                          </td>
                          <td class="p-1 border-r border-r-gray-200 min-w-max"
                            :class="{
                              'bg-red-200': item.dayOfWeek === '日' || item.dayOfWeek === '土'
                            }"
                          >
                            <div class="flex items-center gap-1 min-w-max">
                              <span v-if="infors[item.day]?.status === '◯'" title="使用可">
                                <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.0002 1.19922C6.0278 1.19922 1.2002 6.02682 1.2002 11.9992C1.2002 17.9716 6.0278 22.7992 12.0002 22.7992C17.9726 22.7992 22.8002 17.9716 22.8002 11.9992C22.8002 6.02682 17.9726 1.19922 12.0002 1.19922ZM12.0002 20.6392C7.2266 20.6392 3.3602 16.7728 3.3602 11.9992C3.3602 7.22562 7.2266 3.35922 12.0002 3.35922C16.7738 3.35922 20.6402 7.22562 20.6402 11.9992C20.6402 16.7728 16.7738 20.6392 12.0002 20.6392Z" fill="#394142"/>
                                </svg>
                              </span>
                              <span v-else-if="infors[item.day]?.status === '✕'" title="使用不可">
                                <svg class="w-6 h-6" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18.8597 19.7807L12.4997 13.4107L6.13973 19.7807L4.71973 18.3607L11.0897 12.0007L4.71973 5.6407L6.13973 4.2207L12.4997 10.5907L18.8597 4.2307L20.2697 5.6407L13.9097 12.0007L20.2697 18.3607L18.8597 19.7807Z" fill="#394142"/>
                                </svg>
                              </span>
                              <table v-else class="flex flex-col leading-none w-fit min-w-max">
                                <tbody>
                                  <tr v-for="room in infors[item.day]?.rooms">
                                    <td>{{ room.name }}</td>
                                    <td class="px-1">
                                      <div class="truncate flex items-center gap-2">
                                        <span v-if="room.closed" title="使用不可">
                                          ✕
                                        </span>
                                        <span v-else-if="room.default" title="使用可">
                                          ◯
                                        </span>
                                        <span v-else="" :title="room.time">
                                          {{ room.time }}
                                        </span>
                                        <span class="max-w-32 truncate" :title="room.note">{{ room.note }}</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div v-if="infors[item.day]?.status != '-'">
                                {{ infors[item.day]?.note }}
                              </div>
                            </div>
                          </td>
                          <td class="p-1"
                            :class="{
                              'bg-grey-100/80': infors[item.day]?.status === '✕'
                            }"
                          >
                            <div v-if="infors[item.day]?.status !== '✕'" class="flex flex-col gap-1">
                              <div v-for="schedule in item.schedules" class="flex justify-center py-0.5">
                                <input 
                                  type="checkbox" 
                                  class="w-5 h-5" 
                                  v-model="schedule.selected" 
                                  :disabled="schedule.status === 'approved'"
                                  title="利用日選択"
                                />
                              </div>
                            </div>
                          </td>
                          <td class="p-1"
                            :class="{
                              'bg-grey-100/80': infors[item.day]?.status === '✕'
                            }"
                          >
                            <div v-if="infors[item.day]?.status !== '✕'" class="flex flex-col gap-0.5">
                              <select 
                                v-for="schedule in item.schedules" 
                                class="border border-gray-200 rounded px-2 py-0.5" 
                                v-model="schedule.room_id" 
                                :disabled="!schedule.selected || schedule.status === 'approved'"
                                :class="{
                                  'bg-graysp': !schedule.selected || schedule.status === 'approved'
                                }"
                              >
                                <option value="0" v-if="!schedule.selected"></option>
                                <template v-for="room in clubRequestStore.rooms">
                                  <option 
                                    :value="room.id"
                                    v-if="isAvailableRoom(item.day, room.id)"
                                  >
                                    {{ room.name }}
                                  </option>
                                </template>
                              </select>
                            </div>
                          </td>
                          <td class="p-1"
                            :class="{
                              'bg-grey-100/80': infors[item.day]?.status === '✕'
                            }"
                          >
                            <div v-if="infors[item.day]?.status !== '✕'" class="flex flex-col gap-0.5">
                              <div v-for="schedule in item.schedules" class="flex items-center gap-1">
                                <div class="min-fit">
                                  <InputTime 
                                    v-model="schedule.start" 
                                    :disabled="!schedule.selected || schedule.status === 'approved'" 
                                    :class="{
                                      'bg-graysp': !schedule.selected || schedule.status === 'approved',
                                      'bg-error/30': schedule.selected && schedule.status !== 'approved' && (!schedule.start || DateTimeHelper.isSameOrAfter(schedule.start, schedule.end))
                                    }"
                                    :stepminute="15"
                                    :range="getRangeByRoomId(item.day, schedule.room_id)"
                                  />
                                </div>
                                ~
                                <div class="min-fit">
                                  <InputTime 
                                    v-model="schedule.end" 
                                    :disabled="!schedule.selected || schedule.status === 'approved'" 
                                    :class="{
                                      'bg-graysp': !schedule.selected || schedule.status === 'approved',
                                      'bg-error/30': schedule.selected && schedule.status !== 'approved' && (!schedule.end || DateTimeHelper.isSameOrAfter(schedule.start, schedule.end))
                                    }"
                                    :stepminute="15"
                                    :range="getRangeByRoomId(item.day, schedule.room_id)"
                                  />
                                </div>
                                <span v-if="schedule.status == 'approved'" title="承認済">
                                  <CheckCircleIcon class="w-5 h-5 fill-blue-500" />
                                </span>
                                <span v-if="schedule.status == 'rejected'" title="申請却下">
                                  <ExclamationTriangleIcon class="w-5 h-5 fill-error" />
                                </span>
                                <span v-if="schedule.status == 'cancelled'" title="利用日取消済">
                                  <NoSymbolIcon class="w-5 h-5 fill-gray-400" />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="p-px"
                            :class="{
                              'bg-grey-100/80': infors[item.day]?.status === '✕'
                            }"
                          >
                            <template v-if="infors[item.day]?.status !== '✕'">
                              <div class="flex flex-col gap-0.5">
                                <div v-for="schedule in item.schedules" class="flex justify-center gap-0.5">
                                  <button 
                                    class="p-1 bg-yellow-100 rounded-full hover:opacity-80" 
                                    :class="{
                                      'visible': schedule.selected && schedule.room_id && schedule.start && schedule.end,
                                      'invisible': !schedule.selected || !schedule.room_id || !schedule.start || !schedule.end
                                    }"
                                    title="コピー"
                                    @click.prevent="scheduleCopy(schedule)"
                                  >
                                    <DocumentDuplicateIcon class="w-5 h-5 text-white" />
                                  </button>
                                  <button 
                                    class="p-1 bg-pblue rounded-full hover:opacity-80" 
                                    :class="{
                                      'visible': schedule.status !== 'approved',
                                      'invisible': schedule.status === 'approved'
                                    }"
                                    title="反映"
                                    @click.prevent="schedulePaste(item.day, schedule)"
                                  >
                                    <ClipboardIcon class="w-5 h-5 text-white" />
                                  </button>
                                </div>
                              </div>
                            </template>
                          </td>
                          <td class="p-1"
                            :class="{
                              'bg-grey-100/80': infors[item.day]?.status === '✕'
                            }"
                          >
                            <div v-if="infors[item.day]?.status !== '✕'" class="flex justify-center">
                              <button 
                                class="p-0.5 bg-gray-500 rounded hover:opacity-80" 
                                @click.prevent="editItem(item.day)"
                                title="施設追加"
                              >
                                <PlusIcon class="w-5 h-5 text-white" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex justify-center">
                  <button 
                    class="px-4 py-2 border border-gray-200 rounded font-semibold min-w-32" 
                    @click.prevent="close"
                    title="閉じる"
                  >
                    閉じる
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
      <ApplyAddItemModal ref="refApplyAddItemModal" @update="updateItem" />
      <ApplySuccessModal ref="refApplySuccessModal" :title="successModalTitle" @ok="success" />
      <ApplyDiscardModal ref="refApplyDiscardModal" @ok="discard" />
    </Dialog>
  </TransitionRoot>
</template>
