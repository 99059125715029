<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogTitle, DialogPanel } from '@headlessui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })

</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg py-4 px-8">
              <DialogTitle class="border border-orange-400 rounded-md">
                <h3 class="flex flex-row items-center justify-center text-lg font-bold leading-6 p-1 text-orange-400">
                  <QuestionMarkCircleIcon class="h-4 w-4" />{{ $t('kyc_content.title') }}
                </h3>
              </DialogTitle>

              <div class="mt-2 flex flex-col gap-2">
                <div class="w-full text-left">
                  {{ $t('kyc_content.desc1') }}
                </div>
                <div class="text-error">
                  {{ $t('kyc_content.desc2') }}
                </div>
                <div v-html="$t('kyc_content.desc3')">
                </div>
                <div>
                  <ul class="list-disc pl-6 font-bold text-sblue">
                    <li>{{ $t('kyc_content.li1') }}</li>
                    <li>{{ $t('kyc_content.li2') }}</li>
                    <li>{{ $t('kyc_content.li3') }}</li>
                    <li>{{ $t('kyc_content.li4') }}</li>
                  </ul>
                </div>
                <div class="flex items-center justify-center">
                  <img src="./images/kyc.jpg" />
                </div>

                <div class="flex flex-row items-center justify-center gap-4">
                  <button type="button" @click="close"
                    class="w-full border shadow text-gray-800 font-bold  py-1 rounded-md hover:opacity-80">
                    {{ $t('close') }}
                  </button>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
