<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline';

const isOpen = ref(false)

const close = () => {
  isOpen.value = false

  // make tag: main > .tox.tox-tinymce sticky css property
  const main = document.querySelector('main')
  const tox = document.querySelector('.tox.tox-tinymce')
  if (main && tox) {
    tox.style.position = ''
  }
}
const open = () => {
  isOpen.value = true

  // make tag: main > .tox.tox-tinymce sticky css property
  const main = document.querySelector('main')
  const tox = document.querySelector('.tox.tox-tinymce')
  if (main && tox) {
    tox.style.position = 'sticky'
  }
}

defineExpose({ open, close })
</script>
<template>
  <!-- 
    ※承認後に承認状況を変更した場合クラブ代表のメールアドレス宛にメールで通達をする。 
    Shortcodes: [#NAME], [#CONTENT]
  -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="close()" class="z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25 jsh-template" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary tw-modal">
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between px-6 py-2 bg-gray-100"
              >
                ヘルプ

                <button @click="close" class="text-gray-500 hover:text-gray-700">
                  <XMarkIcon class="w-6 h-6" />
                </button>
              </DialogTitle>
              <div class="grid grid-cols-2 gap-4 px-6 py-4">
                <div class="rounded-lg h-full bg-yellow flex flex-col">
                  <div class="w-full bg-gray-200 font-semibold p-2">
                    テンプレート
                  </div>
                  <div class="p-2">
                    <p>こんにちは、</p>
                    <p></p><br>
                    [承認]、[拒否]、[キャンセル]のスケジュールリスト:
                    <br>
                    [#CONTENT]
                    <p></p><br>
                    <p>スケジュールを確認してください。</p>
                    <p></p><br>
                    <p>敬具、</p>
                    <p>私たちの学校チーム</p>
                  </div>
                </div>
                <div class="rounded-lg h-full bg-gray-100">
                  <div class="w-full bg-gray-200 font-semibold p-2">
                    メールの内容
                  </div>
                  <div class="p-2">
                    <p>こんにちは、</p>
                    <p></p><br>
                    <!-- list schedule approve, rejected, cancelled -->
                    [承認]、[拒否]、[キャンセル]のスケジュールリスト:
                    <br>
                    <table style="border: 1px solid #ccc; border-collapse: collapse;">
                      <tbody>
                        <tr style="border: 1px solid #ccc;">
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">クラブ</td>
                          <td style="border: 1px solid #ccc; padding: 4px;font-weight: bold;">サッカー部</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">申請施設</td>
                          <td style="border: 1px solid #ccc; padding: 4px;font-weight: bold;">体育館</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">利用日</td>
                          <td style="border: 1px solid #ccc; padding: 4px;font-weight: bold;">2024年10月24日（月）</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">利用時間</td>
                          <td style="border: 1px solid #ccc; padding: 4px;font-weight: bold;">13:00～15:00</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">承認状況</td>
                          <td style="border: 1px solid #ccc; padding: 4px;font-weight: bold;background-color: #C0E1F8;">承認済み</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">メモ</td>
                          <td style="border: 1px solid #ccc; padding: 4px;">特になし</td>
                        </tr>
                        <tr>
                          <td style="border: 1px solid #ccc; padding: 4px; background-color: #ddd;">更新者</td>
                          <td style="border: 1px solid #ccc; padding: 4px;">山田太郎</td>
                        </tr>
                      </tbody>
                    </table>
                    <p></p><br>
                    <p>スケジュールを確認してください。</p>
                    <p></p><br>
                    <p>敬具、</p>
                    <p>私たちの学校チーム</p>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>