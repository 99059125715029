<script setup>
  import { ref, computed } from 'vue'
  import CalendarHelper from '../../../js/helper/CalendarHelper';
  import CalendarRangeItem from './CalendarRangeItem.vue';
  // import CalendarRangeBlank from './CalendarRangeBlank.vue';
  import { useCalendarFactility } from '../../../js/store/calendar-factility';
  const facilityStore = useCalendarFactility();
  const emit = defineEmits(['edit', 'note']);
      // default is current year
  const year = defineModel('year', Number);
  // default range is 3 months
  const range = defineModel('range', Number);
  // default is current month
  const monthStart = defineModel('monthStart', Number);

  const calendars = computed(() => {
    return CalendarHelper.generateCalendars(year.value, monthStart.value, range.value);
  });
  const editItem = () => {
    emit('edit');
  };

  const getDataOffDay = (year, month, day) => {
    let dateString = ref(`${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`);
    let closedAllDay = false;
    let defaultAllDay = true;
    let daySetting = facilityStore.items.filter((i) => i.date == dateString.value);

    if(daySetting.length){
      closedAllDay = !daySetting.find((i) => i.closed == 0)
      defaultAllDay = !daySetting.find((i) => i.default_hours == 0)
    }
    return {daySetting : daySetting, closedAllDay: closedAllDay, defaultAllDay: defaultAllDay}
  };

  const dragOver = (e) => {
    e.preventDefault();
    // console.log('dragOver', e);
  };

  const drop = (e) => {
    e.preventDefault();
    console.log('drop', e);
  };
</script>
<template>
<div class="grid transition delay-75 duration-300 ease-in-out divide-x divide-slate-400 border-r border-slate-400"
  :class="{
    'grid-cols-1': calendars.length === 1,
    'grid-cols-2': calendars.length === 2,
    'grid-cols-3': calendars.length === 3,
    'grid-cols-4': calendars.length === 4,
    'grid-cols-5': calendars.length === 5,
    'grid-cols-6': calendars.length === 6,
    'grid-cols-7': calendars.length === 7,
    'grid-cols-8': calendars.length === 8,
    'grid-cols-9': calendars.length === 9,
    'grid-cols-10': calendars.length === 10,
    'grid-cols-11': calendars.length === 11,
    'grid-cols-12': calendars.length === 12,
  }"
>
  <div 
    v-for="(cal, idx) in calendars" 
    class="border-slate-400 relative print:p-px border-b border-b-slate-400"
    :class="{ 
      'border-l': idx === 0,
    }"
    @dragover="dragOver"
    @drop="drop"
  >
    <div class="flex flex-col">
      <div class="p-2 font-semibold text-center bg-gray-100 sticky top-0 left-0 right-0 z-10 border-b border-slate-400">
        {{ cal.month }}月
      </div>
      <div class="flex flex-col divide-y divide-slate-400 h-full`">
        <CalendarRangeItem v-for="i in cal.days"
          :year="cal.year" 
          :month="cal.month" 
          :day="i"
          :data="getDataOffDay(cal.year, cal.month, i)"
          @edit="editItem(cal.year, cal.month, i)"
        />
        <div></div>
      </div>
    </div>
  </div>
</div>
</template>