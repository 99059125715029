<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { UserIcon, UsersIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'
import Form4AboutDownload from './Form4AboutDownload.vue';

import { useRoute, useRouter } from 'vue-router'
const route = useRoute();
const router = useRouter();
const $root = getCurrentInstance()?.appContext.config.globalProperties

const refForm4AboutDownload = ref(null)

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import PopoverLanguage from './widgets/PopoverLanguage.vue';

const club_id = route.params.id
onMounted(async () => {
  if (!fronEndClubStore.club.id) {
    router.push({ path: `/front-end/club-membership/${club_id}` });
  }
})

const downloadQRCode = (qrcode) => {
  // download to memory and share to photos/gallery
  const img = new Image()
  img.src = qrcode.qr_code_image
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const padding = 10
    canvas.width = img.width + padding * 2
    canvas.height = img.height + 50 + padding * 2 // Add space for the name and padding
    const ctx = canvas.getContext('2d')

    // Add background color
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Draw the image with padding
    ctx.drawImage(img, padding, padding)

    // Add the name below the image with padding
    ctx.font = '20px Arial'
    ctx.fillStyle = 'black'
    const textWidth = ctx.measureText(qrcode.name).width
    const textX = (canvas.width - textWidth) / 2
    ctx.fillText(qrcode.name, textX, img.height + 40 + padding)

    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = qrcode.name + '.png'
      a.click()
      URL.revokeObjectURL(url)
    })
  }
}
const bulkDownload = () => {
  if(fronEndClubStore.info.member.qr_code_image){
    downloadQRCode(fronEndClubStore.info.member)
  }
  if(fronEndClubStore.info.related.length > 0){
    fronEndClubStore.info.related.forEach(rela => {
      if(rela.join && rela.qr_code_image) {
        downloadQRCode(rela)
      }      
    })
  }
}
</script>
<template>
  <div v-if="fronEndClubStore.info.member"
    class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div
      class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-md md:shadow-lg">
      <div class="flex flex-row w-full bg-secondary pt-4 px-3 pb-0 justify-between items-center relative">
        <div class="flex w-12 h-12 items-center">
        </div>
        <h5 class="text-xl text-white text-center font-bold">{{ $t('issue_qr_title') }}</h5>
        <div class="flex items-center">
          <div class="group transition duration-300 delay-75 ease-in-out">
            <PopoverLanguage/>
          </div>
        </div>
      </div>
      <hr />

      <div class="flex flex-col text-base p-4">
        <div class="flex flex-col gap-4">

          <div class="flex flex-col items-start border border-gray-200 divide-y">
            <div class="flex w-full p-2 bg-gray-100 text-sm">
              {{ $t('club_name') }}
            </div>
            <div class="flex w-full p-2 font-bold text-sblue">
              {{ fronEndClubStore.club.name }}
            </div>
          </div>

          <div class="flex flex-col gap-2 whitespace-pre text-wrap">
            <!-- <div class="bg-graysp text-center text-sm">{{ $t('qr_code_view.desc1') }}</div> -->
            <div class="flex flex-row gap-2 text-error font-bold whitespace-pre text-wrap group">
              <div>
                <ExclamationTriangleIcon
                  class="w-9 h-9 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
              </div>
              <div>{{ $t('qr_code_view.desc2') }}</div>
            </div>
            <div class="flex items-center text-blue-500 justify-center cursor-pointer" @click.prevent="refForm4AboutDownload.open">
              <QuestionMarkCircleIcon class="w-5 h-5 text-blue-500" />
              ※
              <span class="underline">{{ $t('qr_code_view.desc2_help') }}</span>
            </div>
            <div class="text-error text-sm text-center">{{ $t('qr_code_view.desc3') }}</div>
          </div>

          <div class="flex flex-row gap-2 items-center border rounded-md p-2" v-if="fronEndClubStore.info.member.qr_code_image">
            <div>
              <UserIcon class="w-7 h-7 text-primary" />
            </div>
            <div class="flex flex-col gap-2 w-full">
              <div>{{ fronEndClubStore.info.member.name }}</div>
              <div class="flex flex-row gap-2 justify-end items-start">
                <div class="h-24 w-24">
                  <img :src="fronEndClubStore.info.member.qr_code_image" />
                </div>
                <div>
                  <button type="button" @click="downloadQRCode(fronEndClubStore.info.member)" class="bg-primary  text-white font-bold py-1 px-2 rounded hover:opacity-80 h-9">
                    {{ $t('download') }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <template v-for="rela in fronEndClubStore.info.related">
            <div class="flex flex-row gap-2 items-center border rounded-md p-2" v-if="rela.join && rela.qr_code_image">
              <div>
                <UsersIcon class="w-7 h-7 text-primary" />
              </div>
              <div class="flex flex-col gap-2 w-full">
                <div>{{ rela.name }}</div>
                <div class="flex flex-row gap-2 justify-end items-start">
                  <div class="h-24 w-24">
                    <img :src="rela.qr_code_image" />
                  </div>
                  <div>
                    <button type="button" @click="downloadQRCode(rela)" class="bg-primary  text-white font-bold py-1 px-2 rounded hover:opacity-80 h-9">
                      {{ $t('download') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-row items-center justify-center gap-4">
            <button type="submit" @click="bulkDownload()"
              class="w-1/2 shadow text-white font-bold bg-primary py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('bulk_download') }}
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
  <Form4AboutDownload ref="refForm4AboutDownload" />
</template>
