<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import CalendarHelper from '../../../js/helper/CalendarHelper'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import DateTimeHelper from '../../../js/helper/DatetimeHelper'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useCalendarEvent } from '../../../js/store/calendar-event';
const eventStore = useCalendarEvent();

import { useHoliday } from '../../../js/store/holiday';
const holidayStore = useHoliday();

const emit = defineEmits(['edit']);

// default is current year
const year = defineModel('year', Number);
// default range is 3 months
const range = defineModel('range', Number);
// default is current month
const monthStart = defineModel('monthStart', Number);

const addItem = (day) => {
  eventStore.newItem = {color: '#000000'}
  eventStore.startDate =  `${year.value}-${monthStart.value}-${day}`;
  eventStore.endDate =  `${year.value}-${monthStart.value}-${day}`;
  emit('edit');
};
const editItem = (item) => {

  item.holiday = item.holiday ? true : false;
  item.important = item.important ? true : false;
  eventStore.newItem = JSON.parse(JSON.stringify(item));
  eventStore.startDate =  DateTimeHelper.format(item.start_time);
  eventStore.starTime = DateTimeHelper.formatTimeObject(item.start_time);
  eventStore.endDate =  DateTimeHelper.format(item.end_time);
  eventStore.endTime = DateTimeHelper.formatTimeObject(item.end_time);
  emit('edit');
};

const deleteItem = async(item) => {
  const confirm = window.confirm('削除しますか？');
  if (!confirm) return;
  $root.overlay.loading = true
  try {
    const response = await eventStore.delete(item.id);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getItems();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
};

const calendars = computed(() => {
  const cals = CalendarHelper.generateCalendars(year.value, monthStart.value, range.value); // array

  let objs = [], years = {}
  cals.forEach(c => {
    for (let i = 1; i <= parseInt(c.days); i++) {
      let currentDate = `${c.year}-${("0" + c.month).slice(-2)}-${("0" + i).slice(-2)}`;
      let data = eventStore.items.filter((i)=> DateTimeHelper.format(i.start_time) <= currentDate && currentDate <= DateTimeHelper.format(i.end_time))
      let item = {
        year: c.year,
        month: c.month,
        day: i,
        textYear: years[c.year] ? '' : c.year,
        textMonth: years[c.year] && years[c.year][c.month] ? '' : `${c.month}月`,
        data: data,
        isHoliday: holidayStore.items.findIndex((i) => i.date == currentDate && i.status == 1) >= 0
      }
      if (!years[c.year]) years[c.year] = {}
      if (!years[c.year][c.month]) years[c.year][c.month] = {} 

      years[c.year][c.month][i] = item
      objs.push(item)
    }
  })
  return objs
});

const dragOver = (e) => {
  e.preventDefault();
  // console.log('dragOver', e);
};

const drop = (e) => {
  e.preventDefault();
  console.log('drop', e);
};
</script>
<template>
<table class="table w-full border-separate border-spacing-0 transition-all duration-500 delay-75 ease-in-out relative">
  <thead class="bg-gray-100 sticky top-0 left-0 right-0 h-8 z-10">
    <tr class="divide-x divide-slate-400">
      <th colspan="3" class="p-1 text-center border-b border-slate-400">
        日付
      </th>
      <th class="p-1 text-left border-b border-slate-400">
        行事
      </th>
    </tr>
  </thead>
  <tbody class="divide-x divide-slate-400">
    <tr v-for="(c, idx) in calendars" class="transition delay-75 duration-300 ease-in-out divide-x divide-slate-400">
      <td 
        class="p-1 font-semibold text-lg w-24 bg-white"
        :class="{
          'sticky left-0 top-8': c.textYear,
        }"
      >
        {{ c.textYear }}
      </td>
      <td 
        class="p-1 font-semibold text-lg w-24 border-slate-400 bg-white"
        :class="{
          'border-t': idx > 0 && c.textMonth,
          'sticky left-0 top-8': c.textMonth,
        }"
      >
        {{ c.textMonth }}
      </td>
      <td class="p-1 w-16 border-slate-400"
        :class="{
          'border-t': idx > 0,
          'bg-blue-100 text-blue-500': CalendarHelper.isSat(c.year, c.month, c.day),
          'bg-error/10': CalendarHelper.isSun(c.year, c.month, c.day) || c.isHoliday
        }"
      >
        <div class="flex items-center gap-2 justify-between font-semibold">
          <div class="text-lg">{{ c.day }}</div>
          <div>{{ CalendarHelper.getWeekday(c.year, c.month, c.day) }}</div>
        </div>
      </td>
      <td 
        class="p-1 border-slate-400 relative group"
        :class="{
          'border-t': idx > 0,
          'bg-blue-100': CalendarHelper.isSat(c.year, c.month, c.day),
          'bg-error/10': CalendarHelper.isSun(c.year, c.month, c.day) || c.isHoliday,

        }"
        @dragover="dragOver"
        @drop="drop"
      >
        <template v-for="item in c.data">
          <div class="flex w-fit rounded border border-slate-300 px-1 py-0.5 bg-white group/item"
            :style="{backgroundColor: item.holiday? '#FEE9E7' : item.color, color: item.holiday ? '#ff3000' : '#ffffff'}"
            draggable="true"
            @dragstart="dragStart"
            @click.prevent="editItem(item)"
          >
            {{ item.name }}
            <!-- remove icon -->
            <button class="p-[2px] bg-white hidden group-hover/item:block rounded transition-all delay-75 duration-300 ease-in-out print:hidden right-5 top-1" @click.stop="deleteItem(item)">
                  <XMarkIcon class="w-3 h-3 font-bold" :style="{color: item.holiday ? '#FEE9E7' : item.color}"/>
                </button>
          </div>
        </template>
        <button
          @click.prevent="addItem(c.day)"
          class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-px rounded-sm bg-slate-700 absolute right-1 top-1/2 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out"
        >
          <PlusIcon class="w-4 h-4 text-white" />
        </button>
      </td>
    </tr>
  </tbody>
</table>
</template>
