<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useDatabaseStore } from '../../js/store/database'
import { useRoute} from 'vue-router'
import VueDatePicker from '@vuepic/vue-datepicker';
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';

const $route = useRoute()
const $root = getCurrentInstance()?.appContext.config.globalProperties
const isOpen = ref(false)
const std = ref({})
const flagEdit = ref(0)
const databaseStore = useDatabaseStore()

const appUrl = window.location.origin

const open = async (item) => {
  isOpen.value = true
  std.value = JSON.parse(JSON.stringify(item));
  if(item.id) {
    flagEdit.value = 1;
  } else {
    flagEdit.value = 0;
    std.value.status = 1;
  }
}
const close = () => {
  isOpen.value = false
}


const update = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.post(`/api/students/`, std.value);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      databaseStore.getItem($route.params.id);
      close();
    } else {
      $root.push.error(response.data.message)
    }
  } catch (error) {
    console.error('Error adding user:', error);
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform bg-white text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-secondary"
            >
            <div class="flex flex-col gap-2">
              <div class="flex flex-col">
                <label for="class" class="text-sm text-gray-500">生徒ID</label>
                <input v-model="std.student_code" type="text"  class="w-full border border-gray-300 rounded p-2" />
              </div>
              <div class="flex w-full gap-2">
                <div class="flex-auto">
                  <label for="name" class="text-sm text-gray-500">氏名（漢字）</label>
                  <input v-model="std.kanji_name" type="text" class="w-full border border-gray-300 rounded p-2"/>
                </div>
                <div class="flex-auto">
                  <label for="name" class="text-sm text-gray-500">氏名（ローマ字）</label>
                  <input v-model="std.romaji_name" type="text" class="w-full border border-gray-300 rounded p-2"/>
                </div>
              </div>
              <div class="flex items-center gap-3">
                <div class="grow">
                    <div class="flex flex-col gap-3">
                      <div class="flex items-center gap-2">
                        <div class="flex-auto ">
                          <label for="class" class="text-sm text-gray-500">学校</label>
                          <div>
                            <select v-model="std.school_level" class="w-full border border-gray-300 rounded p-2">
                              <option value="中学部">中学部</option>
                              <option value="小学部">小学部</option>
                            </select>
                          </div>
                        </div>
                        <div class="flex-auto">
                          <label for="class" class="text-sm text-gray-500">学年</label>
                          <div>
                            <select v-model="std.grade" class="w-full border border-gray-300 rounded p-2">
                              <option v-if="std.school_level == '中学部'" v-for="grd in 3" :value="`中${grd}`">中{{grd}}</option>
                              <option v-if="std.school_level == '小学部'" v-for="grd in 6" :value="`小${grd}`">小{{grd}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="flex-auto ">
                          <label for="class" class="text-sm text-gray-500">クラス</label>
                          <div>
                            <select v-model="std.class_name" class="w-full border border-gray-300 rounded p-2">
                              <option v-for="cls in 5" :value="`${cls}組`">{{cls}}組</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col gap-2">
                        <div class="flex items-center gap-2">
                          <div class="flex flex-col w-1/2">
                            <label for="class" class="text-sm text-gray-500">生年月日 (YYYY-MM-DD)</label>
                            <VueDatePicker 
                              v-model="std.date_of_birth" 
                              auto-apply 
                              format="yyyy-MM-dd"
                              :enable-time-picker="false"
                              locale="ja"
                              class="mydatepicker w-fit"
                              :clearable="false"
                              input-class-name="bg-white border rounded w-36 pl-8 pr-2 py-1 cursor-pointer"
                            >
                              <template #dp-input="{ value }">
                                <input type="text" :value="value" class="w-full border border-gray-300 rounded p-2"/>
                              </template>
                            </VueDatePicker>
                          </div>

                          <div class="flex flex-col">
                            <label for="class" class="text-sm text-gray-500">性別</label>
                            <select v-model="std.gender" class="w-full border border-gray-300 rounded p-2">
                              <option value="男性">男性</option>
                              <option value="女性">女性</option>
                            </select>
                          </div>
                          <div class="flex flex-col">
                            <label for="class" class="text-sm text-gray-500">状態</label>
                            <select v-model="std.status" class="w-full border border-gray-300 rounded p-2">
                              <option value="0">退校</option>
                              <option value="1">在学</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="flex-none w-28">
                  <div class="flex flex-col gap-2">
                    <img  
                      class="object-contain group-hover:scale-110 transition-transform duration-300 delay-75 ease-in-out"
                      :src="std.qr_code_url ? std.qr_code_url : appUrl + '/default.png'"
                      loading="lazy"
                    />
                    <!-- <button class="px-2 py-1 border rounded">Re-generate</button> -->
                  </div>
                </div>
              </div>

              <div class="flex flex-col">
                <label for="class" class="text-sm text-gray-500">現住所</label>
                <input v-model="std.address" type="text"  class="w-full border border-gray-300 rounded p-2" />
              </div>
              <div class="flex flex-col">
                <label for="class" class="text-sm text-gray-500">メモ</label>
                <input v-model="std.note" type="text"  class="w-full border border-gray-300 rounded p-2" />
              </div>

              <!-- cancel + save button -->
              <div class="flex justify-center gap-2 mt-6">
                <button class="px-4 py-2 border rounded" type="button" @click.prevent="close">キャンセル</button>
                <button class="px-4 py-2 text-white bg-primary rounded" @click.prevent="update()">{{flagEdit? '更新' : '登録する'}}</button>
              </div>
            </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
