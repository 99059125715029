<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useClubScheduleStore } from '../../../js/store/club-schedule'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import DateTimeHelper from '../../../js/helper/DatetimeHelper'
import ApprovalModal from './ApprovalModal.vue'
import CalendarFilterPopover from './CalendarFilterPopover.vue'
import PopoverMemo from './PopoverMemo.vue'

const emit = defineEmits(['add', 'edit', 'filter'])
const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubScheduleStore = useClubScheduleStore()

const refMemo = ref(null)
const calendar = ref(null)
const refApprovalModal = ref(null)
const allDayEvents = ref([])

onMounted(() => {
  // get rooms and clubs
  clubScheduleStore.getRooms()
  clubScheduleStore.getClubs()
})

// events for calendar - computed
const calendarEvents = computed(() => {
  const items = clubScheduleStore.items.flatMap((item) => {
    return item.rooms.map((room) => {
      return {
        title: item.club_name,
        start: room.start,
        end: room.end,
        allDay: item.is_all_day ?? false,
        extendedProps: {
          id: item.id,
          roomId: room.id,
          roomName: room.room_name,
          status: room.status,
          hoverText: `${item.club_name} ${room.room_name}`,
          admin_memo: room.admin_memo,
          shared_memo: room.shared_memo,
        },
      }
    })
  })

  // add all day events
  if (allDayEvents.value.length > 0) {
    allDayEvents.value.forEach((item) => {
      items.push({
        title: (item.status === '◯' || item.status === '✕') ? item.status : '',
        allDay: true,
        start: `${item.date} 00:00:00`,
        end: `${item.date} 23:59:59`,
        extendedProps: {
          status: item.status,
          note: item.note,
          rooms: item.rooms,
          hoverText: item.status === '◯' ? '使用可' : item.status === '✕' ? '使用不可' : item.rooms.map((room) => room.name).join(', '),
        }
      })
    })

    $root.hideHelp = true
  }

  return items
})

const calendarOptions = computed(() => {
  return {
    customButtons: {
      filterEvent: {
        text: 'フィルター' + (countFiltered.value > 0 ? ` (${countFiltered.value})` : ''),
        className: 'btn filter-event',
        click: () => {
          clubScheduleStore.setFilter(!clubScheduleStore.showFilter)
        }
      },
    },
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    views: {
      dayGridMonth: {
        displayEventEnd: true,
      },
    },
    headerToolbar: {
      left: 'filterEvent prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    buttonText: {
      today: '今日',
      year: '年',
      month: '月',
      week: '週',
      day: '日',
      list: '予定リスト',
    },
    weekText: '週',
    allDayText: '施設予定',
    moreLinkText(n) {
      return '他 ' + n + ' 件';
    },
    firstDay: 0,
    noEventsText: '表示する予定はありません',
    locale: 'ja',
    events: calendarEvents.value,
    eventMouseEnter: (info) => {
      // set pointer
      info.el.style.cursor = 'pointer'
      // show room name when hover event
      info.el.setAttribute('title', info.event.extendedProps.hoverText)

      // show memo when hover event
      if (info.event.extendedProps.shared_memo || info.event.extendedProps.admin_memo) {
        refMemo.value.show(
          info.event.extendedProps.admin_memo, 
          info.event.extendedProps.shared_memo, 
          info.el,
          // mode: 月, 週, 日, 予定リスト
          calendar.value?.getApi().view.type
        )
      }
    },
    eventMouseLeave: (info) => {
      refMemo.value.hide()
    },
    dayMaxEventRows: 5,
    // eventLimit: true,
    eventClick: async (info) => {
      // if all day event, do nothing
      if (info.event.allDay) {
        return
      }

      if (info.event.extendedProps.status === 'cancelled') {
        $root.push.info('キャンセルされた予定です。')
        return
      }

      refApprovalModal.value.open({
        date: DateTimeHelper.format(info.event.start, 'YYYY-MM-DD'),
        start: DateTimeHelper.format(info.event.start, 'HH:mm'),
        end: DateTimeHelper.format(info.event.end, 'HH:mm'),
        status: info.event.extendedProps.status,
        roomName: info.event.extendedProps.roomName,
        id: info.event.extendedProps.id,
        roomId: info.event.extendedProps.roomId,
        admin_memo: info.event.extendedProps.admin_memo,
        shared_memo: info.event.extendedProps.shared_memo,
      })
    },
    datesSet: async (info) => {
      clubScheduleStore.setPagination({
        start: DateTimeHelper.format(info.start, 'YYYY-MM-DD'),
        end: DateTimeHelper.format(info.end, 'YYYY-MM-DD'),
        rooms: clubScheduleStore.pagination.rooms,
        clubs: clubScheduleStore.pagination.clubs,
      })

      getItems()
    },
  }
})

const getItems = () => {
  $root.overlay.loading = true
  clubScheduleStore.getItems().then(() => {
    $root.overlay.loading = false

    // get informations for show items on all day calendar
    // just get when calendar mode is month
    const start = DateTimeHelper.format(calendar.value.getApi().view.activeStart, 'YYYY-MM-DD')
    const end = DateTimeHelper.format(calendar.value.getApi().view.activeEnd, 'YYYY-MM-DD')
    clubScheduleStore.getCalendarAlldays(start, end).then((response) => {
      allDayEvents.value = response.data.data
    })

    // const havePending = clubScheduleStore.items.some((item) => item.rooms.some((room) => room.status === 'pending'))
    // if (!havePending) {
    //   document.querySelector('.jsh-help').classList.add('hidden')
    // } else {
    //   document.querySelector('.jsh-help').classList.remove('hidden')
    // }
  })
}

const countFiltered = computed(() => {
  return clubScheduleStore.countFiltered
})

const getDayEvent = (d) => {
  const date = DateTimeHelper.formatToVi(d, 'YYYY-MM-DD')
  return allDayEvents.value?.find((item) => item.date === date)
}
</script>
<template>
  <div class="flex w-full h-full p-4 flex-grow transition-all duration-300 delay-75 ease-in-out relative">
    <CalendarFilterPopover 
      :filter="{
        rooms: clubScheduleStore.pagination.rooms,
        clubs: clubScheduleStore.pagination.clubs,
      }" 
      @filter="getItems" 
    />
    <div class="grow transition-all duration-300 delay-75 ease-in-out jsh-new-calendar">
      <FullCalendar 
        ref="calendar"
        class="w-full h-full transition-all duration-300 delay-75 ease-in-out jsh-approval-calendar" 
        :options="calendarOptions" 
      >
        <!-- dayCellContent -->
        <template #dayCellContent="arg">
          <div class="flex items-start justify-between w-full gap-2">
            <div v-if="calendar?.getApi().view.type === 'dayGridMonth'" class="grow overflow-hidden">
              <div 
                v-if="getDayEvent(arg.date)?.status == '◯' || getDayEvent(arg.date)?.status == '✕'" 
                class="flex items-center gap-1"
                :title="`${ getDayEvent(arg.date)?.status } ${ getDayEvent(arg.date)?.note ?? '' }`"
              >
                  <span class="font-bold">
                    {{ getDayEvent(arg.date)?.status }}
                  </span>
                  {{ getDayEvent(arg.date)?.note ?? '' }}
                </div>
                <div v-else class="flex flex-col leading-tight text-xxs max-w-fit pt-1">
                  <div 
                    v-for="room in getDayEvent(arg.date)?.rooms"  
                    class="flex items-center gap-1 truncate" 
                    :title="`${room.name} ${ room.closed ? '✕' : (room.default ? '◯' : room.time) } ${ room.note ?? '' }`"
                  >
                    {{ room.name }}
                    <span>
                      {{ room.closed ? '✕' : (room.default ? '◯' : room.time) }}
                    </span>
                    {{ room.note ?? '' }}
                  </div>
                </div>
            </div>
            <div class="flex-none w-fit">
              {{ arg.dayNumberText }}
            </div>
          </div>
        </template>
        <!-- event content -->
        <template #eventContent="arg">
          <div class="flex flex-col items-start p-0.5 rounded w-full h-full overflow-hidden leading-tight hover:opacity-80 transition-all duration-300 delay-75 ease-in-out"
            :class="{
              'text-white bg-blue-500 hover:shadow-lg': arg.event.extendedProps.status === 'approved',
              'text-white bg-yellow-700 hover:shadow-lg': arg.event.extendedProps.status === 'pending',
              'text-white bg-error hover:shadow-lg': arg.event.extendedProps.status === 'rejected',
              'text-grey bg-grey-200 cursor-not-allowed hover:shadow-lg': arg.event.extendedProps.status === 'cancelled',
              'bg-transparent text-gray-600 is-all-day': arg.event.allDay,
            }"
          >
            <template v-if="calendar?.getApi().view.type === 'dayGridMonth'">
              <div v-if="arg.event.extendedProps.status != '◯' && arg.event.extendedProps.status != '✕' && !arg.event.allDay" class="flex flex-col leading-tight">
                <span>
                  {{ DateTimeHelper.format(arg.event.start, 'HH:mm') }}
                  - 
                  {{ DateTimeHelper.format(arg.event.end, 'HH:mm') }}
                </span>
                <span>{{ arg.event.title }}</span>
                <span>{{ arg.event.extendedProps.roomName }}</span>
              </div>
            </template>
            <template v-else>
              <div v-if="arg.event.allDay" class="text-gray-600 truncate">
                <div v-if="arg.event.extendedProps.status == '◯' || arg.event.extendedProps.status == '✕'" class="flex items-center gap-2">
                  <span class="text-xl font-bold">
                    {{ arg.event.title }}
                  </span>
                  {{ arg.event.extendedProps.note }}
                </div>
                <div v-else class="flex flex-col leading-tight text-xxs">
                  <div class="flex items-center gap-2" v-for="room in arg.event.extendedProps.rooms">
                    {{ room.name }}
                    <span>
                      {{ room.closed ? '✕' : (room.default ? '◯' : room.time) }}
                    </span>
                    {{ room.note }}
                  </div>
                </div>
              </div>
              <div v-else class="flex flex-col leading-tight">
                <span>
                  {{ DateTimeHelper.format(arg.event.start, 'HH:mm') }}
                  - 
                  {{ DateTimeHelper.format(arg.event.end, 'HH:mm') }}
                </span>
                <span>{{ arg.event.title }}</span>
                <span>{{ arg.event.extendedProps.roomName }}</span>
              </div>
            </template>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
  <ApprovalModal ref="refApprovalModal" @refresh="getItems" />
  <PopoverMemo ref="refMemo" />
</template>
