import { defineStore } from 'pinia'

export const useAccountStore = defineStore('account', {
  state: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: '表示名',
        value: 'name',
      },
      {
        text: 'メールアドレス',
        value: 'email',
      },
      {
        text: 'ログインID',
        value: 'login_id',
      },
      {
        text: 'パスワード',
        value: 'password',
      },
      {
        text: '権限',
        value: 'role',
      },
      {
        text: 'ログイン状態',
        value: 'status',
      },
    ],
    items: [],
    item: {role: [], active: 1},
    roles: {
      school: "学校",
      PTA: "PTA",
      club: "クラブ",
      protector: "警備員",
      bus_monitor: "Bus Monitor",
    }
  }),
  actions: {
    async getItems() {
      setTimeout(async() => {  
        const response = await axios.get('/api/users');
        if (response.status !== 200) {
          this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
          return
        }
        this.items = response.data;
      })
    },
    setItem(item) {
      if (!item) {
        return
      }
      this.item = item
    },
  },
});
