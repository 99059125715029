import { defineStore } from 'pinia'

export const useClubScheduleStore = defineStore('club-schedule', {
  state: () => ({
    rooms: [],
    clubs: [],
    mode: 'month',
    showFilter: false,
    items: [],
    pagination: {
      start: '',
      end: '',
      rooms: [],
      clubs: [],
    },
    copiedItem: {},
  }),
  actions: {
    setMode(value) {
      this.mode = value
    },
    setFilter(value) {
      this.showFilter = value;
    },
    setPagination(value) {
      this.pagination = value;
    },
    async getItems() {
      const response = await axios.post('/api/club-schedules', {
        start: this.pagination.start,
        end: this.pagination.end,
        rooms: this.pagination.rooms,
        clubs: this.pagination.clubs,
      });

      if (response.status === 200) {
        this.items = response.data.data
      }
    },
    update(item) {
      return axios.put(`/api/club-schedule/${item.roomId}`, item)
    },
    getRooms() {
      axios.get('/api/club-schedule/rooms').then(response => {
        if (response.status === 200) {
          this.rooms = response.data.data
        }
      })
    },
    getClubs() {
      axios.get('/api/club-schedule/clubs').then(response => {
        if (response.status === 200) {
          this.clubs = response.data.data
        }
      })
    },
    async getRoomsInfor(d) {
      return await axios.get('/api/club-schedule/rooms-info', {
        params: {
          date: d
        }
      });
    },
    getInforsByMonth(year, month) {
      return axios.get(`/api/club-schedule/infors/${year}/${month}`)
    },
    getAlldays() {
      const year = this.pagination.start.split('-')[0]
      const month = parseInt(this.pagination.start.split('-')[1])
      return this.getInforsByMonth(year, month)
    },
    getCalendarAlldays(start, end) {
      return axios.get(`/api/club-schedule/calendar/infors/${start}/${end}`)
    }
  },
  getters: {
    // count filtered rooms and clubs
    countFiltered() {
      return this.pagination.rooms.length + this.pagination.clubs.length
    }
  }
})