<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import CalendarHelper from '../../../js/helper/CalendarHelper'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import DateTimeHelper from '../../../js/helper/DatetimeHelper'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useCalendarEvent } from '../../../js/store/calendar-event';
const eventStore = useCalendarEvent();

import { useHoliday } from '../../../js/store/holiday';
const holidayStore = useHoliday();

const emit = defineEmits(['edit']);

// default is current year
const year = defineModel('year', Number);

// default is current month
const monthStart = defineModel('monthStart', Number);

const calendars = computed(() => {
  let calendars = CalendarHelper.generateMonthCalendar(year.value, monthStart.value); // array
  calendars.map((c) => {
    c.map((d) => {
      let currentDate = `${d.year}-${("0" + d.month).slice(-2)}-${("0" + d.day).slice(-2)}`;
      d.isHoliday = holidayStore.items.findIndex((i) => i.date == currentDate && i.status == 1) >= 0
      d.items = eventStore.items.filter((i)=> DateTimeHelper.format(i.start_time) <= currentDate && currentDate <= DateTimeHelper.format(i.end_time))
    })
    
  })
  return calendars
});
const addItem = (day) => {
  eventStore.newItem = {color: '#000000'}
  eventStore.startDate =  `${year.value}-${monthStart.value}-${day}`;
  eventStore.endDate =  `${year.value}-${monthStart.value}-${day}`;
  emit('edit');
};
const editItem = (item) => {

  item.holiday = item.holiday ? true : false;
  item.important = item.important ? true : false;
  eventStore.newItem = JSON.parse(JSON.stringify(item));
  eventStore.startDate =  DateTimeHelper.format(item.start_time);
  eventStore.starTime = DateTimeHelper.formatTimeObject(item.start_time);
  eventStore.endDate =  DateTimeHelper.format(item.end_time);
  eventStore.endTime = DateTimeHelper.formatTimeObject(item.end_time);
  emit('edit');
};
const deleteItem = async(item) => {
  const confirm = window.confirm('削除しますか？');
  if (!confirm) return;
  $root.overlay.loading = true
  try {
    const response = await eventStore.delete(item.id);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getItems();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
};

const dragOver = (e) => {
  e.preventDefault();
  // console.log('dragOver', e);
};

const drop = (e) => {
  e.preventDefault();
  console.log('drop', e);
};

const isDragging = ref(false)
const isDragOver = ref(false)

const dragendHandler = () => {
  isDragging.value = false
  isDragOver.value = false
}

const dropHandler = (e) => {
  e.preventDefault()
  isDragging.value = false
  isDragOver.value = false
}
</script>
<template>
  <table class="table w-full border-separate border-spacing-0 transition-all duration-500 delay-75 ease-in-out relative">
    <thead class="bg-gray-100 sticky top-0 left-0 right-0 h-8">
      <tr class="divide-x divide-slate-400">
        <th class="p-1 text-error bg-error/10">日</th>
        <th class="p-1">月</th>
        <th class="p-1">火</th>
        <th class="p-1">水</th>
        <th class="p-1">木</th>
        <th class="p-1">金</th>
        <th class="p-1 text-blue-500 bg-blue-100">土</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-slate-400">
      <tr v-for="w in calendars" class="divide-x divide-slate-400">
        <td v-for="d in w" 
          class="p-1 border-t border-slate-400 relative group"
          :class="{
            'bg-blue-100': d.isSat,
            'bg-error/10': d.isSun || d.isHoliday,
          }"
          @dragover="dragOver"
          @drop="drop"
        >
          
          <div class="flex flex-col gap-0.5 items-start min-h-32">
            <div class="font-semibold text-lg">{{ d.day }}</div>
            <template v-for="item in d.items">
              <div class="flex w-fit rounded border border-slate-300 px-1 py-0.5 cursor-pointer transition delay-75 duration-300 ease-in-out group/item relative"
                :style="{backgroundColor: item.holiday? '#FEE9E7' : item.color, color: item.holiday ? '#ff3000' : '#ffffff'}"
                :class="{
                  'border-dashed bg-white/60': isDragging,
                  'bg-white': !isDragging,
                }"
                draggable="true"
                @dragstart="isDragging = true"
                @dragend="dragendHandler"
                @click.prevent="editItem(item)"
              >
                {{ item.name }}
                <!-- remove icon -->
                <button class="p-[2px] bg-white hidden group-hover/item:block rounded transition-all delay-75 duration-300 ease-in-out print:hidden absolute right-px top-1" @click.stop="deleteItem(item)">
                  <XMarkIcon class="w-3 h-3 font-bold" :style="{color: item.holiday ? '#FEE9E7' : item.color}"/>
                </button>
              </div>
            </template>
          </div>


          <button
            @click.prevent="addItem(d.day)"
            class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-px rounded-sm bg-slate-700 absolute right-1 -bottom-1 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out"
          >
            <PlusIcon class="w-4 h-4 text-white" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
