<script setup>
import { computed, watchEffect } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ClockIcon } from '@heroicons/vue/24/outline'
import DateTimeHelper from '../../js/helper/DatetimeHelper'

const props = defineProps({
  format: { type: String, default: 'H:mm' },
  class: { type: String, default: 'w-fit' },
  stepminute: { type: Number, default: 1 },
  disabled: { type: Boolean, default: false },
  // for limit hour and minute range: { start, end }
  range: { type: Object, default: () => ({ opening_time: '0:00', closing_time: '23:59' }) }
})

const time = defineModel({
  prop: 'time',
  event: 'change'
})

// change event when hour or minute undefined
watchEffect(() => {
  const values = time.value.split(':')

  let h = parseInt(values[0]) || 0
  if (values[0] === undefined || values[0] === 'undefined') {
    h = 0
  }

  let m = parseInt(values[1]) || 0
  if (values[1] === undefined || values[1] === 'undefined') {
    m = 0
  }

  time.value = `${h}:${m.toString().padStart(2, '0')}`
})

const hour = computed({
  get: () => parseInt(time.value.split(':')[0]),
  set: (value) => time.value = `${value}:${time.value.split(':')[1]}`
})

const minute = computed({
  get: () => parseInt(time.value.split(':')[1]),
  set: (value) => time.value = `${time.value.split(':')[0]}:${value.toString().padStart(2, '0')}`
})

// compute hours and minutes
const hours = computed(() => {
  const start = parseInt(props.range.opening_time.split(':')[0])
  const end = parseInt(props.range.closing_time.split(':')[0])

  return Array.from({ length: end - start + 1 }, (_, i) => i + start)
})
// calc minutes based on stepminute and range
// if hour is last hour, then minutes should be limited to the last minute
const minutes = computed(() => {
  const start = parseInt(props.range.opening_time.split(':')[0])
  if (hour.value === start) {
    const firstMinute = parseInt(props.range.opening_time.split(':')[1])
    return Array.from({ length: 60 / props.stepminute }, (_, i) => i * props.stepminute).filter(i => i >= firstMinute)
  }

  const end = parseInt(props.range.closing_time.split(':')[0])
  if (hour.value === end) {
    const lastMinute = parseInt(props.range.closing_time.split(':')[1])
    return Array.from({ length: 60 / props.stepminute }, (_, i) => i * props.stepminute).filter(i => i <= lastMinute)
  }

  return Array.from({ length: 60 / props.stepminute }, (_, i) => i * props.stepminute)
})

const srollToValues = () => {
  if (props.disabled) return

  setTimeout(() => {
    const hoursPanel = document.querySelector('.hours-panel')
    if (hour.value) {
      const hourElement = document.querySelector(`.hours-panel > div:nth-child(${hour.value + 1})`)
      hoursPanel?.scrollTo({
        top: hourElement?.offsetTop - hoursPanel?.offsetTop,
        behavior: 'smooth'
      })
    }

    const minutesPanel = document.querySelector('.minutes-panel')
    if (minute.value) {
      const minuteElement = document.querySelector(`.minutes-panel > div:nth-child(${minute.value + 1})`)
      minutesPanel?.scrollTo({
        top: minuteElement?.offsetTop - minutesPanel?.offsetTop,
        behavior: 'smooth'
      })
    }
  }, 100)
}
</script>
<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      class="group flex items-center focus:outline-none focus:ring-0 relative pl-2 pr-8 py-1 rounded border border-gray-200 min-w-20" :class="props.class"
      @click="srollToValues"
      :disabled="props.disabled"
    >
      <div class="text-gray-600 gap-0.5 flex items-center">
        <span>{{ DateTimeHelper.formatTime(time, props.format) }}</span>
      </div>
      <ClockIcon class="w-4 h-4 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-show="open && !props.disabled"
        class="absolute z-30 top-8 left-0 transform bg-white/60 border border-gray-200 rounded shadow-lg overflow-hidden transition-all delay-75 duration-300 ease-in-out backdrop-blur backdrop-filter pb-2"
      >
        <div class="flex gap-px min-w-20">
          <div class="flex flex-col w-1/2">
            <div class="text-xs text-gray-400 text-center bg-gray-100 font-semibold">時</div>
            <div class="overflow-y-auto max-h-40 scroll-m-0 scroll-smooth hours-panel">
              <template v-for="i in hours">
                <div
                  @click.prevent="hour = i"
                  :class="{ 'bg-primary/30': i === hour }"
                  class="p-1 cursor-pointer text-center"
                >{{ i }}</div>
              </template>
            </div>
          </div>
          <div class="flex flex-col w-1/2">
            <div class="text-xs text-gray-400 text-center bg-gray-100 font-semibold">分</div>
            <div class="overflow-y-auto max-h-40 scroll-m-0 scroll-smooth minutes-panel">
              <template v-for="i in minutes">
                <div
                  @click.prevent="minute = i"
                  :class="{ 'bg-primary/30': i === minute }"
                  class="p-1 cursor-pointer text-center"
                >{{ i.toString().padStart(2, '0') }}</div>
              </template>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>