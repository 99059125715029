<script setup>
import { ref } from 'vue'

const refMemo = ref(null)
const adminMemo = ref('')
const shareMemo = ref('')
const parentElem = ref(null)

const show = (am, sm, elm, mode) => {
  // check if memo is empty
  if (!am && !sm) return

  // set value
  adminMemo.value = am
  shareMemo.value = sm
  parentElem.value = elm
  
  // show memo
  refMemo.value.classList.remove('hidden')
  refMemo.value.classList.add('flex')
  
  // set position
  const rect = parentElem.value.getBoundingClientRect()
  const top = rect.top + document.querySelector('.jsh-new-calendar').scrollTop
  const left = rect.right + document.querySelector('.jsh-new-calendar').scrollLeft + 4
  const bottom = top + refMemo.value.offsetHeight
  const right = left + refMemo.value.offsetWidth

  // get calendar height and width
  const h = document.querySelector('.jsh-new-calendar').offsetHeight
  const w = document.querySelector('.jsh-new-calendar').offsetWidth

  // set position
  if (mode === 'timeGridDay') {
    refMemo.value.style.left = `${rect.left + 4}px`
    if (bottom > h) {
      refMemo.value.style.bottom = '20px'
    } else {
      refMemo.value.style.top = `${top + 4}px`
    }
    return
  } 
  
  if (mode === 'listWeek') {
    refMemo.value.style.top = `${top}px`
    refMemo.value.style.right = '40px'
    return
  }

  if (bottom > h) {
    refMemo.value.style.bottom = '20px'
  } else {
    refMemo.value.style.top = `${top}px`
  }
  if (right > w) {
    refMemo.value.style.right = `${(rect.width + 40)}px`
  } else {
    refMemo.value.style.left = `${left}px`
  }
}

const hide = () => {
  // not hide if cusor is still on the memo
  if (refMemo.value.matches(':hover')) return

  // reset value
  adminMemo.value = ''
  shareMemo.value = ''
  parentElem.value = null
  refMemo.value.classList.remove('flex')
  refMemo.value.classList.add('hidden')

  // reset style
  refMemo.value.style.top = 'unset'
  refMemo.value.style.bottom = 'unset'
  refMemo.value.style.left = 'unset'
  refMemo.value.style.right = 'unset'
}

defineExpose({ show, hide })
</script>
<template>
  <div ref="refMemo" class="flex-col gap-1 max-w-40 absolute top-0 hidden z-50 transition-all duration-100 delay-75 ease-in-out h-auto text-xs">
    <div v-show="shareMemo" class="w-full bg-yellow-100 p-2 rounded shadow-xl">
      {{ shareMemo }}
    </div>
    <div v-show="adminMemo" class="w-full bg-pink-100 p-2 rounded shadow-xl">
      {{ adminMemo }}
    </div>
  </div>
</template>