import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '../store/auth'

const route = createRouter({
  history: createWebHistory(),
  routes
})

route.beforeEach((to, from, next) => {
  if (to.meta.forPublic || to.matched[0].path == '/front-end') {
    if(to.matched.length == 1){
      next({ path: '/front-end/404'})
      return
    }
    next()
    return
  }
  // check authentication
  const authStore = useAuthStore()
  if (!authStore.isAuthenticated) {

    if (to.meta.forPublic) {
      next()
      return
    } else if (to.path !== '/login') {
      // remove token from axios header
      delete window.axios.defaults.headers.common['Authorization']

      window.location.href = '/login'
      return
    }
  }

  // check role if user is authenticated
  // exits in meta.roles -> can access
  // e.g. user->role = ['schoold', 'club'], meta.roles = ['school', 'pta']
  // need to check for each role
  if (to.meta.roles && to.meta.roles.length > 0) {
    let hasRole = false
    for (let role of to.meta.roles) {
      if (authStore.user.role.includes(role)) {
        hasRole = true
        break
      }
    }

    if (!hasRole) {
      next()
      return
    }
  }

  // set header for axios
  if (authStore.user && authStore.user.token) {
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.user.token}`
  }

  // set timezone header for axios
  window.axios.defaults.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

  next()
})

export default route
