<script setup>
import { onMounted, ref, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ArrowUturnLeftIcon, PencilIcon } from '@heroicons/vue/24/outline';
import EditTabs from './EditTabs.vue';
import { useClubStore } from '../../../js/store/club';
import ClubEditModal from './ClubEditModal.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const clubStore = useClubStore();
const clubEditModelRef = ref(null);

// get id from route
const id = ref(parseInt(route.params.id));
if (!id.value) {
  $root.push.error('クラブIDが見つかりません');
  router.push('/club');
}

onMounted(async () => {
  $root.overlay.loading = true;
  await clubStore.getItem(id.value);
  $root.overlay.loading = false;
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが見つかりません');
    router.push('/club');
  }
});
</script>
<template>
  <div class="flex flex-col gap-2 p-2 md:p-4 w-full">
    <div class="flex flex-col-reverse md:flex-row md:items-center justify-between gap-2 md:gap-20 w-full">
      <div class="grow">
        <div class="flex flex-col md:flex-row items-stretch border-t-2 border-t-primary border-b border-gray-200 h-auto divide-y divide-gray-200 md:divide-y-0">
          <div class="grow">
            <div class="flex h-full items-stretch">
              <div class="w-1/3 md:w-fit flex-none">
                <div class="p-1 flex h-full items-center md:py-0 md:px-2 bg-graysp">
                  クラブ名
                </div>
              </div>
              <div class="grow">
                <div class="flex h-full items-center text-sblue font-semibold md:text-lg px-1 md:px-4 flex-wrap">
                  {{ clubStore.item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="grow">
            <div class="flex h-full items-stretch">
              <div class="p-1 w-1/3 md:w-fit flex h-full items-center px-1 md:px-2 bg-graysp">
                責任者氏名
              </div>
              <div class="grow">
                <div class="flex h-full items-center px-1 md:px-4 flex-wrap">
                  {{ clubStore.item.teacher }}
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-fit flex-none">
            <div class="flex h-full items-stretch">
              <div class="p-1 w-1/3 md:w-fit flex h-full items-center px-1 md:px-2 bg-graysp">
                クラブ設定
              </div>
              <div class="py-px md:py-2 px-1 md:px-4">
                <button class="p-1 md:p-2 bg-green rounded-full hover:opacity-80" title="編集" @click.prevent="clubEditModelRef.open()">
                  <PencilIcon class="w-4 h-4 md:w-5 md:h-5 text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-between md:w-fit flex-none">
        <button class="flex items-center gap-2 font-semibold bg-background border border-gray-400 rounded-md px-4 py-1 md:py-2 transition duration-300 delay-75 ease-in-out hover:opacity-80"
          @click.prevent="router.go(-1)"
        >
          <ArrowUturnLeftIcon class="w-5 h-5" />
          戻る
        </button>
      </div>
    </div>
    <EditTabs />
  </div>
  <ClubEditModal ref="clubEditModelRef" />
</template>