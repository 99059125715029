<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { CalendarIcon, ClockIcon } from '@heroicons/vue/24/outline'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useCalendarEvent } from '../../../js/store/calendar-event';

import DateTimeHelper from '../../../js/helper/DatetimeHelper'
const eventStore = useCalendarEvent()
const $root = getCurrentInstance()?.appContext.config.globalProperties

const isOpen = ref(false)
const allDay = ref(false)
const open = async () => {
  allDay.value = false
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async() => {
  $root.overlay.loading = true
  const startDateFomat = DateTimeHelper.format(eventStore.startDate);
  const endDateFomat = DateTimeHelper.format(eventStore.endDate);

  eventStore.newItem.start_time = `${startDateFomat} ${eventStore.starTime.hours}:${eventStore.starTime.minutes}`
  eventStore.newItem.end_time = `${endDateFomat} ${eventStore.endTime.hours}:${eventStore.endTime.minutes}`
  try {
    const response = await eventStore.save();
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getItems();
      resetForm();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}
const update = async() => {
  $root.overlay.loading = true
  const startDateFomat = DateTimeHelper.format(eventStore.startDate);
  const endDateFomat = DateTimeHelper.format(eventStore.endDate);

  eventStore.newItem.start_time = `${startDateFomat} ${eventStore.starTime.hours}:${eventStore.starTime.minutes}`
  eventStore.newItem.end_time = `${endDateFomat} ${eventStore.endTime.hours}:${eventStore.endTime.minutes}`
  try {
    const response = await eventStore.update();
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getItems();
      resetForm();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}
const updateTime = () => {
  if(allDay.value == true) {
    eventStore.starTime = { "hours": 0, "minutes": 0}
    eventStore.endTime = {"hours": 23, "minutes": 59}
  }else {
    eventStore.starTime = { "hours": null, "minutes": null}
    eventStore.endTime = { "hours": null, "minutes": null}
  }
  
}

const resetForm = () => {
    eventStore.newItem = {color: '#FFFFFF'}
    eventStore.startDate = new Date()
    eventStore.endDate = new Date()
    eventStore.starTime = { "hours": 0, "minutes": 0}
    eventStore.endTime = {"hours": 23, "minutes": 59}
    allDay.value = true
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30 print:hidden">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadown-lg transition-all border-t-2 border-t-secondary"
            >
              <div class="text-primary font-semibold bg-gray-100 px-4 py-2">
                予定詳細
              </div>
              <div class="flex flex-col p-4 gap-2">
                <div class="flex items-center gap-4">
                  <div class="flex-none w-20">
                    タイトル <span class="text-red font-bold">(*)</span>
                  </div>
                  <div class="grow">
                    <div class="flex items-center gap-4">
                      <div class="w-64">
                        <input
                          v-model="eventStore.newItem.name"
                          type="text"
                          class="w-full px-2 py-1 border border-slate-300 rounded"
                          placeholder="予定日名称を記載してください"
                        />
                      </div>
                      <div>
                        <input v-model="eventStore.newItem.color" type="color" class="w-8 h-8 appearance-none rounded p-0"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-start gap-4">
                  <div class="flex-none w-20 mt-4">
                    日付 <span class="text-red font-bold">(*)</span>
                  </div>
                  <div class="grow">
                    <div class="flex flex-col gap-2">
                      <div class="flex items-center gap-4">
                        <div class="flex items-center gap-2">
                          <div class="w-8">開始</div>
                          <div class="max-w-40">
                            <VueDatePicker 
                              auto-apply 
                              format="yyyy-MM-dd"
                              :enable-time-picker="false"
                              locale="ja"
                              input-class-name="px-2 py-1 border border-gray-200 rounded"
                              v-model="eventStore.startDate"
                            >
                              <template #input-icon>
                                <CalendarIcon class="ml-2 w-4 h-4" />
                              </template>
                              <template #dp-input="{ value }">
                                <input type="text" :value="value" class="pl-8 pr-2 py-1 bg-gray-100 rounded max-w-40" />
                              </template>
                            </VueDatePicker>
                          </div>
                          <div class="relative bg-gray-100 rounded w-20">
                            <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                            <VueDatePicker 
                              auto-apply 
                              time-picker
                              format="HH:mm"
                              locale="ja"
                              class="mydatepicker w-fit"
                              :clearable="false"
                              :minutes-increment="15"
                              input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                              v-model="eventStore.starTime"
                              :disabled="allDay"
                            >
                              <template #dp-input="{ value }">
                                <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                              </template>
                            </VueDatePicker>
                          </div>
                        </div>
                        <!-- <div>
                          <button class="px-1 py-1 rounded border bg-white">時間を追加</button>
                        </div> -->
                        <div>
                          <label class="flex items-center gap-1">
                            <input v-model="allDay" type="checkbox" class="w-4 h-4" @change="updateTime"/>
                            終日
                          </label>
                        </div>
                      </div>
                      <div class="flex items-center gap-2">
                        <div class="w-8">終了</div>
                        <div class="max-w-40">
                          <VueDatePicker 
                            auto-apply 
                            format="yyyy-MM-dd"
                            :enable-time-picker="false"
                            locale="ja"
                            input-class-name="px-2 py-1 border border-gray-200 rounded"
                            v-model="eventStore.endDate"
                          >
                            <template #input-icon>
                              <CalendarIcon class="ml-2 w-4 h-4" />
                            </template>
                            <template #dp-input="{ value }">
                              <input type="text" :value="value" class="pl-8 pr-2 py-1 bg-gray-100 rounded max-w-40" />
                            </template>
                          </VueDatePicker>
                        </div>
                        <div class="relative bg-gray-100 rounded w-20">
                          <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                          <VueDatePicker
                            v-model="eventStore.endTime" 
                            auto-apply 
                            time-picker
                            format="HH:mm"
                            locale="ja"
                            class="mydatepicker w-fit"
                            :clearable="false"
                            :minutes-increment="15"
                            input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                            :disabled="allDay"
                          >
                            <template #dp-input="{ value }">
                              <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                            </template>
                          </VueDatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center gap-4">
                  <div class="flex-none w-20">
                    状態
                  </div>
                  <div class="grow">
                    <div class="flex items-center gap-6">
                      <label class="flex items-center gap-1">
                        <input v-model="eventStore.newItem.holiday" type="checkbox" class="w-4 h-4"/>
                        休日
                      </label>
                      <label class="flex items-center gap-1">
                        <input v-model="eventStore.newItem.important" type="checkbox" class="w-4 h-4" />
                        重要
                      </label>
                    </div>
                  </div>
                </div>
                <div class="flex items-start gap-4">
                  <div class="flex-none w-20">
                    メモ
                  </div>
                  <div class="grow">
                    <textarea
                      v-model="eventStore.newItem.memo"
                      class="w-full h-24 p-2 border border-slate-300 rounded"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mb-4 flex items-center justify-center gap-4 font-semibold">
                <button v-if="eventStore.newItem.id"
                  class="px-4 py-1 text-white rounded bg-primary border border-primary hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="update"
                >
                  更新
                </button>
                <button v-else
                  class="px-4 py-1 text-white rounded bg-primary border border-primary hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="save"
                >
                  保存
                </button>
                <button
                  class="px-4 py-1 rounded bg-gray-100 border border-slate-600 text-slate-600 hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="close"
                >
                  キャンセル
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
