<script setup>
import { ref, computed } from 'vue'
import CalendarHelper from '../../../js/helper/CalendarHelper'
import { PencilIcon } from '@heroicons/vue/24/solid';
import DateTimeHelper from '../../../js/helper/DatetimeHelper'

import { useCalendarFactility } from '../../../js/store/calendar-factility';
const facilityStore = useCalendarFactility();

const emit = defineEmits(['edit']);

// default is current year
const year = defineModel('year', Number);

// default is current month
const monthStart = defineModel('monthStart', Number);

const calendars = computed(() => {
  let calendars = CalendarHelper.generateMonthCalendar(year.value, monthStart.value);
  calendars.map((item) => {
    item.map((day)=> {
      let dateString = `${day.year}-${("0" + day.month).slice(-2)}-${("0" + day.day).slice(-2)}`
      day.datas = facilityStore.items.filter((i) => i.date == dateString);
      console.log(day)
    })

  })
  return calendars;
});

const editItem = (item) => {
  item.holiday = item.holiday ? true : false;
  item.important = item.important ? true : false;
  eventStore.newItem = JSON.parse(JSON.stringify(item));
  eventStore.startDate =  DateTimeHelper.format(item.start_time);
  eventStore.starTime = DateTimeHelper.formatTimeObject(item.start_time);
  eventStore.endDate =  DateTimeHelper.format(item.end_time);
  eventStore.endTime = DateTimeHelper.formatTimeObject(item.end_time);
  emit('edit');
};

const showItem = (item, day) => {
  const currentDate = DateTimeHelper.format(`${year.value}-${monthStart.value}-${day}`);
  const startSchedule = DateTimeHelper.format(item.start_time);
  const endSchedule = DateTimeHelper.format(item.end_time);
  return (startSchedule <= currentDate && currentDate <= endSchedule) ? true : false;
}
const dragOver = (e) => {
  e.preventDefault();
  // console.log('dragOver', e);
};

const drop = (e) => {
  e.preventDefault();
  console.log('drop', e);
};

const isDragging = ref(false)
const isDragOver = ref(false)

const dragendHandler = () => {
  isDragging.value = false
  isDragOver.value = false
}

const dropHandler = (e) => {
  e.preventDefault()
  isDragging.value = false
  isDragOver.value = false
}
</script>
<template>
  <table class="table w-full border-separate border-spacing-0 transition-all duration-500 delay-75 ease-in-out relative">
    <thead class="bg-gray-100 sticky top-0 left-0 right-0 h-8">
      <tr class="divide-x divide-slate-400">
        <th class="p-1 text-error bg-error/10">日</th>
        <th class="p-1">月</th>
        <th class="p-1">火</th>
        <th class="p-1">水</th>
        <th class="p-1">木</th>
        <th class="p-1">金</th>
        <th class="p-1 text-blue-500 bg-blue-100">土</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-slate-400">
      <tr v-for="w in calendars" class="divide-x divide-slate-400">
        <td v-for="d in w" 
          class="p-1 border-t border-slate-400 relative group"
          :class="{
            'bg-error/10': d.isSun,
            'bg-blue-100': d.isSat,
          }"
          @dragover="dragOver"
          @drop="drop"
        >
          
            <div class="flex flex-col gap-0.5 items-start min-h-32">
                <div class="font-semibold text-lg">{{ d.day }}</div>
                    <div class="flex w-full flex-col p-1 min-h-14 transition delay-75 duration-300 ease-in-out relative min-w-40"
                    :class="{
                        'border-dashed bg-white/60': isDragging,
                    }"
                    draggable="true"
                    @dragstart="isDragging = true"
                    @dragend="dragendHandler"
                    @click.prevent="editItem"
                    >
                    <div class="flex items-center gap-2"> 体育館 <span>15:45～21:00</span></div>
                    <div class="flex items-center gap-2">校　庭<span>15:45～21:00</span></div>
                    <div class="flex items-center gap-2">テニス<span>15:45～21:00</span></div>
                </div>
            </div>

            <button 
            class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-1 rounded-full bg-green absolute right-2 top-1/2 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out"
            @click.prevent="emit('edit')"
            >
            <PencilIcon class="w-5 h-5 text-white" />
            </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
