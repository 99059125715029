<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useCalendarEvent } from '../../../js/store/calendar-event'
const eventStore = useCalendarEvent();
const $root = getCurrentInstance()?.appContext.config.globalProperties

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async() => {
  $root.overlay.loading = true
  try {
    const response = eventStore.newNote.id ? await eventStore.updateNote() : await eventStore.saveNote();
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getNotes();
      eventStore.newNote = {};
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30 print:hidden">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-sm transform bg-white text-left align-middle shadown-lg transition-all border-t-2 border-t-secondary"
            >
              <div class="text-primary font-semibold bg-gray-100 px-4 py-2">
                備考メモ
              </div>
              <div class="flex flex-col px-4 py-6 gap-4">
                <div class="flex flex-col">
                  <div class="text-gray-500">備考</div>
                  <div>
                    <textarea
                      v-model="eventStore.newNote.content"
                      class="w-full h-24 p-2 border border-slate-300 rounded"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mb-4 flex items-center justify-center gap-4 font-semibold">
                <button
                  class="px-4 py-1 text-white rounded bg-primary border border-primary hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="save"
                >
                  保存
                </button>
                <button
                  class="px-4 py-1 rounded bg-gray-100 border border-slate-600 text-slate-600 hover:opacity-80 transition delay-75 duration-300 ease-in-out min-w-28"
                  @click.prevent="close"
                >
                  キャンセル
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
