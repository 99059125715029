<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  fill: {
    type: String,
    default: '',
  },
})
</script>
<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" :class="props.class">
    <g clip-path="url(#clip0_2678_24480)">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16ZM5.427 4.99C5.14233 5.41933 5 5.84267 5 6.26C5 6.46333 5.09 6.652 5.27 6.826C5.45 7 5.67033 7.08667 5.931 7.086C6.37433 7.086 6.67533 6.83733 6.834 6.34C7.002 5.86467 7.20733 5.50467 7.45 5.26C7.69267 5.016 8.07067 4.894 8.584 4.894C9.02267 4.894 9.381 5.015 9.659 5.257C9.93633 5.49967 10.075 5.797 10.075 6.149C10.0762 6.32559 10.0292 6.49917 9.939 6.651C9.84635 6.80514 9.73333 6.94608 9.603 7.07C9.39273 7.26256 9.17663 7.44864 8.955 7.628C8.615 7.91 8.34433 8.15333 8.143 8.358C7.943 8.56333 7.782 8.801 7.66 9.071C7.338 10.316 9.01 10.416 9.396 9.527C9.44267 9.44167 9.51367 9.347 9.609 9.243C9.705 9.13967 9.83233 9.01967 9.991 8.883C10.3956 8.54603 10.7937 8.2013 11.185 7.849C11.4063 7.645 11.5973 7.40167 11.758 7.119C11.924 6.81806 12.0075 6.47859 12 6.135C12 5.66033 11.8587 5.22033 11.576 4.815C11.294 4.409 10.894 4.08833 10.376 3.853C9.858 3.61767 9.26067 3.5 8.584 3.5C7.856 3.5 7.219 3.641 6.673 3.923C6.127 4.205 5.71167 4.56133 5.427 4.99ZM7.567 12.07C7.567 12.3352 7.67236 12.5896 7.85989 12.7771C8.04743 12.9646 8.30178 13.07 8.567 13.07C8.83222 13.07 9.08657 12.9646 9.27411 12.7771C9.46164 12.5896 9.567 12.3352 9.567 12.07C9.567 11.8048 9.46164 11.5504 9.27411 11.3629C9.08657 11.1754 8.83222 11.07 8.567 11.07C8.30178 11.07 8.04743 11.1754 7.85989 11.3629C7.67236 11.5504 7.567 11.8048 7.567 12.07Z"
        :fill="props.fill" />
    </g>
    <defs>
      <clipPath id="clip0_2678_24480">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
