<script setup>
import { ref, computed } from 'vue'
import { PlusIcon } from '@heroicons/vue/24/solid';
import CalendarHelper from '../../../js/helper/CalendarHelper';
import ModalNote from './ModalNote.vue';
import { useCalendarEvent } from '../../../js/store/calendar-event';

const eventStore = useCalendarEvent();
// default is current year
const year = defineModel('year', Number);
// default range is 3 months
const range = defineModel('range', Number);
// default is current month
const monthStart = defineModel('monthStart', Number);
const modalNote = ref(null);
const calendars = computed(() => {
  return CalendarHelper.generateCalendars(year.value, monthStart.value, range.value);
});

const showModalNote = (idx) => {
  let monthCurent = monthStart.value + idx;
  let yearCurent = year.value;
  if( monthCurent > 12) {
    monthCurent = monthCurent - 12;
    yearCurent = year.value + 1;
  }
  const note = eventStore.notes.find((note)=> note.year == yearCurent && note.month == monthCurent);
  eventStore.newNote = note ? JSON.parse(JSON.stringify(note)) : {year : yearCurent, month: monthCurent}
  modalNote.value.open();
}
const showItem = (item, idx) => {
  let monthCurent = monthStart.value + idx;
  let yearCurent = year.value;
  if( monthCurent > 12) {
    monthCurent = monthCurent - 12;
    yearCurent = year.value + 1;
  }
  return (monthCurent == item.month && monthCurent <= item.year) ? true : false;
}
</script>
<template>
<div 
  class="grid transition delay-75 duration-300 ease-in-out calendar-range-footer" 
  :class="{
      'grid-cols-1': calendars.length === 1,
      'grid-cols-2': calendars.length === 2,
      'grid-cols-3': calendars.length === 3,
      'grid-cols-4': calendars.length === 4,
      'grid-cols-5': calendars.length === 5,
      'grid-cols-6': calendars.length === 6,
      'grid-cols-7': calendars.length === 7,
      'grid-cols-8': calendars.length === 8,
      'grid-cols-9': calendars.length === 9,
      'grid-cols-10': calendars.length === 10,
      'grid-cols-11': calendars.length === 11,
      'grid-cols-12': calendars.length === 12,
  }"
>
  <div v-for="(cal, idx) in calendars" class="border-t border-slate-400 group">
    <div class="text-gray-400 text-left pt-4 px-0.5 w-full">
      23日
    </div>
    <div 
      class="flex flex-col p-0.5 min-h-32 relative justify-start items-start group border-y border-slate-400 first:border-l first:border-slate-400 last:border-r group-hover:bg-green/10 transition delay-75 duration-300 ease-in-out cursor-pointer"
      :class="{ 'border-l': idx === 0 }"
      @click.prevent="showModalNote(idx)"
    >
      備考
      <template v-for="item in eventStore.notes">
        <div v-if="showItem(item, idx)" class="w-full flex items-start whitespace-pre-wrap">
          {{ item.content }}
        </div>
      </template>

      <button
        @click.prevent="showModalNote(idx)"
        class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-px rounded-sm bg-slate-700 absolute right-2 bottom-2 hover:opacity-80 transition delay-75 duration-300 ease-in-out"
      >
        <PlusIcon class="w-4 h-4 text-white" />
      </button>
    </div>
  </div>
</div>
<ModalNote ref="modalNote" />
</template>
