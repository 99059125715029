<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

const emit = defineEmits(['ok'])
const isOpen = ref(false)

const open = () => {
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const ok = () => {
  emit('ok')
  close()
}

defineExpose({ open, close })
</script>
<template>
  <!-- 編集中の内容が破棄されます。 -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-fit transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-error"
            >
              <div class="w-full flex flex-col gap-6 items-center justify-center px-8 py-4">
                <div class="text-lg font-semibold text-error">
                  編集中の内容が破棄されます。
                </div>
                <div class="flex flex-col gap-2">
                  <div>申請画面を閉じてもよろしいですか？</div>
                  <div>閉じる場合は、設定内容が失われます。</div>
                </div>
                <div class="flex items-center justify-between gap-4">
                  <button class="text-white bg-error rounded px-4 py-1 hover:opacity-80" @click.prevent="ok">
                    破棄して閉じる
                  </button>
                  <button class="border rounded px-4 min-w-1/2 py-1 hover:opacity-80" @click.prevent="close">
                    キャンセル
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
