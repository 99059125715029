import { defineStore } from 'pinia'

export const useCalendarEvent = defineStore('calendar-event', {
  state: () => ({
    items: [],
    notes: [],
    year: new Date().getFullYear(),
    newItem: {color: '#000000'},
    newNote: {},
    startDate: new Date(),
    endDate: new Date(),
    starTime: { "hours": 0, "minutes": 0},
    endTime: {"hours": 23, "minutes": 59},
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/events?year=` + this.year);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    },
    async getNotes() {
      const response = await axios.get(`/api/notes?year=` + this.year);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.notes = response.data;
    },
    async update() {
      return await axios.put('/api/events/' + this.newItem.id, this.newItem);
    },
    async delete(id) {
      return await axios.delete('/api/events/' + id);
    },
    async save() {
      return await axios.post('/api/events', this.newItem);
    },

    async saveNote() {
      return await axios.post('/api/notes', this.newNote);
    },
    async updateNote() {
      return await axios.put('/api/notes/' + this.newNote.id, this.newNote);
    },
  },
})
