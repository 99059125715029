<script setup>
import { ref, getCurrentInstance } from 'vue'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import TablePagination from './TablePagination.vue';
import ClubItemModal from './ClubItemModal.vue';
import ClubEditModal from '../pages/club/ClubEditModalOutside.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const clubItemModal = ref(false)
const clubEditModal = ref(false)

const doSearch = async () => {
  $root.overlay.loading = true
  await clubStore.getItems()
  $root.overlay.loading = false
}

const editClubOutside = async (id) => {
  $root.overlay.loading = true
  await clubStore.getItem(id)
  $root.overlay.loading = false
  clubEditModal.value.open()
}
</script>
<template>
  <div class="w-full bg-white rounded-md flex flex-col gap-2">
    <div class="flex items-end justify-between gap-2">
      <!-- searching -->
      <div class="flex items-end gap-2">
        <!-- searching by text -->
        <div class="relative">
          <input 
            type="text" 
            class="pl-2 pr-8 py-1 border border-gray-200 rounded w-full md:w-56" 
            v-model="clubStore.filter.text" 
            placeholder="クラブ名で検索"
            @keyup.enter="doSearch"
          />
          <button 
            class="absolute right-2 top-2 w-4 h-4 text-gray-400 cursor-pointer" 
            v-show="clubStore.filter.text" 
            @click="clubStore.filter.text = ''"
            :title="clubStore.filter.text ? 'クリア' : ''"
          >
            <XMarkIcon class="w-4 h-4" />
          </button>
        </div>

        <!-- button search -->
        <button 
          class="px-2 md:px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out min-w-max" 
          @click.prevent="doSearch"
        >
          <MagnifyingGlassIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
          検索
        </button>
      </div>
      <div class="flex items-center gap-1">
        <!-- add new btn -->
        <button 
          class="px-2 md:px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out min-w-max" 
          @click.prevent="clubItemModal.open()"
        >
          <PlusIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
          新規追加
        </button>
      </div>
    </div>
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th v-for="(header, idx) in clubStore.headers" 
            class="font-normal p-2 text-left"
            :class="{
              'hidden md:table-cell': idx > 0
            }"
          >{{ header.text }}</th>
          <th class="font-normal p-2 text-center hidden md:table-cell">クラブ管理</th>
          <th class="font-normal p-2 text-center hidden md:table-cell">クラブ設定</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="clubStore.items && clubStore.items.length">
          <tr v-for="item in clubStore.items" class="border-b border-b-gray-200 hover:bg-gray-50">
            <td class="p-2">
              <RouterLink :to="{ path: `/club/${item.id}` }" class="font-bold text-pblue cursor-pointer hover:opacity-80 hover:underline w-full hidden md:flex justify-between items-center" :title="item.name">
                {{ item.name }}
              </RouterLink>
              <div class="flex flex-col md:hidden">
                <RouterLink :to="{ path: `/club/${item.id}` }" class="font-bold text-pblue cursor-pointer hover:opacity-80 hover:underline w-full flex justify-between items-center" :title="item.name">
                  <div class="grow">
                    <div class="flex items-center">
                      <div class="truncate max-w-64 sm:max-w-96 md:max-w-none">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                  <div class="flex-none w-fit md:hidden">
                    <ChevronRightIcon class="w-4 h-4" />
                  </div>
                </RouterLink>
                <div class="flex flex-col md:hidden text-xs">
                  <div class="flex items-center gap-2">
                    責任者氏名: {{ item.teacher }}
                    <span v-if="item.teacher_phone" class="text-xs text-grey">({{ item.teacher_phone }})</span>
                  </div>
                  <div class="flex items-center">
                    子供数/メンバー数: {{ item.total_childs ?? 0 }}/{{ item.members ?? 0 }}
                  </div>
                  <div class="flex items-center">
                    大人数/メンバー数: {{ item.members - item.total_childs }}/{{ item.members ?? 0 }}
                  </div>
                  <div class="flex items-center">
                    学校関連/メンバー数: {{ item.total_relateds ?? 0 }}/{{ item.members ?? 0 }}
                  </div>
                </div>
              </div>
            </td>
            <td class="p-2 hidden md:table-cell">
              {{ item.teacher }}<br />
              <span class="text-xs text-grey">{{ item.teacher_phone }}</span>
            </td>
            <td class="p-2 hidden md:table-cell">{{ item.total_childs ?? 0 }}/{{ item.members ?? 0 }}</td>
            <td class="p-2 hidden md:table-cell">{{ item.members - item.total_childs }}/{{ item.members ?? 0 }}</td>
            <td class="p-2 hidden md:table-cell">{{ item.total_relateds ?? 0 }}/{{ item.members ?? 0 }}</td>
            <td class="p-2 text-center hidden md:table-cell">
              <div class="flex justify-center">
                <!-- ■クラブ管理ボタンを追加　別途クラブ管理のページを設定（スケジュール・メンバー等）-->
                <RouterLink 
                  class="px-2 py-1 bg-blue-500 text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out w-fit min-w-max" 
                  :to="{ path: `/club/${item.id}` }"
                >
                  クラブ管理
                </RouterLink>
              </div>
            </td>
            <td class="p-2 text-center hidden md:table-cell">
              <div class="flex justify-center">
                <button class="px-2 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out w-fit min-w-max"
                  @click.prevent="editClubOutside(item.id)"
                >
                  <!-- ■クラブ設定ボタン以前と同じ設定。クラブ登録のモーダルを表示 -->
                  クラブ設定
                </button>
              </div>
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-xs text-grey" :colspan="clubStore.headers.length">
            データがありません
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination 
      :total="clubStore.total" 
      :perPage="clubStore.perpage"
      v-model:currentPage="clubStore.page"
      :onPageChange="doSearch"
      unit="件"
    />
  </div>
  <ClubItemModal ref="clubItemModal" />
  <ClubEditModal ref="clubEditModal" />
</template>
