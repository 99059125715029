<script setup>
import { getCurrentInstance } from 'vue'
import { useAuthStore } from '../../js/store/auth'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const authStore = useAuthStore()

// auto login
if (authStore.isAuthenticated) {
  window.location.href = '/'
}

// do login
const submit = async () => {
  $root.overlay.loading = true
  await authStore.login()
  
  if (authStore.errors.loginId || authStore.errors.password) {
    $root.overlay.loading = false
    return
  }

  if (authStore.errors.response) {
    $root.push.error(authStore.errors.response)
    $root.overlay.loading = false
    return
  }

  if (authStore.user.role.includes('school')) {
    window.location.href = '/'
    return
  } else if (authStore.user.role.includes('PTA')) {
    window.location.href = '/email-history'
    return
  } else if (authStore.user.role.includes('club')) {
    window.location.href = '/club'
    return
  }

  window.location.href = '/'
}
</script>
<template>
  <div class="flex w-screen h-dvh bg-graysp align-middle items-center justify-center p-6">
    <form 
      @submit.prevent="submit"
      class="flex flex-col w-full sm:w-1/2 lg:w-1/4 min-h-min p-8 items-center space-y-8 bg-white rounded-lg shadow transition-all delay-75 duration-300 ease-in-out"
    >
      <h1 class="text-tertiary font-bold italic text-4xl font-racingsans">
        School go
      </h1>

      <div class="flex flex-col space-y-4 w-full">
        <div class="flex flex-col justify-start w-full">
          <label>ログインID</label>
          <input 
            v-model="authStore.form.loginId" 
            type="text" 
            class="border border-grey/40 rounded-sm w-full px-2 py-1" 
            @keyup.enter.once="submit"
          />
          <div 
            v-show="authStore.errors.loginId" 
            class="text-error text-xs transition-all delay-75 duration-300 ease-in-out"
          >{{ authStore.errors.loginId }}</div>
        </div>

        <div class="flex flex-col justify-start w-full">
          <label>パスワード</label>
          <input 
            v-model="authStore.form.password" 
            type="password" 
            class="border border-grey/40 rounded-sm w-full px-2 py-1" 
            @keyup.enter.once="submit"
          />
          <div 
            v-show="authStore.errors.password" 
            class="text-error text-xs transition-all delay-75 duration-300 ease-in-out"
          >{{ authStore.errors.password }}</div>
        </div>
      </div>

      <button 
        type="submit" 
        class="bg-primary hover:opacity-80 text-base text-white font-bold px-8 py-2 rounded-md w-full"
      >
        ログイン
      </button>
    </form>
  </div>
</template>
