<script setup>
import { ref, onMounted } from 'vue';
import { PlusCircleIcon, MagnifyingGlassIcon, PencilIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import ModalResource from './ModalResource.vue';
import { useResource } from '../../../js/store/resource';

const modalResource = ref(null);
const itemNew = ref({
      id: 0,
      name: '',
      notes: ''
    });
const resourceStore = useResource()
onMounted(() => {
  resourceStore.getItems();
})

const openModalResource = (item) => {
  resourceStore.item = JSON.parse(JSON.stringify(item))
  modalResource.value.open();
}
</script>
<template>
  <table class="w-full table border-b divide-y">
    <thead class="border-t-2 border-t-secondary bg-gray-100">
      <tr>
        <th class="text-left p-1">
          <div class="flex items-center gap-1">
            <div class="flex items-center font-semibold text-primary">物品 <span class="text-gray-400 font-normal">({{ resourceStore.items.length }})</span></div>
            <div class="relative">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500 absolute top-1/2 left-2 transform -translate-y-1/2" />
              <input type="text" v-model="resourceStore.search" class="pr-2 px-6 py-px border w-32 rounded text-gray-500" placeholder="名前" />
              <button v-if="resourceStore.search" @click="resourceStore.search = ''" class="absolute top-1/2 right-2 transform -translate-y-1/2">
                <XMarkIcon class="w-4 h-4 text-gray-500" />
              </button>
            </div>
          </div>
        </th>
        <th class="text-end w-8 p-1">
          <button @click.prevent="openModalResource(itemNew)">
            <PlusCircleIcon class="w-5 h-5 text-primary" />
          </button>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y">
      <tr v-for="resource in resourceStore.searchItems">
        <td class="p-1">
          <div class="flex flex-col leading-tight">
            {{ resource.name }}
            <div class="text-xs text-gray-500">({{ resource.quantity }} {{ resource.unit }})</div>
          </div>
        </td>
        <td class="p-1">
          <button class="p-1.5 rounded-full bg-green" @click.prevent="openModalResource(resource)">
            <PencilIcon class="w-4 h-4 text-white" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ModalResource ref="modalResource" />
</template>
