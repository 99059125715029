<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogTitle, DialogPanel } from '@headlessui/vue'
import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })

const confirm = ()=>{
  fronEndClubStore.confirm = true
  close()
}
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4">
              <DialogTitle class="border-primary border-l-4 bg-gray-100">
                <h3 class="text-lg font-medium leading-6 text-start py-2 px-1">プライバシーポリシーの確認</h3>
              </DialogTitle>

              <div class="mt-2 flex flex-col gap-2">

                <div class="flex flex-col border border-[#9BAEB1] p-2 gap-4">
                  <div class="font-semibold">
                    ハノイ日本人学校（以下、「本校」といいます。）は、校務支援システム「School go」（以下、「本サービス」といいます。）における利用者の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第1条（個人情報）</div>
                    「個人情報」とは、個人情報保護法において定義される「個人情報」を指し、生存する個人に関する情報で、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等、および容貌、指紋、声紋にかかるデータ、KYC（本人確認可能な書類等）など、単体または他の情報と組み合わせることで特定の個人を識別できる情報（個人識別情報）を意味します。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第2条（個人情報の収集方法）</div>
                    本校および本サービスは、利用登録の際に、氏名、生年月日、住所、電話番号、メールアドレス、振込先情報、KYCなどの個人情報をお尋ねすることがあります。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第3条（個人情報の収集・利用目的）</div>
                    本校および本サービスは、次項に定める利用目的の達成に必要な範囲内で、個人情報を適法かつ適正な手段によって取得し、適正に利用します。この目的には以下が含まれます。
                  </div>

                  ・学内施設・設備の利用管理、保安管理

                  <div>
                    これに加え、関連または附随する目的で利用する場合、ご本人の同意がある場合または法令により認められる場合を除き、その他の目的には利用しません。なお、他の利用目的については、個別に公表または通知することがあります。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第4条（利用目的の変更）</div>
                    本校および本サービスは、利用目的が変更前と合理的に関連する場合に限り、個人情報の利用目的を変更します。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第5条（個人情報の第三者提供）</div>
                    本校および本サービスは、以下の場合を除き、あらかじめ利用者の同意を得ることなく、第三者に個人情報を提供しません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                  </div>

                  <ol class="list-decimal list-inside">
                    <li>
                      人の生命、身体、または財産の保護のために必要がある場合で、本人の同意を得ることが困難であるとき。
                    </li>
                    <li>
                      公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合で、本人の同意を得ることが困難であるとき。
                    </li>
                    <li>
                      国の機関または地方公共団体、その委託を受けたものが法令の定める事務を遂行する際に協力が必要で、本人の同意を得ることで事務の遂行に支障を及ぼすおそれがあるとき。
                    </li>
                  </ol>

                  <div class="flex flex-col">
                    <div class="font-semibold">第6条（データの取り扱いについて）</div>
                    <ol class="list-decimal list-inside">
                      <li>本システム内のデータは、本校及び本サービス、および利用者に帰属するものとします。ただし、利用者（個人および団体）が自ら使用する場合はこの限りではありません。</li>
                      <li>利用者は、前項のデータについて、本サービスの利用に必要な範囲内に限り、自己利用することができます。</li>
                      <li>本システム内において発行された個人認証データについて、利用者は、本サービスの利用に必要な範囲内に限り使用でき、これを第三者に開示・漏洩し、または本サービスの利用の目的外で使用してはならないものとします。</li>
                    </ol>
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第7条（個人情報の開示）</div>
                    本校および本サービスは、本人から個人情報の開示を求められた場合、遅滞なくこれを開示します。ただし、開示により次のいずれかに該当する場合、その全部または一部を開示しないことがあります。その場合、開示しない旨を遅滞なく通知します。
                  </div>

                  <ol class="list-decimal list-inside">
                    <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合。</li>
                    <li>本校および本サービスの業務の適正な実施に著しい支障を及ぼすおそれがある場合。</li>
                    <li>その他法令に違反することとなる場合。</li>
                  </ol>
                  
                  <div>なお、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第8条（個人情報の訂正および削除、または利用停止等）</div>
                    <ol class="list-decimal list-inside">
                      <li>利用者は、本校および本サービスが保有する自己の個人情報が誤った情報である場合、定められた手続きにより訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。本校および本サービスは、利用者からの請求を受け、それに応じる必要があると判断した場合、遅滞なく当該個人情報の訂正等を行います。</li>
                      <li>本人から、個人情報が利用目的の範囲を超えて取り扱われている、または不正な手段で取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます。）を求められた場合、遅滞なく必要な調査を行います。その調査結果に基づき、その請求に応じる必要があると判断した場合、遅滞なく当該個人情報の利用停止等を行います。</li>
                    </ol>
                  </div>

                  <div>本校および本サービスは、前項に基づき利用停止等を行った場合、または利用停止等を行わないとの決定をした場合、遅滞なくこれを利用者に通知します。ただし、利用停止等に多額の費用がかかる場合や、その他の理由により利用停止等が困難な場合で、利用者の権利利益を保護するために必要な代替策を講じるものとします。</div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第9条（プライバシーポリシーの変更）</div>
                    本ポリシーの内容は、法令その他本ポリシーに別段の定めがある事項を除き、利用者に通知することなく変更することができます。変更後のプライバシーポリシーは、本ウェブサイトに掲載された時点から効力を生じます。
                  </div>

                  <div class="flex flex-col">
                    <div class="font-semibold">第10条（お問い合わせ窓口）</div>
                    本ポリシーに関するお問い合わせは、以下の窓口までお願いいたします。
                  </div>

                  <div>School Go 事務局</div>
                  Eメールアドレス：schoolgo@omt-quality.jp
                </div>

                <div class="flex flex-row items-center justify-center gap-4">
                <button type="button" @click="close"
                  class="w-1/2 border shadow text-gray-800 font-bold  py-1 rounded-md hover:opacity-80">
                  {{ $t('close') }}
                </button>
                <button type="submit" @click="confirm()"
                  class="w-1/2 shadow bg-primary  text-white font-bold py-1 rounded-md hover:opacity-80 ">
                  {{ $t('agree') }}
                </button>
              </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
