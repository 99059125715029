<script setup>
import { ref, getCurrentInstance, } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { PlusIcon, MinusCircleIcon } from '@heroicons/vue/24/outline';
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { useStaff } from 'store/staff';
const staffStore = useStaff()

const isOpen = ref(false)
const arrPositions = ref([])
const open = async () => {
  isOpen.value = true
  arrPositions.value = Object.assign([], JSON.parse(JSON.stringify(staffStore.positions)))
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })

const addStaffPossition = () => {
  arrPositions.value.push({ id: 0, name: "", reason: "", status: 1 })
}

const removePosision = (index) => {
  arrPositions.value.splice(index, 1)
}

const save = async () => {
  $root.overlay.loading = true
  await staffStore.validatePosition(arrPositions.value)
  if (Object.entries(staffStore.error).length > 0) {
    $root.push.error('必須項目を入力してください')
    $root.overlay.loading = false
    return
  }
  staffStore.savePositions(arrPositions.value).then((result) => {
    if (result.length > 0) {
      $root.push.success('保存しました。')
      staffStore.positions = result
    }
    else {
      $root.push.error('エラーが発生しました')
    }
    close()
  })
    .catch((err) => {
      close()
      $root.push.error('エラーが発生しました')
    });

  $root.overlay.loading = false

}

</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary p-4">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 text-center">役割新規登録</DialogTitle>
              <div class="mt-2 flex flex-col gap-2">

                <div class="mt-2 flex flex-col gap-2 max-h-[500px] overflow-auto">
                  <table class="w-full">
                    <tbody>
                      <tr>
                        <td>位置</td>
                        <td>入校理由</td>
                      </tr>
                      <tr v-for="(position, idx) in arrPositions">
                        <td class="w-44 ">
                          <input v-model="position.name" type="text" maxlength="100"
                            :class="staffStore.error[position.name] ? 'border-rose-500' : 'border-gray-300'"
                            class="w-full border rounded p-2 " />
                        </td>
                        <td class="">
                          <div class="flex flex-row items-center pl-2 pt-1 gap-2">
                            <input v-model="position.reason" type="text" maxlength="255"
                              class="w-full border rounded p-2 border-gray-300" />

                            <template v-if="position.id == 0">
                              <MinusCircleIcon @click.prevent="removePosision(idx)"
                                class="w-5 h-5 text-error cursor-pointer" />
                            </template>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="flex flex-col gap-2 items-center justify-center pt-2">
                  <button
                    class="px-4 py-2 bg-tertiary rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                    @click.prevent="addStaffPossition">
                    <PlusIcon class="w-5 h-5 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                    追加
                  </button>
                </div>

                <div class="flex items-center gap-4 justify-center pt-4">
                  <button type="button" class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="save">
                    保存</button>
                  <button type="button" class="p-2 rounded-md bg-gray-100 text-gray-800 hover:opacity-80"
                    @click="close">
                    キャンセル
                  </button>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
