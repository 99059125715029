<script setup>
import { ref, getCurrentInstance } from 'vue';
import CalendarHelper from '../../../js/helper/CalendarHelper';
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid';
const $root = getCurrentInstance()?.appContext.config.globalProperties

import DateTimeHelper from '../../../js/helper/DatetimeHelper'
import { name } from 'dayjs/locale/en';

import { useCalendarEvent } from '../../../js/store/calendar-event';
const eventStore = useCalendarEvent();

import { useHoliday } from '../../../js/store/holiday';
const holidayStore = useHoliday();

const emit = defineEmits(['edit']);

const props = defineProps({
  year: Number,
  month: Number,
  day: Number,
  items: Array
});
const editItem = (item) => {
  item.holiday = item.holiday ? true : false;
  item.important = item.important ? true : false;
  eventStore.newItem = JSON.parse(JSON.stringify(item));
  eventStore.startDate =  DateTimeHelper.format(item.start_time);
  eventStore.starTime = DateTimeHelper.formatTimeObject(item.start_time);
  eventStore.endDate =  DateTimeHelper.format(item.end_time);
  eventStore.endTime = DateTimeHelper.formatTimeObject(item.end_time);
  emit('edit');
};

const addItem = () => {
  eventStore.newItem = {color: '#000000'}
  eventStore.startDate =  `${props.year}-${props.month}-${props.day}`;
  eventStore.endDate =  `${props.year}-${props.month}-${props.day}`;
  emit('edit');
};

const deleteItem = async(item) => {
  const confirm = window.confirm('削除しますか？');
  if (!confirm) return;
  $root.overlay.loading = true
  try {
    const response = await eventStore.delete(item.id);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      close();
      eventStore.getItems();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
};

const isDragging = ref(false)
const isDragOver = ref(false)

const dragendHandler = () => {
  isDragging.value = false
  isDragOver.value = false
}

const dropHandler = (e) => {
  e.preventDefault()
  isDragging.value = false
  isDragOver.value = false
}

const isHoliday = () => {
  let dateString = `${props.year}-${("0" + props.month).slice(-2)}-${("0" + props.day).slice(-2)}`;
  return holidayStore.items.findIndex((i) => i.date == dateString  && i.status == 1) >= 0
}
</script>
<template>
  <div 
    class="flex items-center w-full transition delay-75 duration-300 ease-in-out overflow-hidden group hover:bg-green/10 border-b border-slate-400 last:border-0 h-fit print:p-px"
  >
    <div class="flex-none w-10 min-h-8"
      :class="{
        'bg-blue-100 text-blue-500': CalendarHelper.isSat(props.year, props.month, props.day),
        'bg-error/10 text-error': CalendarHelper.isSun(props.year, props.month, props.day) || isHoliday(),
      }"
    >
      <div class="p-1 flex w-full items-center justify-between h-fit">
        <div class="font-semibold text-md">{{ props.day }}</div>
        <span class="text-xs">{{ CalendarHelper.getWeekday(props.year, props.month, props.day) }}</span>
      </div>
    </div>
    <div class="grow text-xs relative text-charcoal transition delay-75 duration-300 ease-in-out"
      :class="{
        'bg-primary': isDragOver,
        'bg-error/10 group-hover:bg-green/10': CalendarHelper.isSun(props.year, props.month, props.day) && !isDragOver || isHoliday(),
        'bg-blue-100 group-hover:bg-green/10': CalendarHelper.isSat(props.year, props.month, props.day) && !isDragOver,
      }"
      @dragover="isDragOver = true"
      @dragleave="isDragOver = false"
      @drop="dropHandler"
    >
      <div class="flex w-full flex-col p-1 transition delay-75 duration-300 ease-in-out relative min-h-8 h-auto">
        <template v-for="item in props.items">
          <div class="flex w-fit items-center gap-1 rounded border border-slate-300 px-1 py-0.5 cursor-pointer transition delay-75 duration-300 ease-in-out group/item hover:border-dashed"
            :class="{
              'border-dashed bg-white/60': isDragging,
            }"
            :style="{backgroundColor: item.holiday? '#FEE9E7' : item.color, color: item.holiday ? '#ff3000' : '#ffffff'}"
            draggable="true"
            @dragstart="isDragging = true"
            @dragend="dragendHandler"
            @click.prevent="editItem(item)"
          >  
            {{ item.name }}
            <!-- remove icon -->
            <button class="p-[2px] bg-white/60 hidden group-hover/item:block rounded transition-all delay-75 duration-300 ease-in-out print:hidden right-5" @click.stop="deleteItem(item)">
              <XMarkIcon class="w-3 h-3" :style="{color: item.holiday ? '#FEE9E7' : item.color}"/>
            </button>
          </div>
        </template>  
        <button 
          @click.prevent="addItem"
          class="hidden group-hover:flex opacity-0 group-hover:opacity-100 p-px rounded-sm bg-slate-700 absolute right-1 top-1/2 transform -translate-y-1/2 hover:opacity-80 transition delay-75 duration-300 ease-in-out print:hidden"
        >
          <PlusIcon class="w-4 h-4 text-white" />
        </button>
      </div>
    </div>
  </div>
</template>
