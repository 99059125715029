<script setup>
import { ref, getCurrentInstance, onMounted, computed } from 'vue'
import { GlobeAltIcon } from '@heroicons/vue/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'
import { useRoute, useRouter } from 'vue-router'
import IndexLineHelpModal from './IndexLineHelpModal.vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
const route = useRoute();
const router = useRouter();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

import TermModel from './TermModel.vue';
const refTermModel = ref(null)

import SelectLanguage from './widgets/SelectLanguage.vue';

const refLineHelpModal = ref(null)
const error = ref({ phone: false, confirm: false })

onMounted(async () => {
  fetchData()
})
const club_id = route.params.id
const fetchData = async () => {  
  $root.overlay.loading = true
  await fronEndClubStore.getItem(club_id)
  $root.overlay.loading = false

  if (!fronEndClubStore.club.id) {
    router.push({ path: `/front-end/404` });
  }
  const fillter = localStorage.getItem('frontClubFillter')
  if (fillter) {
    fronEndClubStore.filter = JSON.parse(fillter)
    fronEndClubStore.filter.related = false
  }
}

const search = async () => {
  error.value = { phone: false, confirm: false }
  if (!fronEndClubStore.filter.phone) {
    error.value.phone = true
  }
  if (!fronEndClubStore.confirm) {
    error.value.confirm = true
  }
  if (error.value.phone == true || error.value.confirm == true) {
    return
  }
  localStorage.setItem('frontClubFillter', JSON.stringify(fronEndClubStore.filter))

  $root.overlay.loading = true
  await fronEndClubStore.search()
  $root.overlay.loading = false

  if (fronEndClubStore.info.joined || fronEndClubStore.info.related_join.length > 0) {
    router.push({ path: `/front-end/club-membership/${fronEndClubStore.club.alias}/3` });
  }
  else if (fronEndClubStore.info.member) {
    router.push({ path: `/front-end/club-membership/${fronEndClubStore.club.alias}/1` });
  }
  else {
    router.push({ path: `/front-end/club-membership/${fronEndClubStore.club.alias}/2` });
  }

}

const club_member_registration_desc_2 = computed(() => {
  return t('club_member_registration_desc_2', { yes: '<span class="font-bold text-pblue">「' + t('yes') + '」</span>' })
})

const club_member_registration_desc_3 = computed(() => {
  return t('club_member_registration_desc_3', { no: '<span class="font-bold text-oblue">「' + t('no') + '」</span>' })
})
const phoneInput = () => {
  fronEndClubStore.filter.phone = fronEndClubStore.filter.phone.replace(/[^0-9]/g, '');
}
</script>
<template>
  <div
    class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div
      class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-md md:shadow-lg">
      <div class="flex flex-col w-full bg-secondary p-4">
        <h5 class="text-xl text-white text-center font-bold">{{ $t('club_member_registration') }}</h5>
      </div>
      <hr />

      <div class="flex flex-col text-base p-4">
        <div class="flex flex-col gap-4">

          <div class="flex flex-row items-center gap-2 justify-center">
            <div class="w-5 h-5 group transition duration-300 delay-75 ease-in-out">
              <GlobeAltIcon
                class="w-6 h-6 text-gray-400 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
            </div>
            <div>Language</div>
            <SelectLanguage />
          </div>

          <div class="flex flex-col items-start border border-gray-200 divide-y">
            <div class="flex w-full p-2 bg-gray-100 text-sm">
              {{ $t('club_name') }}
            </div>
            <div class="flex w-full p-2 font-bold text-sblue">
              {{ fronEndClubStore.club.name }}
            </div>
          </div>
          
          <template v-if="fronEndClubStore.filter.related == 0">
            <div class="flex flex-col items-center gap-2 w-full">
              <div>{{ $t('club_member_registration_desc_1') }}</div>
              <div v-html="club_member_registration_desc_2" class="line-content text-center"></div>
              <div v-html="club_member_registration_desc_3" class="line-content text-center"></div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center gap-4 w-full">
              <div class="text-center">{{ $t('club_name_note.desc1') }}</div>
              <div class="text-error text-center">{{ $t('club_name_note.desc11') }}</div>
              <div class="text-center">{{ $t('club_name_note.desc2') }}</div>
              <div class="text-pblue text-center">{{ $t('club_name_note.desc3') }}</div>
            </div>
          </template>

          <div class="flex flex-col px-4 gap-4" v-if="fronEndClubStore.filter.related == 0">
            <div class="flex w-full border items-center justify-center py-6 border-primary rounded-md">
              <button
                class="px-2 py-1 h-11 font-bold bg-blue-500 text-white rounded w-fit min-w-28 hover:opacity-80 flex items-center gap-1 justify-center"
                @click="fronEndClubStore.filter.related = 1">
                {{ $t('yes') }}
              </button>
            </div>
            <div class="flex w-full border items-center justify-center py-6 border-primary rounded-md">
              <button
                class="px-2 py-1 h-11 font-bold bg-oblue text-white rounded w-fit min-w-28 hover:opacity-80 flex items-center gap-1 justify-center"
                @click="fronEndClubStore.filter.related = 2">
                {{ $t('no') }}
              </button>
            </div>

            <div class="w-full flex flex-col">
              <div class="text-error">{{ $t('club_line.desc') }}</div>
              <div class="flex items-start gap-1 text-blue-500 underline" @click.prevent="refLineHelpModal.open">
                <QuestionMarkCircleIcon class="w-5 h-5 fill-green mt-1" />
                {{ $t('club_line.help') }}
              </div>
            </div>
          </div>

          <div class="flex flex-col" v-if="fronEndClubStore.filter.related != 0">
            <div class="flex w-full px-1 py-0 justify-start text-sm">
              {{ $t('phone_number') }}
            </div>
            <div class="flex w-full py-1 justify-start">
              <input type="text" class="px-2 py-1 border rounded w-full" 
                @input="phoneInput"
                :class="error.phone ? 'border-error' : 'border-grey'" 
                v-model.trim="fronEndClubStore.filter.phone" 
                placeholder="0900000000"
              />
            </div>
          </div>

          <div class="flex flex-row items-center gap-2 justify-center bg-yellow-300 px-4 py-2"
            v-if="fronEndClubStore.filter.related != 0">
            <label class="flex items-center gap-2 text-sm cursor-pointer">
              <input type="checkbox" class="w-6 h-6 appearance-none border-2 rounded-sm" :class="{
                'border-error': error.confirm,
                'border-grey': !error.confirm,
                'appearance-auto': fronEndClubStore.confirm
              }" v-model="fronEndClubStore.confirm" />
              <a @click="refTermModel.open()" class="text-pblue cursor-pointer hover:font-semibold underline">
                {{ $t('privacy_policy') }}
              </a>{{ $t('privacy_policy_confirm') }}
            </label>
          </div>

          <div class="flex flex-row items-center justify-center gap-4" v-if="fronEndClubStore.filter.related != 0">
            <button type="button" @click.prevent="fronEndClubStore.filter.related = 0"
              class="w-1/2 border shadow font-bold  text-gray-800 py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('back') }}
            </button>
            <button type="submit" @click.prevent="search()"
              class="w-1/2 shadow text-white font-bold bg-primary py-1 rounded-md hover:opacity-80 h-9">
              {{ $t('next') }}
            </button>
          </div>

        </div>
      </div>
    </div>
    <TermModel ref="refTermModel"></TermModel>
    <IndexLineHelpModal ref="refLineHelpModal" />
  </div>
</template>
